import s from "./mobile-guard.module.scss";
import { AppDLHLogo } from "../icons/logo/app-dlh-logo.icon";
import React from "react";
import { useTranslation } from "react-i18next";

export const MobileGuard = () => {
  const { t } = useTranslation();

  return (
    <div className={s.mobileGuard}>
      <AppDLHLogo width={"80"} height={"40"} />
      <h2>{t("app.not.available.mobile")}</h2>
    </div>
  );
};
