import React, { SVGAttributes } from "react";
import cn from "classnames";

interface IPasswordIconType {
  show?: boolean;
  width?: string;
  height?: string;
  sharedStyles?: string;
}

export const AppPasswordIcon: React.FC<
  IPasswordIconType & SVGAttributes<any>
> = ({ sharedStyles, show = true, width = "20", height = "20", ...props }) => {
  if (show) {
    return (
      <svg
        className={cn(sharedStyles)}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0011 17.7143C8.99981 17.7143 5.92035 15.8725 4.36377 12.0001C5.9204 8.13137 9.02129 6.28571 12.0387 6.28571C15.0552 6.28571 18.1301 8.12969 19.6408 11.9941C18.085 15.8706 15.0039 17.7143 12.0011 17.7143ZM21.8608 11.6094C20.1034 6.60143 16.1147 4 12.0387 4C7.96468 4 3.95483 6.59828 2.14522 11.5987L2 12L2.14522 12.4013C3.95331 17.3975 7.93542 20 12.0011 20C16.0668 20 20.0489 17.3975 21.857 12.4013L22 12.0061L21.8608 11.6094ZM10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.5523 10.5 13 10.0523 13 9.5C13 8.94772 12.5523 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5C13.933 15.5 15.5 13.933 15.5 12V11.5C15.5 10.9477 15.0523 10.5 14.5 10.5C13.9477 10.5 13.5 10.9477 13.5 11.5V12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12Z"
          fill="#8C94A5"
          {...props}
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={cn(sharedStyles)}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.70711 3.29289C4.31658 2.90237 3.68342 2.90237 3.29289 3.29289C2.90237 3.68342 2.90237 4.31658 3.29289 4.70711L5.2947 6.70892C3.97746 7.93408 2.87998 9.56839 2.14522 11.5987L2 12L2.14522 12.4013C3.95331 17.3975 7.93542 20 12.0011 20C13.7786 20 15.5401 19.5026 17.1109 18.5252L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L4.70711 3.29289ZM15.4501 16.8643L13.6651 15.0793C13.1699 15.3476 12.6028 15.5 12 15.5C10.067 15.5 8.5 13.933 8.5 12C8.5 11.3972 8.65237 10.8301 8.92069 10.3349L6.88689 8.3011C5.86651 9.21425 4.99013 10.4434 4.36377 12.0001C5.92035 15.8725 8.99981 17.7143 12.0011 17.7143C13.1705 17.7143 14.3517 17.4347 15.4501 16.8643ZM10.5023 11.9165C10.5008 11.9441 10.5 11.972 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5C12.028 13.5 12.0559 13.4992 12.0835 13.4977L10.5023 11.9165ZM18.3099 14.3957C18.8241 13.7061 19.2747 12.9064 19.6408 11.9941C18.1301 8.12969 15.0552 6.28571 12.0387 6.28571C11.4873 6.28571 10.933 6.34736 10.3859 6.47173L8.56431 4.6501C9.68237 4.21829 10.8578 4 12.0387 4C16.1147 4 20.1034 6.60143 21.8608 11.6094L22 12.0061L21.857 12.4013C21.3574 13.7818 20.6918 14.9796 19.9044 15.9901L18.3099 14.3957Z"
          fill="#8C94A5"
          {...props}
        />
      </svg>
    );
  }
};
