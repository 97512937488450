import { useRef } from "react";
import { Table, Tag, Tooltip, Col, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import type { Node } from "reactflow";

import { AppSmallCrossIcon } from "../../../../components/icons";
import { IEntriesAttributeTypes } from "../../../../store/types/storages.types";
import { ScheduleString } from "../../schedule-string.component";

import s from "./process-table.module.scss";

type PropTypes = {
  node: Node;
  onClose: () => void;
  initialHeight?: number | undefined;
};

export const ProcessTable: React.FC<PropTypes> = ({
  node,
  onClose,
  initialHeight,
}) => {
  const { t } = useTranslation();
  const tableRef = useRef<HTMLDivElement>(null);
  const addStyle = initialHeight ? { height: initialHeight } : {};
  const columns: ColumnsType<IEntriesAttributeTypes> = [
    {
      width: "30%",
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>
            {t("source.detail.card.table.head.attribute")}
          </p>
        </div>
      ),
      key: "name",
      render: (_, { attribute_name }) => {
        return (
          <div className={s.rowWrapper}>
            <div className={s.tableTitle}>{attribute_name}</div>
          </div>
        );
      },
    },
    {
      width: "15%",
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>
            {t("source.detail.card.table.head.type")}
          </p>
        </div>
      ),
      key: "type",
      dataIndex: "type",
      render: (_, { attribute_type }) => (
        <div className={s.rowWrapper}>
          <p className={s.tableContent}>{attribute_type}</p>
        </div>
      ),
    },
    {
      title: (
        <div className={s.rowWrapper}>
          <Tooltip title="null/not null" color={"var(--white)"}>
            <p className={s.tableTitle}>
              {t("source.detail.card.table.head.n")}
            </p>
          </Tooltip>
        </div>
      ),
      dataIndex: "type",
      key: "type",
      width: "15%",
      render: (_, { attribute_is_nullable }) => (
        <div className={s.rowWrapper}>
          <p className={s.tableContent}>
            {t(
              attribute_is_nullable
                ? "source.detail.card.table.head.n.yes"
                : "source.detail.card.table.head.n.no"
            )}
          </p>
        </div>
      ),
    },
    {
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>
            {t("source.detail.card.table.head.rules")}
          </p>
        </div>
      ),
      key: "rules",
      dataIndex: "rules",
      render: (_, { attribute_rules }) => (
        <div className={s.rowWrapper}>
          <div className={cn(s.rowWrapper, s.ruleWrapper)}>
            {attribute_rules.map(({ attribute_rule_rule_template }, id) => (
              <Tag key={id} className={s.ruleTag}>
                {attribute_rule_rule_template.rule_template_name}
              </Tag>
            ))}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={s.footerTable} ref={tableRef} {...addStyle}>
      <AppSmallCrossIcon
        sharedStyles={s.footerTableClose}
        width={"16"}
        height={"16"}
        onClick={onClose}
      />
      <Row>
        <Col span={5} className={s.processInfoWrapper}>
          <div className={s.processInfo}>
            <div className={s.processInfoContent}>
              <span>{t("page.process.form.name")}:</span>
              <p className={s.processInfoTitle}>
                <span>{node.data.title}</span>
                <span className={s.status}>
                  {t(`app.process.status.${node.data.status}`)}
                </span>
              </p>
            </div>
          </div>
          <div className={s.processInfo}>
            <div className={s.processInfoContent}>
              <span>
                {t("page.process.form.step.lowcode.footer.process.schedule")}
              </span>
              <p>
                {node.data.schedule ? (
                  <ScheduleString schedule={node.data.schedule} />
                ) : (
                  "-"
                )}
              </p>
            </div>
          </div>
        </Col>
        <Col span={19} className={s.tableWrapper}>
          <Table
            bordered
            size="small"
            rowKey={(fieldKey) => fieldKey.id}
            columns={columns}
            pagination={false}
            dataSource={node.data.entity?.attributes || []}
          />
        </Col>
      </Row>
    </div>
  );
};
