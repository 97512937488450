/* eslint-disable */
import { getTimeZone } from "../../helpers/date.helper";

export enum EInterval {
  PER_HOUR = "perhour",
  PER_DAY = "perday",
  PER_WEEK = "perweek",
  PER_MONTH = "permonth",
}

export const intervalMapper = (interval: EInterval) => {
  switch (interval) {
    case EInterval.PER_HOUR:
      return "ежечасно";
    case EInterval.PER_DAY:
      return "ежедневно";
    case EInterval.PER_WEEK:
      return "еженедельно";
    case EInterval.PER_MONTH:
      return "ежемесячно";
  }
};

export const enrichNode = (node, joinItem, attributes) => {
  node.data.results = attributes.map((attr) => ({
    id: attr.id,
    entity_id: String(attr.entityId),
    attribute_schema: attr.attributeSchema,
    attribute_name: attr.attributeName,
    attribute_name_slug: attr.attributeNameSlug,
    attribute_type: attr.attributeType,
  }));
  let entity = node.data.fields?.find(
    ({ id }) => id === String(joinItem.entityId)
  );
  if (!entity) {
    return;
  }
  node.data.index = 0;
  entity.node_id = String(joinItem.id);
  entity.type = node.type.slice(0, -4);
  entity.entity_id = joinItem.entityId;
  entity.entity_schema_slug = joinItem.entitySchemaSlug;
  entity.entity_name_slug = joinItem.entityNameSlug;
  entity.storage_name_slug = joinItem.storageNameSlug;
  entity.attribute_name_slug = joinItem.attributeNameSlug;
  entity.attribute_id = joinItem.attributeId;
};

export const enrichRf = (tree, rf) => {
  const resultRf = rf;
  resultRf.nodes = rf.nodes.filter((item) => {
    //проверка чтоб не отправлялись nodes без results
    if (item.type !== "mergeTransformNode") {
      return true;
    }
    return tree.some((obj) => obj.id === item.id);
  });

  resultRf.edges = rf.edges.filter((item) => {
    return tree.some((obj) => obj.id === item.target);
  });

  tree.forEach((leaf) => {
    if (leaf.type == "merge") {
      const key = `${leaf.type}_${leaf.index}`;
      let transformNode = rf.nodes.find(({ id }) => id === key);
      if (transformNode) {
        transformNode.data.settings = leaf.settings.map((join) => ({
          index: join.index,
          variant: join.variant,
          left: {
            type: join.left.type,
            id: join.left.id,
            storage_name: join.left.storageName,
            storage_name_slug: join.left.storageNameSlug,
            entity_id: String(join.left.entityId),
            entity_name: join.left.entityName,
            entity_name_slug: join.left.entityNameSlug,
            entity_schema: join.left.entitySchema,
            entity_schema_slug: join.left.entitySchemaSlug,
            attribute_id: join.left.attributeId,
            attribute_name: join.left.attributeName,
            attribute_name_slug: join.left.attributeNameSlug,
            attribute_type: join.left.attributeType,
          },
          right: {
            type: join.right.type,
            id: join.right.id,
            storage_name: join.right.storageName,
            storage_name_slug: join.right.storageNameSlug,
            entity_id: String(join.right.entityId),
            entity_name: join.right.entityName,
            entity_name_slug: join.right.entityNameSlug,
            entity_schema: join.right.entitySchema,
            entity_schema_slug: join.right.entitySchemaSlug,
            attribute_id: join.right.attributeId,
            attribute_name: join.right.attributeName,
            attribute_name_slug: join.right.attributeNameSlug,
            attribute_type: join.right.attributeType,
          },
        }));
        transformNode.data.results = leaf.results.attributes.map((item) => ({
          id: item.id,
          entity_id: String(item.entityId),
          attribute_schema: item.attributeSchema,
          attribute_name: item.attributeName,
          attribute_name_slug: item.attributeNameSlug,
          attribute_type: item.attributeType,
        }));
        transformNode.id = key;
        transformNode.data.index = leaf.index;
        transformNode.data.title = leaf.name;
      }

      leaf.settings.forEach(({ left, right }) => {
        enrichNode(
          rf.nodes.find(({ id }) => id === String(left.id)),
          left,
          leaf.results.attributes
        );
        enrichNode(
          rf.nodes.find(({ id }) => id === String(right.id)),
          right,
          leaf.results.attributes
        );
      });
    } else if (leaf.type === "filterTransformNode") {
      const nodeIndex = resultRf.nodes.findIndex(({ id }) => id === leaf.id);
      resultRf.nodes[nodeIndex].data = leaf.data;
      resultRf.nodes[nodeIndex].type = leaf.type;

      const storageNodeIndex = resultRf.nodes.findIndex(
        ({ id }) => id === String(leaf.data.filter_group.source.id)
      );

      resultRf.nodes[storageNodeIndex].data.results =
        leaf.data.filter_group.groups[0].filters.map(({ attribute }) => ({
          id: attribute.id,
          entity_id: leaf.data.filter_group.source.entity_id,
          attribute_schema: leaf.data.filter_group.source.entity_name,
          attribute_name: attribute.attribute_name,
          attribute_name_slug: attribute.attribute_name_slug,
          attribute_type: attribute.attribute_type,
        }));

      resultRf.nodes[storageNodeIndex].data.fields =
        leaf.data.filter_group.groups[0].filters.map(({ attribute }) => ({
          id: leaf.data.filter_group.source.entity_id,
          name: leaf.data.filter_group.source.entity_name,
          node_id: String(leaf.data.filter_group.source.id),
          type: leaf.data.filter_group.source.type,
          entity_id: leaf.data.filter_group.source.entity_id,
          entity_schema_slug: leaf.data.filter_group.source.entity_schema_slug,
          entity_name_slug: leaf.data.filter_group.source.entity_name_slug,
          storage_name_slug: leaf.data.filter_group.source.storage_name_slug,
          attribute_name_slug:
            leaf.data.filter_group.source.attribute_name_slug,
          attribute_id: leaf.data.filter_group.source.attribute_id,
        }));
    }
  });

  resultRf.nodes = rf.nodes.map((item) => {
    if (item.type == "mergeTransformNode") {
      // fields при node.type === "mergeTransformNode" не должен отправляться
      delete item.data.fields;
    }
    return item;
  });

  return resultRf;
};

export const transformProcessDataToRequest = ({
  processData,
  timestamp,
  isLowcode,
}) => {
  if (isLowcode) {
    return {
      process_name: processData.process_name,
      process_description: processData.process_description,
      entities: processData.entities.map((id) => ({ id })),
      process_status: processData.process_status,
      process_sql: null,
      process_scheduler: null,
      process_is_overwriting: true,
      process_schema: {
        tree: processData.tree || null,
        rf: enrichRf(processData.tree, processData.rf),
      },
    };
  }

  const dataResolver = (time: string | null) => {
    return time === null ? null : Number(time);
  };

  const processSchedulerCondition =
    processData.onSchedule === "default"
      ? {
          process_scheduler: null,
        }
      : {
          process_scheduler: JSON.stringify({
            minute: dataResolver(timestamp.minute),
            hour: dataResolver(timestamp.hour),
            week: dataResolver(timestamp.week),
            month: dataResolver(timestamp.month),
            interval: timestamp.interval,
            timezone_offset: getTimeZone(),
          }),
        };

  return {
    process_name: processData.process_name,
    process_description: processData.process_description,
    entities: processData.entities,
    process_sql: processData.process_sql,
    process_status: processData.process_status,
    process_is_overwriting: processData.process_is_overwriting || false,
    ...processSchedulerCondition,
    process_schema: null,
  };
};
