import React, { useEffect, useCallback } from "react";
import { Modal, Table, Tag, Tooltip, Col, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { fetchFullProcessById } from "../../../../api/processes/processes-actions.api";
import { AppAttributeIcon } from "../../../icons/app-attribute.icon";
import { AppButton } from "../../../app-button/app-button.component";
import { AppLogoLoader } from "../../../ui/app-animated-logo/app-animated-logo.component";
import { ScheduleString } from "../../schedule-string.component";
import {
  ProcessType,
  ShortProcessType,
} from "../../../../store/types/low-code.types";

import s from "./process-view.module.scss";

type PropTypes = {
  open: boolean;
  shortProcess: ShortProcessType;
  onCancel: () => void;
  onConfirm: (fullProcess: ProcessType) => void;
};

export const LowCodeViewProcessModal: React.FC<PropTypes> = React.memo(
  function ({ open, shortProcess, onCancel, onConfirm }) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const fullProcess: ProcessType = useAppSelector(
      (state) => state.lowCode.process
    );

    useEffect(() => {
      if (shortProcess) {
        dispatch(fetchFullProcessById(shortProcess.id));
      }
    }, [shortProcess]);

    const title = (
      <>
        <div className={s.modalHeader}>{shortProcess?.name}</div>
        <div className={s.modalDescription}>
          {t("page.process.form.step.lowcode.process.view.status")}
          <span className={s.status}>
            {t(`app.process.status.${shortProcess?.status}`)}
          </span>
        </div>
      </>
    );

    type AttributeType = {
      id: number | string;
      attribute_name: string;
      attribute_type: string;
      attribute_is_nullable: boolean;
      attribute_rules: Array<{ id: string | number; name: string }>;
    };

    const columns: ColumnsType<AttributeType> = [
      {
        width: "30%",
        title: (
          <div className={s.rowWrapper}>
            <p className={s.tableTitle}>
              {t("source.detail.card.table.head.attribute")}
            </p>
          </div>
        ),
        key: "name",
        render: (_, { attribute_name }) => {
          return (
            <div className={s.rowWrapper}>
              <div className={s.tableTitle}>{attribute_name}</div>
            </div>
          );
        },
      },
      {
        width: "15%",
        title: (
          <div className={s.rowWrapper}>
            <p className={s.tableTitle}>
              {t("source.detail.card.table.head.type")}
            </p>
          </div>
        ),
        key: "type",
        dataIndex: "type",
        render: (_, { attribute_type }) => (
          <div className={s.rowWrapper}>
            <p className={s.tableContent}>{attribute_type}</p>
          </div>
        ),
      },
      {
        title: (
          <div className={s.rowWrapper}>
            <Tooltip title="null/not null" color={"var(--white)"}>
              <p className={s.tableTitle}>
                {t("source.detail.card.table.head.n")}
              </p>
            </Tooltip>
          </div>
        ),
        dataIndex: "type",
        key: "type",
        width: "15%",
        render: (_, { attribute_is_nullable }) => (
          <div className={s.rowWrapper}>
            <p className={s.tableContent}>
              {t(
                attribute_is_nullable
                  ? "source.detail.card.table.head.n.yes"
                  : "source.detail.card.table.head.n.no"
              )}
            </p>
          </div>
        ),
      },
      {
        title: (
          <div className={s.rowWrapper}>
            <p className={s.tableTitle}>Правила</p>
          </div>
        ),
        key: "rules",
        dataIndex: "rules",
        render: (_, { attribute_rules }) => {
          return (
            <div className={s.rowWrapper}>
              <div className={cn(s.rowWrapper, s.ruleWrapper)}>
                {attribute_rules.map(({ id, name }) => (
                  <Tag key={id} className={s.ruleTag}>
                    {name}
                  </Tag>
                ))}
              </div>
            </div>
          );
        },
      },
    ];

    const footer = (
      <>
        <div className={s.footer}>
          <AppButton
            isOutline
            onClick={() => !fullProcess.loading && onCancel()}
            disabled={fullProcess.loading}
          >
            {t("page.process.form.step.lowcode.process.view.action.cancel")}
          </AppButton>
          <AppButton
            onClick={() => !fullProcess.loading && onConfirm(fullProcess)}
            disabled={fullProcess.loading}
          >
            {t("page.process.form.step.lowcode.process.view.action.confirm")}
          </AppButton>
        </div>
      </>
    );

    const modalContent = useCallback(() => {
      return (
        <Row>
          <Col span={9}>
            <div className={s.processInfo}>
              <div className={s.processInfoContent}>
                <span>
                  {t("page.process.form.step.lowcode.process.view.schedule")}
                </span>
                <p>
                  {fullProcess.schedule ? (
                    <ScheduleString schedule={fullProcess.schedule} />
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </div>
          </Col>
          <Col span={15} className={s.tableWrapper}>
            <h3>
              <AppAttributeIcon />
              {t("page.process.form.step.lowcode.process.view.attributes")}
            </h3>
            <Table
              bordered
              size="small"
              rowKey={(fieldKey) => fieldKey.id}
              columns={columns}
              pagination={false}
              dataSource={fullProcess.entity?.attributes || []}
            />
          </Col>
        </Row>
      );
    }, [fullProcess.entity, fullProcess.schedule]);

    return (
      <Modal
        open={open}
        destroyOnClose={true}
        footer={footer}
        onCancel={onCancel}
        title={title}
        width={1000}
        wrapClassName="view-process"
      >
        {fullProcess.loading ? (
          <div className={cn(s.logoWrapper)}>
            <AppLogoLoader loading={true}></AppLogoLoader>
          </div>
        ) : (
          modalContent()
        )}
      </Modal>
    );
  }
);
