export function filterEntitiesAndAttributes(
  entities: any,
  searchValue: string,
  attrsKey = "attributes"
) {
  const results: any[] = [];

  entities.forEach((entity: any) => {
    if (entity.entity_name.toLowerCase().includes(searchValue.toLowerCase())) {
      results.push(entity);
    } else {
      const attrs: any[] = [];

      entity[attrsKey].forEach((attribute: any) => {
        if (
          attribute.attribute_name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          attrs.push(attribute);
        }
      });

      if (attrs.length) {
        results.push(Object.assign({}, entity, { [attrsKey]: attrs }));
      }
    }
  });

  return results || [];
}

export function shallowSearch(
  entities: any,
  searchValue: string,
  attrsKey = "attributes"
) {
  const results: any[] = [];

  entities.forEach((entity: any) => {
    if (entity.entity_name.toLowerCase().includes(searchValue.toLowerCase())) {
      results.push(entity);
    } else {
      entity[attrsKey].forEach((attribute: any) => {
        if (
          attribute.attribute_name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          const entityWithAttribute = {
            id: entity.id,
            entity_name: entity.entity_name,
            entity_name_slug: entity.entity_name_slug,
            [attrsKey]: [attribute],
          };
          results.push(entityWithAttribute);
        }
      });
    }
  });

  return results || [];
}

export function deepSearch(
  data: any[],
  searchValue: string,
  ...path: Array<string>
): any[] {
  if (!searchValue) {
    return data;
  }

  const results: any[] = [];
  const [one, two] = path;

  data.forEach((storage: any) => {
    if (
      storage.storage_name.toLowerCase().includes(searchValue.toLowerCase())
    ) {
      results.push(storage);
    } else {
      const foundEntities = shallowSearch(storage?.[one], searchValue, two);

      if (foundEntities.length) {
        results.push({
          id: storage.id,
          storage_name_slug: storage.storage_name_slug,
          storage_name: storage.storage_name,
          [`${one}`]: foundEntities,
        });
      }
    }
  });
  return results || [];
}
