import React, { SVGAttributes } from "react";

interface ConnectionIconTypes {
  width?: string;
  height?: string;
}

export const AppDLHLogo: React.FC<ConnectionIconTypes & SVGAttributes<any>> = ({
  width = "335",
  height = "100",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 335 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 8L50 50.5L8 92V8Z" fill="#00ADEF" {...props} />
      <path d="M8 8H92L50 50.5L8 8Z" fill="#2E3192" {...props} />
      <path d="M50 50.5L92 8V92L50 50.5Z" fill="#00ADEF" {...props} />
      <path d="M8 92L50 55L92 92H8Z" fill="#2E3192" {...props} />
      <path
        d="M108 92V8H135.921C144.834 8 152.208 9.89296 158.045 13.6789C163.961 17.3859 168.377 22.4338 171.296 28.8225C174.293 35.1324 175.792 42.1521 175.792 49.8817C175.792 58.4 174.175 65.8141 170.941 72.124C167.707 78.4338 163.093 83.3239 157.099 86.7944C151.104 90.2648 144.045 92 135.921 92H108ZM169.758 49.8817C169.758 42.862 168.417 36.5916 165.735 31.0704C163.132 25.5493 159.307 21.2507 154.259 18.1746C149.29 15.0197 143.177 13.4423 135.921 13.4423H114.034V86.5578H135.921C143.256 86.5578 149.448 84.9409 154.496 81.707C159.544 78.4732 163.33 74.0958 165.854 68.5747C168.456 62.9747 169.758 56.7437 169.758 49.8817Z"
        fill="#2E3192"
        {...props}
      />
      <path
        d="M192.342 92V8H198.376V86.5578H248.539V92H192.342Z"
        fill="#2E3192"
        {...props}
      />
      <path
        d="M326.157 8V92H320.123V51.7747H267.12V92H261.086V8H267.12V46.3324H320.123V8H326.157Z"
        fill="#2E3192"
        {...props}
      />
    </svg>
  );
};
