import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";

import { AppDLHLogo } from "../../components/icons/logo/app-dlh-logo.icon";
import { AppAuth } from "../../components/forms/app-auth-form/app-auth.form";
import { MobileGuard } from "../../components/app-mobile-guard/mobile-guard.component";
import { AppLanguage } from "../../components/app-header/app-language/app-language.compenent";
import { onLogout } from "../../store/auth/auth.slice";
import { useAppDispatch } from "../../store/hooks";

import s from "./auth.module.scss";

export const Auth = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("logout")) {
      dispatch(onLogout());
      return;
    }

    const isLogged = localStorage.getItem("isLoggedIn");
    if (isLogged) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <MobileGuard />
      <div className={s.authContainer}>
        <div className={s.authLanguage}>
          <AppLanguage sharedStyles={s.languageBlock} />
        </div>
        <div className={s.authWrapper}>
          <div className={s.authHead}>
            <AppDLHLogo width={"80"} height={"40"} />
            <h1>{t("modal.source.create.title.auth")}</h1>
          </div>
          <AppAuth />
        </div>
      </div>
    </>
  );
};
