import { RegisterOptions, useForm } from "react-hook-form";
import { useWizard } from "react-use-wizard";

import { AppInput } from "../../app-input/app-input.component";
import s from "../create-process-modal.module.scss";

import { StepFooter, StepLayout } from "./step-layout.component";

export const Step1 = ({ processData, onUpdate }: any) => {
  const { nextStep } = useWizard();
  const formInstance = useForm({
    mode: "onChange",
    defaultValues: {
      process_name: processData.process_name,
      process_description: processData.process_description,
    },
  });

  const {
    control,
    trigger,
    handleSubmit,
    formState: { errors, isValid },
  } = formInstance;

  const validateSymbols = (value: string): string | boolean => {
    const regex = new RegExp(/[^a-zA-ZЁёА-я0-9\s-]/g);
    return !value.match(regex) || "Спецсимволы недопустимы";
  };

  const nameValidation: RegisterOptions = {
    required: "Заполните это поле",
    maxLength: {
      value: 30,
      message: "Имя процессы не может превышать 30 символов",
    },
    validate: {
      validateSymbols,
    },
  };

  const submitForm = (data: any) => {
    onUpdate(data);
    nextStep();
  };

  return (
    <StepLayout onValidate={trigger}>
      <form
        className={s.formWrapper}
        onSubmit={handleSubmit((data) => submitForm(data))}
      >
        <div>
          <h3 className={s.formTitle}>Основные данные</h3>

          <AppInput
            rules={nameValidation}
            errors={errors}
            control={control}
            name={"process_name"}
            placeholder={"Название процесса"}
          />

          <AppInput
            rules={{ required: "Заполните это поле" }}
            errors={errors}
            control={control}
            name={"process_description"}
            placeholder={"Описание процесса"}
            isTextArea={true}
          />
        </div>

        <StepFooter disabledNext={!isValid} />
      </form>
    </StepLayout>
  );
};
