import React, { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Handle, NodeProps, Position, useNodeId } from "reactflow";
import cn from "classnames";

import { ProcessModeContext } from "../../providers/process-mode-provider/processMode.provider";
import { AppSourceIcon, AppEntityIcon, AppSmallCrossIcon } from "../icons";
import { AppButton, EButtonSize } from "../app-button/app-button.component";
import { TransformContext } from "../../pages/create-process/steps/transform.provider";

import s from "./node.module.scss";

export type TStorageNodeField = {
  id: string;
  name: string;
};

export type TStorageNode = {
  id: string;
  title: string;
  fields: TStorageNodeField[];
};

export const StorageNode: React.FC<NodeProps<TStorageNode>> = memo(
  ({ data, selected, isConnectable }) => {
    const { t } = useTranslation();
    const nodeId = useNodeId();
    const { onEmitEditNode, onEmitDeleteNode } = useContext(TransformContext);
    const mode = useContext(ProcessModeContext);

    const handleDelete = (event: React.MouseEvent) => {
      event.stopPropagation();
      onEmitDeleteNode(String(nodeId));
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onEmitEditNode(nodeId);
    };

    return (
      <>
        <div
          className={cn(s.customNode, { [s.customNodeSelected]: selected })}
          data-testid="storage-node"
        >
          <div
            className={s.customNodeClose}
            onClick={handleDelete}
            role="img"
            aria-label={`remove storage ${nodeId}`}
          >
            <AppSmallCrossIcon />
          </div>
          <div className={s.customNodeTitle}>
            <AppSourceIcon width="16" height="16" />
            <span>{data.title}</span>
          </div>
          {data.fields.map((e: TStorageNodeField, i: number) => (
            <div className={cn(s.customNodeField)} key={i}>
              <AppEntityIcon width="14" height="14" />
              <div className={s.customNodeName} data-testid="entity-name">
                {e.name}
              </div>
              <Handle
                type="source"
                id={`${e.id}`}
                position={Position.Right}
                isConnectable={isConnectable}
                style={{ background: "#b7b7b7", width: "8px", height: "8px" }}
              />
            </div>
          ))}
          <div className={s.customNodeBody}>
            <AppButton
              buttonSize={EButtonSize.FILL}
              isOutline={true}
              isSmall={true}
              onClick={handleClick}
              disabled={mode === "view"}
            >
              {t("page.process.form.step.lowcode.node.storage.edit")}
            </AppButton>
          </div>
        </div>
      </>
    );
  }
);
