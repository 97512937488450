import React, { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

export const GroupIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 7.032 7.032 3 12 3C16.968 3 21 7.032 21 12C21 16.968 16.968 21 12 21C7.032 21 3 16.968 3 12ZM4.8 12C4.8 15.969 8.031 19.2 12 19.2C15.969 19.2 19.2 15.969 19.2 12C19.2 8.031 15.969 4.8 12 4.8C8.031 4.8 4.8 8.031 4.8 12ZM10.2001 13.8C10.2001 14.7941 9.39418 15.6 8.40006 15.6C7.40595 15.6 6.60006 14.7941 6.60006 13.8C6.60006 12.8059 7.40595 12 8.40006 12C9.39418 12 10.2001 12.8059 10.2001 13.8ZM12 10.2C12.9941 10.2 13.8 9.39412 13.8 8.4C13.8 7.40589 12.9941 6.6 12 6.6C11.0059 6.6 10.2 7.40589 10.2 8.4C10.2 9.39412 11.0059 10.2 12 10.2ZM17.4 13.8C17.4 14.7941 16.5941 15.6 15.6 15.6C14.6059 15.6 13.8 14.7941 13.8 13.8C13.8 12.8059 14.6059 12 15.6 12C16.5941 12 17.4 12.8059 17.4 13.8Z"
      fill={"var(--app-icon-color)" || "#737373"}
      {...props}
    />
  </svg>
);
