import React from "react";

export const AppSearchIcon: React.FC<{ width?: string; height?: string }> = ({
  height = "24",
  width = "24",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6977 18.3953C12.4055 18.395 14.0642 17.8232 15.4096 16.7711L19.6394 21.001L21 19.6404L16.7701 15.4105C17.8228 14.0651 18.3949 12.406 18.3953 10.6977C18.3953 6.45336 14.9419 3 10.6977 3C6.45336 3 3 6.45336 3 10.6977C3 14.9419 6.45336 18.3953 10.6977 18.3953ZM10.6977 4.92441C13.8816 4.92441 16.4709 7.51371 16.4709 10.6977C16.4709 13.8816 13.8816 16.4709 10.6977 16.4709C7.51371 16.4709 4.92441 13.8816 4.92441 10.6977C4.92441 7.51371 7.51371 4.92441 10.6977 4.92441Z"
      fill="#737373"
    />
  </svg>
);
