import { useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { getConnectedEdges } from "reactflow";
import type { Node } from "reactflow";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { JoinForm } from "./low-code-join-form.component";
import { JoinFormStep2 } from "./low-code-join-form-step2.component";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { resetJoins } from "../../../../store/processes/process-create/process-create.slice";
import {
  // MergeDataType,
  TransformationIncomerType,
} from "../../../../store/types/low-code.types";
import { memo } from "react";

const storageSourceAdapter = (
  node,
  entityId,
  data
): TransformationIncomerType => {
  const storage = data[node.id];

  const entity = storage.entities.find(
    (entity) => Number(entity.id) === Number(entityId)
  );

  return { storage, entity, node, type: "storage" };
};

const processSourceAdapter = (node: Node): TransformationIncomerType => {
  return {
    node,
    entity: node.data.entity,
    storage: node.data.entity.entity_storage,
    type: "process",
  };
};

const LowCodeJoinModalFn = ({
  rf,
  schema,
  transformNode,
  transformation,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const lowCodeState = useAppSelector((state) => state.lowCode);
  const [step, setStep] = useState(1);

  if (!transformNode) return null;

  const changeStep = (step) => setStep(step);

  const edges = getConnectedEdges([transformNode], rf.getEdges());

  const source: TransformationIncomerType[] = edges
    .filter(({ target }) => target === transformNode.id)
    .map((edge) => {
      const node = rf.getNode(edge.source);

      if (node.type === "storageNode") {
        return storageSourceAdapter(
          node,
          edge.sourceHandle,
          lowCodeState.storages.entities
        );
      } else if (node.type === "processNode") {
        return processSourceAdapter(node);
      } else if (node.type === "mergeTransformNode") {
        return schema.getResult(node.id);
      }
    });

  if (
    edges.filter(({ target }) => target === transformNode.id).length !==
    source.filter((item) => item).length
  ) {
    return null;
  }

  const onClose = () => {
    dispatch(resetJoins());
    onCancel();
  };

  return (
    <Modal
      destroyOnClose={true}
      open={Boolean(transformNode)}
      footer={false}
      width={1692}
      onCancel={onClose}
      wrapClassName="join-modal"
      title={
        <div>
          {t("page.process.form.step.lowcode.transformation.node.join")}
        </div>
      }
    >
      {step === 1 ? (
        <DndProvider backend={HTML5Backend}>
          <JoinForm
            source={source}
            transformNode={transformNode}
            onSubmit={onSubmit}
            onCancel={onCancel}
            changeStep={changeStep}
          />
        </DndProvider>
      ) : (
        <JoinFormStep2
          source={source}
          transformNode={transformNode}
          onSubmit={onSubmit}
          onCancel={onCancel}
          changeStep={changeStep}
        />
      )}
    </Modal>
  );
};

export const LowCodeJoinModal = memo(
  LowCodeJoinModalFn,
  ({ transformNode: oldNode }, { transformNode: newNode }) => {
    return oldNode?.id === newNode?.id;
  }
);
