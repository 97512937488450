import type { Node } from "reactflow";
import type { TransformationIncomerType } from "../../../store/types/low-code.types";
import { flatten } from "../../../utils/utils";

export abstract class Transformation {
  public data: any | null;
  protected children = new Map<string, Transformation>();
  public parent!: Transformation | null;

  constructor(data?: any | undefined | null) {
    this.data = data ?? null;
  }

  abstract getEntities(): number[];

  abstract getResult(): TransformationIncomerType;

  abstract toObject(): any;

  public getData() {
    return this.data;
  }

  public setParent(parent: Transformation | null) {
    this.parent = parent;
  }

  public add(transformation: Transformation): void {
    const node = transformation?.data?.node;
    this.children.set(node?.id, transformation);
    transformation.setParent(this);
  }

  public find(key: string): Transformation | undefined {
    if (this.children.has(key)) {
      return this.children.get(key);
    }

    for (const child of this.children.values()) {
      return child.find(key);
    }
  }

  public remove(key: string): Node[] | undefined {
    if (this.children.has(key)) {
      const child = this.children.get(key);
      this.children.delete(key);
      return this.childrenNodes(child);
    }

    for (const child of this.children.values()) {
      return child.remove(key);
    }
  }

  public size() {
    return this.children.size;
  }

  public childrenNodes(child: Transformation | undefined): Node[] {
    return flatten(
      Array.from(child?.children.values() || []).map(
        (transform) => transform?.data?.incomers.map(({ node }) => node) || []
      )
    );
  }
}
