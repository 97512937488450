import s from "./home.module.scss";

import {
  AppButton,
  EButtonSize,
} from "../../components/app-button/app-button.component";

import { AppCreateCurrent } from "../../components/forms/app-create-current-form/app-create-current.form";
import { OriginButton } from "../../components/origin/origin-button/origin-button.component";
import {
  selectAllConnections,
  selectSearchConnections,
} from "../../store/connections/connnections.selector";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchConnections } from "../../api/connections/connections-actions.api";

import { AppInput } from "../../components/app-input/app-input.component";

import { AppSearchIcon } from "../../components/icons/app-search.icon";
import { onSearch } from "../../store/connections/connections.slice";
import { IntroContext } from "../../providers/intro-provider/intro.provider";

import React, { useCallback, useContext, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import cn from "classnames";
import { Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { AppLogoLoader } from "../../components/ui/app-animated-logo/app-animated-logo.component";
import { useTranslation } from "react-i18next";

export const StorageList = () => {
  const dispatch = useAppDispatch();
  const { connections, loading, error, search } =
    useAppSelector(selectAllConnections);
  const { t } = useTranslation();
  const searchList = useAppSelector(selectSearchConnections);

  const [isShowCreateStorage, setShowCreateStorage] = useState(false);
  const { autoComplete } = useContext(IntroContext);

  const isSearch = Boolean(search);
  const connectionList = isSearch ? searchList : connections;

  useEffect(() => {
    dispatch(fetchConnections());
  }, []);

  useEffect(() => {
    return () => {
      onChangeSearch("");
    };
  }, []);

  const { control } = useForm({ mode: "onChange" });

  const onChangeSearch = (value: string) => {
    dispatch(onSearch(value));
  };

  const renderAddButton = useCallback(() => {
    if (error !== "ERR_NETWORK" && !loading) {
      return (
        <>
          <p>{t("page.source.list.add.source_to_continue")}</p>
          <AppButton onClick={() => setShowCreateStorage(true)}>
            <>
              {t("page.source.add")}
              <PlusOutlined size={56} />
            </>
          </AppButton>
        </>
      );
    } else {
      return <p>{t("page.source.list.connection_error")}</p>;
    }
  }, [loading, error]);

  const renderList = () => {
    return (
      <>
        <div className={s.searchContainer}>
          <AppInput
            icon={<AppSearchIcon />}
            sharedStyles={s.searchInput}
            onChange={(value) => onChangeSearch(value.currentTarget.value)}
            autoComplete={autoComplete}
            name={"search"}
            control={control}
            placeholder={t("page.source.search") as string}
          />
          <AppButton
            id={"create-storage-button"}
            isOutline
            buttonSize={EButtonSize.HUG}
            sharedStyles={s.rightBarButton}
            onClick={() => setShowCreateStorage(true)}
          >
            {t("page.source.add")}
            <PlusOutlined size={56} />
          </AppButton>
        </div>
        <div className={s.searchListWrapper}>
          {connectionList.length ? (
            <>
              <div className={s.searchList}>
                {connectionList.map((e, i) => (
                  <OriginButton origin={e} key={e.id} index={i} />
                ))}
              </div>
            </>
          ) : null}

          <div>
            {isSearch && !connectionList.length && (
              <div className={s.searchEmpty}>
                {t("page.source.search.not_found")}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderEmptyState = () => {
    return (
      <>
        <div className={s.emptyState}>
          <div className={cn(s.emptyContent, s.notSelected)}>
            <h2>{t("page.source.list.empty.title")}</h2>
            {renderAddButton()}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={s.home} id="home">
      <AppLogoLoader loading={loading} />
      <>{renderList()}</>
      <>{!isSearch && !connectionList.length && renderEmptyState()}</>
      <Modal
        destroyOnClose={true}
        open={isShowCreateStorage}
        footer={false}
        onCancel={() => setShowCreateStorage(false)}
        title={t("modal.source.create.title")}
      >
        <AppCreateCurrent
          onCancel={() => {
            setShowCreateStorage(false);
          }}
        />
      </Modal>
    </div>
  );
};
