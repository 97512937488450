import type { Node } from "reactflow";
import { TransformationIncomerType } from "../../../store/types/low-code.types";
import { Merge, Filter, Transformation } from ".";

export class Schema {
  process: { process_name: string; process_description: string };
  tree: Transformation;

  constructor(processData: {
    process_name: string;
    process_description: string;
  }) {
    this.process = processData;
    this.tree = new Merge(); // TODO add Branch as a parent transformation
  }

  add(component: Transformation, parentKey?: string | null): void {
    const parent = parentKey ? this.tree.find(parentKey) : this.tree;
    parent?.add(component);
  }

  find(key: string): Transformation | undefined {
    return this.tree.find(key);
  }

  remove(key: string | undefined): Node[] | undefined {
    if (key) {
      return this.tree.remove(key);
    }
  }

  getResult(id: string): any {
    return this.tree.find(id)?.getResult();
  }

  toObject(): any {
    return this.tree.toObject();
  }

  getEntities(): number[] {
    return this.tree.getEntities();
  }

  private parseIncomers(attrs) {
    const attrsByEntity = attrs.reduce((acc, attr) => {
      const key = `${attr.attributeSchema}_${attr.entityId}`;
      acc[key] = acc[key] || {
        id: attr.entityId,
        type: "process",
        entity_id: attr.entityId,
        entity_name: attr.attributeSchema,
        attributes: [],
      };
      acc[key].attributes.push({
        id: attr.id,
        entity_id: attr.entityId,
        attribute_schema: attr.attributeSchema,
        attribute_name: attr.attributeName,
        attribute_name_slug: attr.attributeNameSlug,
        attribute_type: attr.attributeType,
      });
      return acc;
    }, Object.create(null));

    return Object.values(attrsByEntity).map((entity) => ({
      entity,
    })) as TransformationIncomerType[];
  }

  init({ tree, rf }: any) {
    tree.map((leaf) => {
      if (leaf.type === "merge") {
        const node = rf.nodes.find(
          ({ id }) => id === `${leaf.type}_${leaf.index}`
        );

        const item = {
          name: leaf.name,
          incomers: this.parseIncomers(leaf.results.attributes),
          node,
          joins: leaf.settings.map((item) => Object.assign({}, item)),
        };

        const parentKey = leaf.parent ? `merge_${leaf.parent.index}` : null;

        this.add(new Merge(item), parentKey);
      } else if (leaf.type === "filterTransformNode") {
        const attributes = leaf.data.filter_group.groups[0].filters.map(
          ({ attribute }) => ({
            id: attribute.id,
            entityId: leaf.data.filter_group.source.entity_id,
            attributeSchema: leaf.data.filter_group.source.entity_name,
            attributeName: attribute.attribute_name,
            attributeNameSlug: attribute.attribute_name_slug,
            attributeType: attribute.attribute_type,
          })
        );

        const node = rf.nodes.find(({ id }) => id === leaf.id);

        const item = {
          name: leaf.data.filter_group.title,
          entityName: leaf.data.filter_group.source.entity_name,
          node,
          settings: leaf.data.filter_group.groups[0],
          incomers: this.parseIncomers(attributes),
        };

        this.add(new Filter(item), null);
      }
    });
  }
}
