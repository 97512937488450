import React from "react";

type AppCloseIconProps = {
  width?: string;
  height?: string;
};

export const AppCloseIcon: React.FC<AppCloseIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.26035 4.26035C4.60748 3.91322 5.1703 3.91322 5.51743 4.26035L12 10.7429L18.4826 4.26035C18.8297 3.91322 19.3925 3.91322 19.7397 4.26035C20.0868 4.60748 20.0868 5.1703 19.7397 5.51743L13.2571 12L19.7397 18.4826C20.0868 18.8297 20.0868 19.3925 19.7397 19.7397C19.3925 20.0868 18.8297 20.0868 18.4826 19.7397L12 13.2571L5.51743 19.7397C5.1703 20.0868 4.60748 20.0868 4.26035 19.7397C3.91322 19.3925 3.91322 18.8297 4.26035 18.4826L10.7429 12L4.26035 5.51743C3.91322 5.1703 3.91322 4.60748 4.26035 4.26035Z"
        fill="#737373"
      />
    </svg>
  );
};
