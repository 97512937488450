import { useWizard } from "react-use-wizard";
import cn from "classnames";

import s from "../create-process-modal.module.scss";
import { AppButton } from "../../app-button/app-button.component";
import { Alert } from "../../ui/app-alert/app-alert.component";

type PropTypes = {
  isValidStep?: boolean;
  onValidate?: () => void;
  children: React.ReactNode;
};

export const PROCESS_STEPS = [
  {
    name: "Основные данные",
    helper: "Укажите уникальное название процесса и добавьте описание.",
  },
  {
    name: "Выбор источников",
    helper:
      "Выберите источники данных и таблицы для установления связи между ними. Можно использовать таблицы, созданные с помощью других процессов. Они лежат в Источнике data_product",
  },
  {
    name: "Ввод запроса",
    helper:
      "Проверьте выбранные источники, таблицы и добавьте sql-запрос, используя атрибуты выбранных таблиц. ",
  },
];

export const StepFooter: React.FC<{ disabledNext?: boolean }> = ({
  disabledNext,
}) => {
  const { previousStep, isFirstStep, isLastStep } = useWizard();

  return (
    <div className={s.processFooter}>
      {!isFirstStep ? (
        <AppButton isOutline={true} onClick={() => previousStep()}>
          Назад
        </AppButton>
      ) : null}
      <div></div>
      <AppButton htmlType={"submit"} disabled={disabledNext}>
        {isLastStep ? "Создать процесс" : "Дальше"}
      </AppButton>
    </div>
  );
};

export const StepLayout: React.FC<PropTypes> = ({
  onValidate = () => null,
  children,
}) => {
  const { goToStep, activeStep } = useWizard();

  const handleChangeStep = (index: number) => {
    if (index > activeStep) {
      onValidate();
      return;
    }

    goToStep(index);
  };

  return (
    <div className={s.processLayout}>
      <section className={s.stepContainer}>
        <div>
          {PROCESS_STEPS.map(({ name }, index) => (
            <div
              className={cn(s.stepItem, {
                [s.stepItemActive]: activeStep === index,
              })}
              key={index}
              onClick={() => handleChangeStep(index)}
            >
              <div className={s.stepKey}>{index + 1}</div>
              <div>{name}</div>
            </div>
          ))}
        </div>

        <div className={s.processHelp}>
          <Alert>{PROCESS_STEPS[activeStep].helper}</Alert>
        </div>
      </section>
      <section className={s.content}>{children}</section>
    </div>
  );
};
