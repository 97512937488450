import { createSlice } from "@reduxjs/toolkit";
import { IDomainProcess } from "../../../store/types/processes.types";

export type ProcessDetailType = {
  currentProcess: IDomainProcess;
  touched: boolean;
};

const initialState: ProcessDetailType = {
  currentProcess: {
    id: 1,
    process_name: "",
    process_description: "",
    process_is_overwriting: false,
    process_is_deleted: false,
    process_sql: "",
    process_name_slug: "",
    lineage_process: [
      {
        id: 1,
        storage_name: "",
        storage_name_slug: "",
      },
    ],
    process_status_flow: "",
    process_schema: null,
    process_scheduler: {
      minute: "",
      hour: "",
      week: "",
      month: "",
      timezone_offset: "",
      interval: "",
    },
  },
  touched: false,
};

const processDetailSlice = createSlice({
  name: "processDetail",
  initialState,
  reducers: {
    setCurrentProcess: (state, { payload }) => {
      state.currentProcess = { ...payload };
      state.touched = true;
    },
    resetCurrentProcess: (state) => {
      state.currentProcess = { ...initialState.currentProcess };
      state.touched = false;
    },
    setTouched: (state, { payload }) => {
      state.touched = payload;
    },
  },
});

export const { setCurrentProcess, resetCurrentProcess, setTouched } =
  processDetailSlice.actions;
export const processDetailReducer = processDetailSlice.reducer;
