import { Modal } from "antd";
import { AppButton } from "../../app-button/app-button.component";

import s from "./app-confirm-modal.module.scss";
import { useTranslation } from "react-i18next";

type PropTypes = {
  open: boolean;
  cancelText?: string;
  title: string;
  isDanger?: boolean;
  btnTextConfirm?: string;
  children?: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
};

export const AppConfirmModal: React.FC<PropTypes> = ({
  open,
  title,
  btnTextConfirm = "app.confirm.text",
  isDanger,
  children,
  onConfirm,
  onCancel,
  cancelText = "page.process.card.archive.form.decline",
}) => {
  const handleCancelClick = () => onCancel();
  const handleConfirmClick = () => onConfirm();

  const { t } = useTranslation();

  return (
    <Modal
      data-testid="app-confirm-modal"
      open={open}
      destroyOnClose={true}
      width={500}
      footer={false}
      onCancel={onCancel}
      bodyStyle={{ padding: 0 }}
      title={<h2 className={s.modalTitle}>{t(title)}</h2>}
    >
      <div className={s.modalContainer}>
        <section className={s.content}>{children}</section>
        <section className={s.modalFooter}>
          <AppButton
            isOutline={true}
            onClick={handleCancelClick}
            data-testid="confirm-no"
          >
            {t(cancelText)}
          </AppButton>
          <AppButton
            onClick={handleConfirmClick}
            isDanger={isDanger}
            data-testid="confirm-yes"
          >
            {t(btnTextConfirm)}
          </AppButton>
        </section>
      </div>
    </Modal>
  );
};
