import React from "react";

type AppIconProps = {
  width?: string;
  height?: string;
};

export const AppRuIcon: React.FC<AppIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 9.75H20V13.25H4V9.75Z" fill="#1B75BB" />
      <path
        d="M17.5 6H6.5C4.84325 6 4 7.23125 4 8.75V9.75H20V8.75C20 7.23125 19.1567 6 17.5 6Z"
        fill="#E6E7E8"
      />
      <path
        d="M4 14.25C4 15.7687 4.84325 17 6.5 17H17.5C19.1567 17 20 15.7687 20 14.25V13.25H4V14.25Z"
        fill="#EC1C24"
      />
    </svg>
  );
};
