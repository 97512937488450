import React from "react";

type AppImportIconProps = {
  width?: string;
  height?: string;
};

export const AppArchiveIcon: React.FC<AppImportIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.125C3 4.82663 3.11853 4.54048 3.3295 4.3295C3.54048 4.11853 3.82663 4 4.125 4H19.875C20.1734 4 20.4595 4.11853 20.6705 4.3295C20.8815 4.54048 21 4.82663 21 5.125V7.375C21 7.67337 20.8815 7.95952 20.6705 8.1705C20.4595 8.38147 20.1734 8.5 19.875 8.5V16.9375C19.875 17.6834 19.5787 18.3988 19.0512 18.9262C18.5238 19.4537 17.8084 19.75 17.0625 19.75H6.9375C6.19158 19.75 5.47621 19.4537 4.94876 18.9262C4.42132 18.3988 4.125 17.6834 4.125 16.9375V8.5C3.82663 8.5 3.54048 8.38147 3.3295 8.1705C3.11853 7.95952 3 7.67337 3 7.375V5.125ZM5.25 8.5V16.9375C5.25 17.3851 5.42779 17.8143 5.74426 18.1307C6.06072 18.4472 6.48995 18.625 6.9375 18.625H17.0625C17.5101 18.625 17.9393 18.4472 18.2557 18.1307C18.5722 17.8143 18.75 17.3851 18.75 16.9375V8.5H5.25ZM19.875 5.125H4.125V7.375H19.875V5.125ZM8.625 11.3125C8.625 11.1633 8.68426 11.0202 8.78975 10.9148C8.89524 10.8093 9.03832 10.75 9.1875 10.75H14.8125C14.9617 10.75 15.1048 10.8093 15.2102 10.9148C15.3157 11.0202 15.375 11.1633 15.375 11.3125C15.375 11.4617 15.3157 11.6048 15.2102 11.7102C15.1048 11.8157 14.9617 11.875 14.8125 11.875H9.1875C9.03832 11.875 8.89524 11.8157 8.78975 11.7102C8.68426 11.6048 8.625 11.4617 8.625 11.3125Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
