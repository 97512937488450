import Tabs, { TabsProps } from "antd/lib/tabs";

import s from "./app-tabs.module.scss";

type PropTypes = {
  id?: string;
  items: TabsProps["items"];
  defaultActiveKey: TabsProps["defaultActiveKey"];
  onChange: TabsProps["onChange"];
  activeKey?: TabsProps["activeKey"];
};

export const AppTabs: React.FC<PropTypes> = ({
  id,
  items,
  defaultActiveKey,
  activeKey,
  onChange,
}) => {
  const renderTabBar: TabsProps["renderTabBar"] = (props, DefaultTabBar) => {
    return <DefaultTabBar {...props} className={s.tabBar} />;
  };

  return (
    <Tabs
      id={id}
      className={s.tabs}
      items={items}
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      tabBarGutter={0}
      renderTabBar={renderTabBar}
      onChange={onChange}
    />
  );
};
