import React from "react";

type AppDotsIconProps = {
  width?: string;
  height?: string;
  fill?: string;
};

export const AppDotsIcon: React.FC<AppDotsIconProps> = ({
  width = "18",
  height = "18",
  fill = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="8" r="2" fill="#0057FF" fillOpacity="0.6" />
      <circle cx="3" cy="8" r="1" fill="#0057FF" fillOpacity="0.6" />
      <circle cx="11" cy="8" r="1" fill="#0057FF" fillOpacity="0.6" />
    </svg>
  );
};
