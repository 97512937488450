import React from "react";

type AppPauseIconProps = {
  width?: string;
  height?: string;
  fill?: string;
};

export const AppPauseIcon: React.FC<AppPauseIconProps> = ({
  width = "24",
  height = "24",
  fill = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00016 4.58464V11.918C6.00016 12.4703 5.55245 12.918 5.00016 12.918C4.44788 12.918 4.00016 12.4703 4.00016 11.918V4.58464C4.00016 4.03235 4.44788 3.58464 5.00016 3.58464C5.55245 3.58464 6.00016 4.03235 6.00016 4.58464ZM3.3335 4.58464C3.3335 3.66416 4.07969 2.91797 5.00016 2.91797C5.92064 2.91797 6.66683 3.66416 6.66683 4.58464V11.918C6.66683 12.8384 5.92064 13.5846 5.00016 13.5846C4.07969 13.5846 3.3335 12.8384 3.3335 11.918V4.58464ZM12.0002 4.58464V11.918C12.0002 12.4703 11.5524 12.918 11.0002 12.918C10.4479 12.918 10.0002 12.4703 10.0002 11.918V4.58464C10.0002 4.03235 10.4479 3.58464 11.0002 3.58464C11.5524 3.58464 12.0002 4.03235 12.0002 4.58464ZM9.3335 4.58464C9.3335 3.66416 10.0797 2.91797 11.0002 2.91797C11.9206 2.91797 12.6668 3.66416 12.6668 4.58464V11.918C12.6668 12.8384 11.9206 13.5846 11.0002 13.5846C10.0797 13.5846 9.3335 12.8384 9.3335 11.918V4.58464Z"
        fill="#737373"
      />
    </svg>
  );
};
