import cn from "classnames";
import { useTranslation } from "react-i18next";
import {
  AppModelIcon,
  AppMergeIcon,
  FilterIcon,
  GroupIcon,
  AppJoinIcon,
} from "../../../../components/icons";
import s from "../aside.module.scss";

type PropTypes = {
  handleDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string,
    nodeId?: string | number
  ) => void;
};

export const TransformationsList: React.FC<PropTypes> = ({
  handleDragStart,
}) => {
  const { t } = useTranslation();

  const items = [
    {
      key: "mergeTransformNode",
      icon: <AppMergeIcon width="14" height="14" className={s.transfromIcon} />,
      onDragStart: (event: React.DragEvent<HTMLDivElement>) =>
        handleDragStart(event, "mergeTransformNode"),
      title: t("page.process.form.step.lowcode.transformation.node.merge"),
    },
    // скрыто согласно задаче DLH-1967
    // {
    //   key: "filterTransformNode",
    //   icon: <FilterIcon width="14" height="14" className={s.transfromIcon} />,
    //   onDragStart: (event: React.DragEvent<HTMLDivElement>) =>
    //     handleDragStart(event, "filterTransformNode"),
    //   title: t("page.process.form.step.lowcode.transformation.node.filter"),
    // },
    // {
    //   key: "joinTransformNode",
    //   icon: <AppJoinIcon width="14" height="14" className={s.transfromIcon} />,
    //   onDragStart: (event: React.DragEvent<HTMLDivElement>) =>
    //     handleDragStart(event, "joinTransformNode"),
    //   title: t("page.process.form.step.lowcode.transformation.node.join"),
    // },
    // {
    //   key: "groupTransformNode",
    //   icon: <GroupIcon width="14" height="14" className={s.transfromIcon} />,
    //   onDragStart: (event: React.DragEvent<HTMLDivElement>) =>
    //     handleDragStart(event, "groupTransformNode"),
    //   title: t("page.process.form.step.lowcode.transformation.node.group"),
    // },
  ];

  return (
    <div>
      <div className={s.asideBody}>
        <div className={s.asideList} role="listbox">
          {items.map(({ key, icon, title, onDragStart }) => (
            <div
              className={cn(s.asideItem)}
              onDragStart={onDragStart}
              draggable
              role="option"
              key={key}
            >
              <div className={s.asideItemCol}>
                {icon}
                <div>
                  <div className={s.asideItemTitle}>{title}</div>
                </div>
              </div>
              <AppModelIcon />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
