import { Modal, Select } from "antd";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { addRuleToAttribute } from "../../../../api/storages/storages-acitons.api";
import { useAppDispatch } from "../../../../store/hooks";
import { selectAttributeRules } from "../../../../store/storages/attribute-rules.selector";
import { updateAttribute } from "../../../../store/storages/storage-entity-attributes.slice";
import { IEntriesAttributeTypes } from "../../../../store/types/storages.types";
import { AppButton } from "../../../app-button/app-button.component";
import { AppInput } from "../../../app-input/app-input.component";

import cssTable from "../app-origin-table.module.scss";
import { DefaultOptionType, FilterFunc } from "rc-select/lib/Select";
import s from "./attach-rule-to-attribute-modal.module.scss";
import { useEffect } from "react";
import { AppSearchIcon } from "../../../icons/app-search.icon";
import { useTranslation } from "react-i18next";

type PropTypes = {
  attribute: IEntriesAttributeTypes | null;
  open: boolean;
  onCancel: () => void;
};

const { Option } = Select;

export const AttachRuleToAttributeModal: React.FC<PropTypes> = ({
  attribute,
  open,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataRules = useSelector(selectAttributeRules);
  const mapRuleIds = (attr: IEntriesAttributeTypes) => {
    if (attr && attr.attribute_rules) {
      return attr.attribute_rules.map(
        (rule) => rule.attribute_rule_rule_template.id
      );
    } else {
      return [];
    }
  };

  const formInstance = useForm({
    mode: "onChange",
    defaultValues: {
      rule_template_id: mapRuleIds(attribute as IEntriesAttributeTypes),
    },
  });

  const { handleSubmit, formState, control, setValue } = formInstance;

  useEffect(() => {
    const initial = mapRuleIds(attribute as IEntriesAttributeTypes);
    if (initial) {
      setValue("rule_template_id", initial);
    }
  }, [attribute]);

  const submitForm = (data: { rule_template_id: number[] }) => {
    if (attribute) {
      const dataRequest = {
        modified_by: "admin",
        attribute_rule_rule_template_id: data.rule_template_id,
        attribute_rule_attribute_id: attribute.id,
      };
      dispatch(addRuleToAttribute(dataRequest))
        .unwrap()
        .then(() => {
          const rule = dataRules
            .reduce((acc, el) => {
              if (data.rule_template_id.includes(el.id)) {
                acc = [...acc, el] as any;
              }
              return acc;
            }, [])
            .map((r) => {
              return {
                attribute_rule_rule_template: r,
              };
            });

          if (rule) {
            const changes = { attribute_rules: rule };
            dispatch(
              updateAttribute({
                id: attribute.id,
                changes,
              })
            );
          }
        });
    }

    onCancel();
  };

  const renderTitle = () => {
    if (attribute?.attribute_rules.length) {
      return <span>{t("source.detail.card.table.action.edit_rule_list")}</span>;
    } else {
      return <span>{t("source.detail.card.table.action.add_rule")}</span>;
    }
  };

  const renderBtnTitle = () => {
    if (attribute?.attribute_rules.length) {
      return (
        <span>{t("page.process.form.step.lowcode.entity.edit.save")}</span>
      );
    } else {
      return <span>{t("source.detail.card.table.action.add_rule")}</span>;
    }
  };

  const handleFilterRules: FilterFunc<DefaultOptionType> = (input, option) => {
    return String(option?.children ?? "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <Modal
      destroyOnClose={true}
      open={open}
      footer={false}
      onCancel={onCancel}
      width={620}
      title={
        <div className={cssTable.tableModalHeader}>
          {renderTitle()}
          {attribute ? (
            <div className={cssTable.modalEntity}>
              <span>{t("source.detail.card.table.head.attribute")}: </span>
              <span>{attribute?.attribute_name}</span>
            </div>
          ) : null}
        </div>
      }
    >
      <form
        className={s.modalForm}
        onSubmit={handleSubmit((data) =>
          submitForm({ rule_template_id: [...data.rule_template_id] })
        )}
      >
        <AppInput
          sharedStyles={s.searchInput}
          control={control}
          showArrow={true}
          showSearch={true}
          filterOption={handleFilterRules}
          name={"rule_template_id"}
          placeholder={
            t("source.detail.card.modal.rules.placeholder") as string
          }
          suffixIcon={<AppSearchIcon />}
          mode="multiple"
        >
          {dataRules.map((rule) => (
            <Option value={rule.id} key={rule.id}>
              {rule.rule_template_name}
            </Option>
          ))}
        </AppInput>
        <AppButton
          disabled={!formState.isValid}
          htmlType={"submit"}
          sharedStyles={s.formButton}
        >
          {renderBtnTitle()}
        </AppButton>
      </form>
    </Modal>
  );
};
