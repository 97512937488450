import type { ReactFlowJsonObject } from "reactflow";
import { Schema } from "../../pages/create-process/schema/Schema";

export interface IShortProcess {
  id: number;
  name: string;
  status: EProcessStatus;
}

export type SelectedStorages = {
  entities_id: [];
  storage_id: number;
};

export interface IDomainProcess {
  id: number;
  process_name: string;
  process_description: string;
  process_is_deleted: boolean;
  process_sql: string;
  process_name_slug: string;
  lineage_process: IDomainLineageProcessShort[];
  process_status_flow: EProcessStatus | string;
  process_schema?: IProcessSchema | null; // low-code
  process_is_overwriting?: boolean;
  process_scheduler?: ProcessSchedulerType;
  process_selected_storages?: SelectedStorages[];
}

export interface IDomainLineageProcessShort {
  id: number;
  storage_name: string;
  storage_name_slug: string;
}

export interface IDomainLineageProcess {
  id: number;
  lineage_ent_out: IDomainLineageProcessEntity;
  lineage_ent_in: IDomainLineageProcessEntity;
}

export interface IDomainLineageProcessEntity {
  id: number;
  entity_schema: string;
  entity_schema_slug: string;
  entity_name: string;
  entity_name_slug: string;
  entity_storage: {
    id: number;
    storage_name: string;
    storage_name_slug: string;
  };
}

export interface IDomainProcessState {
  entries: IDomainProcess[];
  search: string;
  loading: boolean;
  error: string;
}

export enum EProcessStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  RUNNING = "RUNNING",
  ARCHIVED = "ARCHIVED",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export type ProcessSchedulerType = {
  minute: string | null;
  hour: string | null;
  week: string | null;
  month: string | null;
  timezone_offset: string | null;
  interval: string | null;
};

type ProcessSchemaAttribute = {
  id: number;
  attribute_name: string;
  attribute_name_slug: string;
  attribute_type: string;
  attribute_is_nullable: boolean;
  attribute_is_selected: boolean;
};

export type ProcessSchemaData = {
  storage_name: string;
  storage_name_slug: string;
  storage_id: number;
  x: number;
  y: number;
  entities: [
    {
      entity_id: number;
      entity_name: string;
      entity_name_slug: string;
      entity_schema_slug: string;
      attributes: ProcessSchemaAttribute[];
    }
  ];
};

export type ProcessSchemaConnection = {
  entity: {
    entity_name: string;
    entity_name_slug: string;
    entity_id: number;
    entity_schema_slug: string;
    storage_name_slug: string;
    attribute: {
      attribute_name: string;
      attribute_name_slug: string;
      attribute_id: number;
    };
  };
};

export type ProcessSchemaSettings = {
  connections: ProcessSchemaConnection[];
  merge_type: string;
};

export type ProcessSchemaTransformation = {
  settings: ProcessSchemaSettings;
  transformation_id: string;
  transformation_index: number;
  transformation_name: string;
  transformation_type: string;
  x: number;
  y: number;
};

export type IProcessSchema = {
  tree?: Schema;
  rf?: ReactFlowJsonObject;
};
