import { Modal, Select } from "antd";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppButton } from "../../../../components/app-button/app-button.component";
import { AppInput } from "../../../../components/app-input/app-input.component";
import type { FilterFunc, DefaultOptionType } from "rc-select/lib/Select";
import s from "../low-code.module.scss";
import sCreateProcess from "../../create-process.module.scss";

type PropTypes = {
  storage: any;
  open: boolean;
  onSubmit: (data: any) => void;
  onCancel: () => void;
};

const { Option } = Select;

const EntityForm = ({ storage, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const formInstance = useForm({
    mode: "onChange",
    defaultValues: {
      entities: [],
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = formInstance;

  const handleFilterSearchStoragses: FilterFunc<DefaultOptionType> = (
    input,
    option
  ) => {
    return String(option?.children ?? "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const submitForm = (data) => {
    const _storage = { ...storage };
    _storage.entities = storage.entities.filter((entity) =>
      data.entities.includes(entity.id)
    );
    onSubmit(_storage);
    onCancel();
  };

  return (
    <form
      className={sCreateProcess.formWrapper}
      onSubmit={handleSubmit((data) => submitForm(data))}
    >
      <div className={sCreateProcess.formInner}>
        <AppInput
          control={control}
          showArrow={true}
          showSearch={true}
          filterOption={handleFilterSearchStoragses}
          rules={{
            required: t("page.process.form.step.validation.required") || "",
          }}
          errors={errors}
          name={"entities"}
          placeholder={t("page.process.form.lowcode.entity.placeholder") || ""}
          mode="multiple"
          sharedStyles={s.inputEntity}
        >
          <></>
          <>
            {storage.entities.map((entity: any) => (
              <Option value={entity.id} key={entity.id}>
                {entity.entity_name}
              </Option>
            ))}
          </>
        </AppInput>
        <AppButton
          disabled={!isValid}
          htmlType={"submit"}
          sharedStyles={s.buttonEntity}
        >
          {t("page.process.first_process.action.add")}
        </AppButton>
      </div>
    </form>
  );
};

export const LowCodeSelectEntityModal: React.FC<PropTypes> = ({
  storage,
  open,
  onSubmit,
  onCancel,
}) => {
  if (!storage) return null;

  return (
    <Modal
      data-testid="low-code-select-entity-modal"
      destroyOnClose={true}
      open={open}
      footer={false}
      onCancel={onCancel}
      title={<div>{storage.storage_name}</div>}
    >
      <EntityForm storage={storage} onSubmit={onSubmit} onCancel={onCancel} />
    </Modal>
  );
};
