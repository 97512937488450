import { Switch } from "antd";
import React from "react";

interface IAppConditionSwitcherProps {
  checkedValue: string;
  uncheckedValue: string;
  onChange: (change: boolean) => void;
}

export const AppConditionSwitcher: React.FC<IAppConditionSwitcherProps> = ({
  uncheckedValue,
  checkedValue,
  onChange,
}) => {
  return (
    <>
      <Switch
        checkedChildren={checkedValue}
        unCheckedChildren={uncheckedValue}
        onChange={onChange}
      />
    </>
  );
};
