import * as _ from "lodash";
import type { Node } from "reactflow";

import { Transformation } from ".";
import type {
  AttributeType,
  FilterDataType,
  TransformationIncomerType,
} from "../../../store/types/low-code.types";
import { flatten } from "../../../utils/utils";

export class Filter extends Transformation {
  public data: FilterDataType | null;

  constructor(data?: any | undefined | null) {
    super(data);
    this.data = data ?? null;
  }

  getEntities(): number[] {
    // TODO
    return [];
  }

  public getAttributes() {
    return flatten(
      this.data?.incomers.map(({ entity }) =>
        entity.attributes.map((attr: AttributeType) => ({
          id: attr.id,
          entity_id: entity.id,
          attribute_schema: entity.entity_name,
          attribute_name: attr.attribute_name,
          attribute_name_slug: attr.attribute_name_slug,
          attribute_type: attr.attribute_type,
          attribute_internal_type: attr.attribute_internal_type,
          attribute_is_nullable: attr.attribute_is_nullable,
          attribute_is_selected: attr.attribute_is_selected,
        }))
      ) || []
    );
  }

  public getResult(): TransformationIncomerType {
    const key = `merge_${this.data?.node?.data.index}`;

    return {
      type: "merge",
      node: this.data?.node as Node,
      entity: {
        id: key,
        entity_name: this.data?.name || key,
        entity_name_slug: key,
        entity_schema: key,
        entity_schema_slug: key,
        attributes: this.getAttributes(),
      },
      storage: {
        id: key,
        storage_name: "",
        storage_name_slug: "",
        entities: [],
      },
    };
  }

  toObject(): any {
    return this.data === null
      ? []
      : [
          {
            index: this.data?.node?.data.index,
            id: this.data?.node.id,
            type: "filterTransformNode",
            parent:
              this.parent && this.parent.data
                ? { index: this.parent.data?.node.data.index, type: "filter" }
                : null,
            children: Array.from(this.children.values()).map((child) => ({
              index: child.data?.node.data.index,
              type: "filter",
            })),
            data: {
              filter_group: {
                index: this.data.settings.index,
                order: this.data.settings.order,
                title: this.data.name,
                source: {
                  id: this.data.incomers[0].storage.id,
                  type: this.data.incomers[0].type,
                  entity_id: String(this.data.incomers[0].entity.id),
                  entity_name: this.data.incomers[0].entity.entity_name,
                  entity_schema_slug:
                    this.data.incomers[0].entity.entity_schema_slug,
                  entity_name_slug:
                    this.data.incomers[0].entity.entity_name_slug,
                  storage_name_slug:
                    this.data.incomers[0].storage.storage_name_slug,
                },
                groups: [this.data.settings],
              },
            },
          },
        ];
  }
}
