import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const checkIsOnline = createAsyncThunk(
  "network/checkIsOnline",
  async () => {
    return navigator.onLine;
  }
);

const networkSlice = createSlice({
  name: "network",
  initialState: {
    isOnline: true,
  },
  reducers: {
    setIsOnline(state, action) {
      state.isOnline = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkIsOnline.fulfilled, (state, action) => {
      state.isOnline = action.payload;
    });
  },
});

export const { setIsOnline } = networkSlice.actions;

export const networkReducer = networkSlice.reducer;
