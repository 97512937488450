import axios, { AxiosRequestConfig } from "axios";

const instance = axios.create({});
const refreshUrl = "/auth/refresh/";
const tokenName = "X-CSRF-Token";
const refreshToken = "refreshToken";

instance.interceptors.request.use((config: any) => {
  if (config.url !== refreshUrl && config.url !== "/auth/login/") {
    config.headers.authorization = localStorage.getItem(tokenName);
  }
  return config;
});

instance.interceptors.response.use(
  (config: AxiosRequestConfig) => {
    return config;
  },
  async (error) => {
    const originRequest = error.config;
    const refresh = localStorage.getItem(refreshToken);
    const isAuthRequest = originRequest.url === "/auth/login/";
    const isRefreshRequest = originRequest.url === refreshUrl;

    if (
      error.response.status == 401 &&
      isRefreshRequest &&
      !originRequest._isRetry
    ) {
      window.location.replace("/#/auth?logout=true");
      return;
    }

    if (
      error.response.status == 401 &&
      originRequest &&
      !isAuthRequest &&
      !isRefreshRequest &&
      !originRequest._isRetry
    ) {
      originRequest._isRetry = true;
      const data = { refresh };
      try {
        const response = await instance.post(refreshUrl, data);
        localStorage.setItem(tokenName, response.data.access);
        return instance.request(originRequest);
      } catch {
        console.log("Not authorized");
      }
    }
    throw error;
  }
);
export default instance;
