import { RootState } from "../index";
import { storageEntitiesAdapter } from "./storage-entities.slice";

export const storageEntitiesSelectors =
  storageEntitiesAdapter.getSelectors<RootState>(
    (state) => state.storageEntities
  );

export const getEntitiesCount = (state: RootState) =>
  state.storageEntities.count;

export const getEntitiesData = (state: RootState) =>
  state.storageEntities.entitiesData;

export const getStorageSelected = (state: RootState) =>
  state.storageEntities.storageSelected;
