import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  error: "",
};

const tokenName = "X-CSRF-Token";

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onLogin: (state, { payload }) => {
      if (payload?.access) {
        state.error = "";
        localStorage.setItem(tokenName, payload.access);
        localStorage.setItem("refreshToken", payload.refresh);
        localStorage.setItem("isLoggedIn", "1");
        state.isLoggedIn = true;
      }
    },
    onError: (state, { payload }) => {
      state.error = payload;
    },
    onLogout: (state) => {
      localStorage.removeItem(tokenName);
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("isLoggedIn");
      state.isLoggedIn = false;
    },
    onCheckAuth: (state, { payload }) => {
      localStorage.setItem(tokenName, payload.access);
      state.isLoggedIn = true;
    },
  },
});

export const { onLogin, onLogout, onCheckAuth } = authSlice.actions;

export const authReducer = authSlice.reducer;
