import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../store/axios-instanse";
import { showMessage } from "../../store/snackbar/snackbar.slice";
import { ESnackbarState } from "../../store/types/snackbar.types";

export const getAttributeStatus = createAsyncThunk(
  "notification/rule",
  async (isNotification: boolean, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: "/dlh-backend/verification/results/show/",
      });

      if (response.data.length && isNotification) {
        dispatch(
          showMessage({
            message: "drawer.notification.errors_detected",
            type: ESnackbarState.ERROR,
            action: {
              actionText: "drawer.notification.errors_detected.explore",
              actionCb: "notification",
            },
          })
        );
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  }
);
