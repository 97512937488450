import { createSlice } from "@reduxjs/toolkit";
import { fetchRules } from "../../api/storages/storages-acitons.api";
import { IAttributeRuleInitialState } from "../types/attribute-rule.types";

const initialState: IAttributeRuleInitialState = {
  rules: [],
  search: "",
  error: "",
  loading: false,
};

const attributeRulesSlice = createSlice({
  name: "storages",
  initialState,
  reducers: {
    searchRules: (state, { payload }) => {
      state.search = payload;
    },
  },
  extraReducers: {
    [fetchRules.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchRules.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.rules = payload;
    },
    [fetchRules.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { searchRules } = attributeRulesSlice.actions;
export const attributeRulesReducer = attributeRulesSlice.reducer;
