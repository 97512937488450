import React from "react";

import { AppContent, AppRightBar } from "../../components/app-main.layout";

import { AppNavigation } from "../../components/app-navigation/app-navigation.component";

import s from "./hypothesis.module.scss";

import { AppInput } from "../../components/app-input/app-input.component";
import { AppSearchIcon } from "../../components/icons/app-search.icon";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import cn from "classnames";
import { AppCalendarIcon } from "../../components/icons/app-calendar.icon";
import { AppTabs } from "../../components/app-tabs/app-tabs.component";
import { AppUnderMaintain } from "../../components/app-under-maintain/app-under-maintain.component";

export const HypothesisPage = () => {
  const { control } = useForm({ mode: "onChange" });
  const { t } = useTranslation();

  const DESCRIPTION_MOCK =
    "Это описание гипотезы, где участвуют Источник 1 и Источник 2. Так же тут учавствует анализ Анализ 1";
  const DATE_MOCK = "12.03.2023";

  const hypothesisList = [
    {
      name: "Hypothesis 1",
      status: "Новая",
      description: DESCRIPTION_MOCK,
      sources: ["Источник 1", "Источник 2"],
      analysis: ["Анализ 1"],
      dateCreated: DATE_MOCK,
      dateUpdated: DATE_MOCK,
    },
    {
      name: "Hypothesis 2",
      status: "Новая",
      description: DESCRIPTION_MOCK,
      sources: ["Источник 12", "Источник 32"],
      analysis: ["Анализ 5"],
      dateCreated: DATE_MOCK,
      dateUpdated: DATE_MOCK,
    },
    {
      name: "Hypothesis 3",
      status: "Новая",
      description: DESCRIPTION_MOCK,
      sources: ["Источник 53", "Источник 53"],
      analysis: ["Анализ 6"],
      dateCreated: DATE_MOCK,
      dateUpdated: DATE_MOCK,
    },
    {
      name: "Hypothesis 4",
      status: "Новая",
      description: DESCRIPTION_MOCK,
      sources: ["Источник 3", "Источник 4"],
      analysis: ["Анализ 2"],
      dateCreated: DATE_MOCK,
      dateUpdated: DATE_MOCK,
    },
    {
      name: "Hypothesis 5",
      status: "Новая",
      description: DESCRIPTION_MOCK,
      sources: ["Источник 5", "Источник 6"],
      analysis: ["Анализ 3"],
      dateCreated: DATE_MOCK,
      dateUpdated: DATE_MOCK,
    },
    {
      name: "Hypothesis 6",
      status: "Новая",
      description: DESCRIPTION_MOCK,
      sources: ["Источник 7", "Источник 8"],
      analysis: ["Анализ 4"],
      dateCreated: DATE_MOCK,
      dateUpdated: DATE_MOCK,
    },
  ];

  const columns: ColumnsType<any> = [
    {
      width: 150,
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>Название</p>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => (
        <div className={s.rowWrapper}>
          <p className={s.tableContent}>{name}</p>
        </div>
      ),
    },
    {
      width: 100,
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>Статус</p>
        </div>
      ),
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <div className={s.rowWrapper}>
          <p className={s.tableContent} style={{ color: "blue" }}>
            {status}
          </p>
        </div>
      ),
    },
    {
      width: 200,
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>Описание</p>
        </div>
      ),
      dataIndex: "description",
      key: "description",
      render: (_, { description }) => (
        <div className={s.rowWrapper}>
          <p className={s.tableContent}>{description}</p>
        </div>
      ),
    },
    {
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>Источники данных:</p>
        </div>
      ),
      dataIndex: "sources",
      key: "sources",
      width: 150,
      render: (_, { sources }) => {
        return (
          <div className={cn(s.rowWrapper, s._tags)}>
            {sources.map((source, i) => {
              return (
                <Tag className={s.sourceTag} key={i}>
                  {source}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>Обнаружена в анализе:</p>
        </div>
      ),
      dataIndex: "analysis",
      key: "analysis",
      width: 150,
      render: (_, { analysis }) => {
        return (
          <div className={cn(s.rowWrapper, s._tags)}>
            {analysis.map((anal, i) => {
              return (
                <Tag className={s.sourceTag} key={i}>
                  {anal}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>Дата</p>
        </div>
      ),
      dataIndex: "type",
      key: "type",
      width: 100,
      render: (_, { dateCreated, dateUpdated }) => {
        return (
          <div className={cn(s.rowWrapper, s._column)}>
            <div className={s.dateInfo}>
              <AppCalendarIcon width={"16"} />
              <div className={s.dateInfoContent}>
                <span>Дата создания:</span>
                <p className={s.tableContent}>{dateCreated}</p>
              </div>
            </div>
            <div className={s.dateInfo}>
              <AppCalendarIcon width={"16"} />
              <div className={s.dateInfoContent}>
                <span>Дата изменения:</span>
                <p className={s.tableContent}>{dateUpdated}</p>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const renderList = () => {
    return (
      <>
        <AppTabs
          defaultActiveKey={"hypothesis"}
          onChange={() => {
            return null;
          }}
          items={[
            {
              label: "Каталог гипотез",
              key: "hypothesis",
              children: (
                <>
                  <div className={s.searchContainer}>
                    <AppInput
                      icon={<AppSearchIcon />}
                      sharedStyles={s.searchInput}
                      name={"search"}
                      control={control}
                      placeholder={
                        "Искать гипотезу (по названию: гипотизы, источника, анализа)"
                      }
                    />
                  </div>
                  <div className={s.domainListWrapper}>
                    <div className={s.domainList}>
                      <Table
                        bordered
                        scroll={{ x: 1400 }}
                        size="small"
                        columns={columns}
                        pagination={false}
                        dataSource={hypothesisList}
                      />
                    </div>
                  </div>
                </>
              ),
            },
            {
              label: "Анализ данных",
              key: "analysis",
              children: <></>,
            },
          ]}
        />
      </>
    );
  };

  return (
    <>
      <AppRightBar>
        <AppNavigation />
      </AppRightBar>
      <AppContent>
        <div className={s.container} id="domain-page">
          <AppUnderMaintain />
          {renderList()}
        </div>
      </AppContent>
    </>
  );
};
