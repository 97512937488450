import { FC, ReactNode } from "react";
import { useAppSelector } from "../../store/hooks";
import { selectOnline } from "../../store/network/network.selector";
import s from "./app-online.module.scss";
import { AppButton } from "../app-button/app-button.component";
import { AppSyncIcon } from "../icons/app-sync.icon";
import { AppInfoIcon } from "../icons/app-info.icon";
import { useTranslation } from "react-i18next";
interface IOnlineComponent {
  children: ReactNode;
}

export const OnlineAccess: FC<IOnlineComponent> = ({ children }) => {
  const isOnline = useAppSelector(selectOnline);
  const { t } = useTranslation();
  const handleReload = () => {
    window.location.reload();
  };
  if (!isOnline) {
    return (
      <>
        <div className={s.onlineAccess}>
          <AppInfoIcon
            sharedStyles={s.onlineAccessInfo}
            width={"48"}
            height={"48"}
          />
          <h2 className={s.onlineAccessTitle}>
            {t("app.connection.offline.title")}
          </h2>
          <AppButton onClick={handleReload}>
            {t("app.connection.offline.action")} <AppSyncIcon />
          </AppButton>
        </div>
        {children}
      </>
    );
  }

  return <>{children}</>;
};
