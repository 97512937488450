import s from "../home/home.module.scss";

import {
  AppButton,
  EButtonSize,
} from "../../components/app-button/app-button.component";

import React, { FormEvent, useEffect, useState } from "react";

import cn from "classnames";
import { PlusOutlined } from "@ant-design/icons";
import { RuleCard } from "../../components/rule-card/rule-card.component";
import {
  selectAllAttributeRules,
  selectSearchRules,
} from "../../store/storages/attribute-rules.selector";
import { AppInput } from "../../components/app-input/app-input.component";
import { AppSearchIcon } from "../../components/icons/app-search.icon";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchRules } from "../../api/storages/storages-acitons.api";
import { searchRules } from "../../store/storages/attribute-rules.slice";
import { AppLogoLoader } from "../../components/ui/app-animated-logo/app-animated-logo.component";
import { Modal } from "antd";
import { AppCreateRule } from "../../components/forms/app-create-rule-form/app-create-rule.form";
import { useTranslation } from "react-i18next";

export const AttributeRulesList = () => {
  const dispatch = useAppDispatch();
  const { rules, search, loading } = useAppSelector(selectAllAttributeRules);
  const searchList = useAppSelector(selectSearchRules);
  const [showCreateRuleModal, setShowCreateRuleModal] = useState(false);
  const { control } = useForm({ mode: "onChange" });
  const { t } = useTranslation();

  const isSearch = Boolean(search);

  const rulesList = isSearch ? searchList : rules;

  const onChangeSearch = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    dispatch(searchRules(value));
  };

  useEffect(() => {
    dispatch(fetchRules());
  }, []);

  const renderEmptyState = () => (
    <div className={s.emptyState}>
      <div className={cn(s.emptyContent, s.notSelected)}>
        <h2>{t("page.rule.no_rule")}</h2>
        <p>{t("page.rule.no_rule.description")}</p>
        <AppButton onClick={() => setShowCreateRuleModal(true)}>
          <>
            {t("page.rule.action.add")}
            <PlusOutlined size={56} />
          </>
        </AppButton>
      </div>
    </div>
  );

  const renderList = () => {
    return (
      <>
        <div className={s.searchContainer}>
          <AppInput
            icon={<AppSearchIcon />}
            sharedStyles={s.searchInput}
            onChange={onChangeSearch}
            name={"search"}
            control={control}
            placeholder={t("page.rule.placeholder.search") as string}
          />
          <AppButton
            isOutline
            buttonSize={EButtonSize.HUG}
            sharedStyles={s.rightBarButton}
            onClick={() => setShowCreateRuleModal(true)}
          >
            {t("page.rule.action.add")}
            <PlusOutlined size={56} />
          </AppButton>
        </div>
        <div className={s.searchListWrapper}>
          {rulesList.length ? (
            <div className={s.rulesList}>
              {rulesList.map((item, index) => (
                <RuleCard
                  key={`${item.rule_template_name}_${index}`}
                  data={item}
                />
              ))}
            </div>
          ) : null}

          <div>
            {isSearch && !rulesList.length && (
              <div className={s.searchEmpty}>
                {t("page.rule.placeholder.search.not_found")}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={s.home} id="home">
      <AppLogoLoader loading={loading} />
      <>{renderList()}</>
      <>{!isSearch && !rulesList.length && renderEmptyState()}</>
      <Modal
        destroyOnClose={true}
        open={showCreateRuleModal}
        footer={false}
        onCancel={() => setShowCreateRuleModal(false)}
        title={t("page.rule.action.add.form.title")}
      >
        <AppCreateRule
          onCancel={() => {
            setShowCreateRuleModal(false);
          }}
        />
      </Modal>
    </div>
  );
};
