import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../store/axios-instanse";

import {
  AuthResponse,
  LodinData,
  RefreshData,
} from "../../store/types/auth.types";

export const fetchAuthLogin = createAsyncThunk(
  "auth/login",
  async (data: LodinData) => {
    try {
      const response = await axiosInstance.post<AuthResponse>(
        "/auth/login/",
        data
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const fetchAuthRefresh = createAsyncThunk(
  "auth/refresh",
  async (data: RefreshData) => {
    try {
      const response = await axiosInstance.post("/auth/refresh/", data);

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
