export const AppQuestionIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02991 7.02991 3 12 3C16.9701 3 21 7.02991 21 12C21 16.9701 16.9701 21 12 21C7.02991 21 3 16.9701 3 12ZM4.52679 12C4.52679 16.1263 7.87366 19.4732 12 19.4732C16.1263 19.4732 19.4732 16.1263 19.4732 12C19.4732 7.87366 16.1263 4.52679 12 4.52679C7.87366 4.52679 4.52679 7.87366 4.52679 12ZM12 7.25893C12.8438 7.25893 13.6393 7.54821 14.242 8.07656C14.8687 8.625 15.2143 9.36228 15.2123 10.1518C15.2123 11.3129 14.4469 12.3576 13.2616 12.8136C13.0797 12.8839 12.9231 13.0073 12.8124 13.1679C12.7017 13.3284 12.6419 13.5186 12.6408 13.7136V14.1696C12.6408 14.258 12.5685 14.3304 12.4801 14.3304H11.5158C11.4275 14.3304 11.3551 14.258 11.3551 14.1696V13.7377C11.3551 13.2737 11.4917 12.8156 11.7549 12.4339C12.0141 12.0603 12.3757 11.775 12.8016 11.6123C13.4866 11.3491 13.9286 10.7766 13.9286 10.1518C13.9286 9.26585 13.0627 8.54464 12 8.54464C10.9373 8.54464 10.0714 9.26585 10.0714 10.1518V10.3045C10.0714 10.3929 9.99911 10.4652 9.91071 10.4652H8.94643C8.85804 10.4652 8.78571 10.3929 8.78571 10.3045V10.1518C8.78571 9.36228 9.13125 8.625 9.75804 8.07656C10.3607 7.55022 11.1562 7.25893 12 7.25893ZM11.4318 16.9879C11.2811 16.8372 11.1964 16.6328 11.1964 16.4196C11.1964 16.2065 11.2811 16.0021 11.4318 15.8514C11.5825 15.7007 11.7869 15.6161 12 15.6161C12.2131 15.6161 12.4175 15.7007 12.5682 15.8514C12.7189 16.0021 12.8036 16.2065 12.8036 16.4196C12.8036 16.6328 12.7189 16.8372 12.5682 16.9879C12.4175 17.1386 12.2131 17.2232 12 17.2232C11.7869 17.2232 11.5825 17.1386 11.4318 16.9879Z"
        fill={"var(--app-icon-color)" || "#737373"}
      />
    </svg>
  );
};
