import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Dragger from "antd/es/upload/Dragger";
import { UploadProps, message, UploadFile, Checkbox } from "antd";
import { ErrorMessage } from "@hookform/error-message";

import { AppCheckbox } from "../../app-checkbox/app-checkbox.component";
import { AppButton } from "../../app-button/app-button.component";
import { AppCsvIcon } from "../../icons/app-csv.icon";
import { AppXlsxIcon } from "../../icons/app-xlsx.icon";

import { uploadEntityFile } from "../../../api/storages/storages-acitons.api";
import { useAppDispatch } from "../../../store/hooks";

import s from "./app-file-form.module.scss";

interface IFileType {
  file: UploadFile;
  fileList: UploadFile[];
}

type AppFileFromProps = {
  entityId: number;
  storageId: number;
  onClose: () => void;
  fileFormat: string;
};

const addFileText = "source.detail.card.actions.actions_button.upload.start";

export const AppFileForm: React.FC<AppFileFromProps> = ({
  entityId,
  storageId,
  onClose,
  fileFormat,
}) => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState(addFileText);
  const { t } = useTranslation();

  const methods = useForm<{ file: IFileType | undefined; truncate: boolean[] }>(
    {
      mode: "onChange",
      defaultValues: {
        file: undefined,
        truncate: [true],
      },
    }
  );
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    watch,
    resetField,
    clearErrors,
  } = methods;

  const fileField = watch("file") as any;

  useEffect(() => {
    if (!fileField?.file) {
      setFile(addFileText);
    } else {
      const uploadFileFormat = fileField.file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (fileFormat !== uploadFileFormat) {
        setFile(addFileText);
        return;
      }
      setFile("source.detail.card.actions.actions_button.upload_csv.end");
    }
  }, [fileField]);

  const submitForm = (data: {
    file: IFileType | undefined;
    truncate: boolean[];
  }) => {
    const formData = new FormData();

    const fileObj: any = data.file?.file.originFileObj;
    delete fileObj.uid;

    formData.append("file", fileObj, data.file?.file.name);

    dispatch(
      uploadEntityFile({
        file: formData.get("file"),
        truncate: data.truncate[0],
        entity_id: entityId,
        storage_id: storageId,
        fileFormat,
      })
    )
      .unwrap()
      .then(() => onClose());
  };

  const dummyRequest = (action: any) => {
    setTimeout(() => {
      action.onSuccess("ok");
    });
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: `.${fileFormat}`,
    customRequest: dummyRequest,
    onChange(info) {
      const { status } = info.file;
      setFile("source.detail.card.actions.actions_button.upload_csv.end");
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(
          `${info.file.name} - ${t(
            "source.detail.card.actions.actions_button.upload_csv.success"
          )}`
        );
      } else if (status === "error") {
        message.error(
          `${info.file.name} - ${t(
            "source.detail.card.actions.actions_button.upload_csv.error"
          )}`
        );
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove(e) {
      setTimeout(() => {
        setValue("file", undefined);
        clearErrors("file");
      }, 500);
    },
  };

  const renderIcon = () => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (fileFormat) {
      case "xlsx":
        return <AppXlsxIcon />;
      default:
        return <AppCsvIcon />;
    }
  };

  const validateFileFormat = (value) => {
    const uploadFileFormat = value.file.name.split(".").pop().toLowerCase();
    return fileFormat === uploadFileFormat || "Недопустимый формат файла";
  };

  return (
    <form
      onSubmit={handleSubmit((data) => submitForm(data))}
      className={s.fileForm}
    >
      <Controller
        control={control}
        rules={{ required: true, validate: { validateFileFormat } }}
        render={({ field }) => (
          <Dragger {...props} {...field}>
            {renderIcon()}
            <p>{t(file, { fileFormat })}</p>
          </Dragger>
        )}
        name={"file"}
      />

      <ErrorMessage
        key={"file"}
        name={"file"}
        errors={errors}
        render={({ message }) => (
          <span className={s.inputError}>{message}</span>
        )}
      />

      <AppCheckbox name={"truncate"} control={control}>
        <Checkbox value={true}>
          {t("source.detail.card.actions.actions_button.upload_csv.clean")}
        </Checkbox>
        <></>
      </AppCheckbox>
      <AppButton
        sharedStyles={s.fileFormButton}
        disabled={!isValid}
        htmlType={"submit"}
      >
        {t("source.detail.card.actions.actions_button.upload_csv.send")}
      </AppButton>
    </form>
  );
};
