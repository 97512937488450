import { useNavigate } from "react-router";
import React, { ReactNode, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectAuth } from "../../store/auth/auth.selector";
import { onCheckAuth } from "../../store/auth/auth.slice";
import { fetchAuthRefresh } from "../../api/auth/auth-actions";
import { getCookieValue } from "../../utils/utils";

type AuthProviderType = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderType> = ({ children }) => {
  const { isLoggedIn } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("X-CSRF-Token");
    if (token) {
      const data = {
        refresh: localStorage.getItem("refreshToken"),
      };
      dispatch(fetchAuthRefresh(data)).then((data: any) => {
        dispatch(onCheckAuth(data.payload));
      });
    }
  }, []);

  useEffect(() => {
    const isLogged = localStorage.getItem("isLoggedIn");
    !isLogged && !isLoggedIn && navigate("/auth");
  }, [isLoggedIn]);

  return <>{children}</>;
};
