import React, { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

export const FilterIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 18C10.4477 18 10 17.5523 10 17C10 16.4477 10.4477 16 11 16H13C13.5523 16 14 16.4477 14 17C14 17.5523 13.5523 18 13 18H11ZM7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7ZM4 8C3.44772 8 3 7.55228 3 7C3 6.44772 3.44772 6 4 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H4Z"
      fill={"var(--app-icon-color)" || "black"}
      {...props}
    />
  </svg>
);
