import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import { BlockerFunction } from "@remix-run/router/router";
import { useTranslation } from "react-i18next";
import { Steps } from "antd";

import { fetchStorageForProcess } from "../../api/storages/storages-acitons.api";
import { createProcess } from "../../api/processes/processes-actions.api";
import { processGenerateSql } from "../../api/processes/processes-actions.api";
import { StepLowCodePlace } from "./steps/step-low-code-place/step-low-code-place.component";
import { AppConfirmModal } from "../../components/ui/app-confirm-modal/app-confirm-modal.component";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setTouched } from "../../store/processes/process-create/process-create.slice";
import { selectCreatedProcess } from "../../store/processes/process-create/process-create.selector";
import { onLogin } from "../../store/auth/auth.slice";
import { OnlineAccess } from "../../components/app-online/app-online.component";
import { AppContent } from "../../components/app-main.layout";
import { Step1 } from "./steps/step1.component";
import { Step2 } from "./steps/step2.component";
import { Step3 } from "./steps/step3.component";
import { EInterval, transformProcessDataToRequest } from "./process.helper";
import { AppArrowIcon } from "../../components/icons/app-arrow.icon";
import { EProcessStatus } from "../../store/types/processes.types";

import s from "./create-process.module.scss";

type PropTypes = { isLowcode?: boolean };

export const initialProcessData = {
  process_name: "",
  process_description: "",
  process_scheduler: {
    minute: null,
    hour: null,
    week: null,
    month: null,
    timezone_offset: null,
  },
  interval: EInterval.PER_HOUR,
  onSchedule: "default",
  process_is_overwriting: true,
  entities: [],
  process_sql: "",
};

const PROCESS_ROUTE = "/processes";

export const CreateProcessPage: React.FC<PropTypes> = ({
  isLowcode = false,
}) => {
  const [processData, setProcessData] =
    useState<typeof initialProcessData>(initialProcessData);
  const [storages, setStorages] = useState<any>(null);
  const { t } = useTranslation();
  const { touched } = useAppSelector(selectCreatedProcess);
  const [nextRoute, setNextRoute] = useState<string>();

  const [timestamp, setTimestamp] = useState(
    initialProcessData.process_scheduler
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleExit = () => {
    setTouch(false);
    setNextRoute("");
    setTimeout(() => {
      navigate(PROCESS_ROUTE);
    }, 1000);
  };

  const steps = isLowcode
    ? [
        {
          title: t("page.process.form.step.basic_details"),
          className: s.processBody,
          content: (
            <Step1
              onChangeStepData={changedData}
              processData={processData}
              isLowcode={isLowcode}
              setTimestamp={setTimestamp}
              onUpdate={onStepData}
            />
          ),
        },
        {
          title: t("page.process.form.step.enter_request"),
          className: s.processLowCodeBody,
          content: (
            <StepLowCodePlace
              processData={processData}
              storages={storages as any}
              timestamp={timestamp}
              onUpdate={onSetData}
              onSubmit={onSubmit}
              onBack={() => prev()}
              onExit={handleExit}
            />
          ),
        },
      ]
    : [
        {
          title: t("page.process.form.step.basic_details"),
          className: s.processBody,
          content: (
            <Step1
              onChangeStepData={changedData}
              processData={processData}
              onUpdate={onStepData}
              setTimestamp={setTimestamp}
            />
          ),
        },
        {
          title: t("page.process.form.step.source_chose"),
          className: s.processBody,
          content: (
            <Step2
              processData={processData}
              storages={storages}
              onBack={() => prev()}
              onUpdate={onStepData}
            />
          ),
        },
        {
          title: t("page.process.form.step.enter_request"),
          className: s.processBody,
          content: (
            <Step3
              storages={storages}
              processData={processData}
              onBack={() => prev()}
              onSubmit={onSubmit}
              onExit={handleExit}
            />
          ),
        },
      ];

  const setTouch = (condition: boolean) => {
    dispatch(setTouched(condition));
  };

  function changedData(fields: string[]) {
    const condition = fields.some((field) => field);
    setTouch(condition);
  }
  useEffect(() => {
    return () => {
      setTouch(false);
    };
  }, []);

  useEffect(() => {
    //FIXME: можно будет убрать если нормализовать стейт для источников и сущностей
    dispatch(fetchStorageForProcess())
      .unwrap()
      .then((data) => {
        const filtered = data.data.filter(
          (storage: { entities: string | any[]; id: number }) => {
            if (isLowcode) {
              return storage.entities.length > 0 && storage.id !== 0;
            } else {
              return storage.entities.length > 0;
            }
          }
        );
        setStorages(filtered);
      });
  }, []);

  function onSetData(data: any) {
    setProcessData({ ...processData, ...data });
  }

  function onStepData(data: any) {
    setProcessData({ ...processData, ...data });
    next();
  }

  function onSubmit(data: any) {
    const _processData = { ...processData, ...data };
    setProcessData(_processData);

    const request = transformProcessDataToRequest({
      processData: _processData,
      timestamp,
      isLowcode,
    });

    dispatch(createProcess(request))
      .unwrap()
      .then((data) => {
        if (data.code === "process") {
          setTouch(false);
          setNextRoute("");
          setTimeout(() => {
            navigate(PROCESS_ROUTE);
          }, 1000);
          setProcessData(initialProcessData);
        }
      });
  }

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const blockerFunction: BlockerFunction = ({ nextLocation }) => {
    setNextRoute(nextLocation.pathname);
    return touched;
  };

  const handleOnConfirm = (onConfirm: () => void) => {
    onConfirm();
    if (nextRoute === "/auth") {
      localStorage.removeItem("X-CSRF-Token");
      setNextRoute("");
    }
  };
  const handleOnCancel = (onCancel: () => void) => {
    onCancel();
    setNextRoute("");
    dispatch(
      onLogin(
        "MTM3NDM4OTY5ODk0OTpkNDlhZDllZC1mMGY5LTRmOGUtYWM1My01MTFiZjgxYTMxOTQ"
      )
    );
  };

  const renderTitle = useMemo(() => {
    if (!processData.process_name) {
      return <span>{t("page.process.form.step.title")}</span>;
    } else {
      return (
        <div className={s.processHeadContainer}>
          <span className={s.processHeadContainerLabel}>
            {t("page.process.form.name")}:
          </span>
          <span className={s.processHeadContainerTitle}>
            {processData.process_name}
          </span>
        </div>
      );
    }
  }, [processData.process_name]);

  return (
    <AppContent isFull>
      <OnlineAccess>
        <div className={s.processHead}>
          <div className={s.actionBlock} id={"first-current"}>
            <NavLink to={PROCESS_ROUTE} className={s.processBlock}>
              <AppArrowIcon side={"left"} />
              {renderTitle}
            </NavLink>
          </div>
          <Steps className={s.stepsContainer} current={current} items={items} />
        </div>
        <div className={steps[current].className || ""}>
          {steps[current].content}
        </div>
        <ReactRouterPrompt when={blockerFunction}>
          {({ isActive, onConfirm, onCancel }) => (
            <AppConfirmModal
              open={isActive}
              cancelText={"page.process.card.archive.form.decline.form"}
              title={"page.process.prompt.exit.title"}
              isDanger={true}
              btnTextConfirm={"page.process.prompt.exit.confirm" || ""}
              onConfirm={() => handleOnConfirm(onConfirm)}
              onCancel={() => handleOnCancel(onCancel)}
            >
              <p>{t("page.process.prompt.exit.description")}</p>
            </AppConfirmModal>
          )}
        </ReactRouterPrompt>
      </OnlineAccess>
    </AppContent>
  );
};
