import { stepsHome, stepsOrigin, stepsProcesses } from "./app-provider.steps";

import { useBasePathHook } from "../../hooks/use-base-path.hook";

import React, { createContext, ReactNode, useEffect, useState } from "react";
import { Step, Steps } from "intro.js-react";
import { useTranslation } from "react-i18next";

type AppIntroProviderPropTypes = {
  children: ReactNode;
};

interface IIntroProviderContext {
  onInitIntro: () => void;
  routePaths: {
    home: Array<string>;
  };
  entityActions: boolean;
  homeTab: boolean;
  autoComplete: "none" | "off";
}

export enum ERouterPaths {
  MAIN = "/",
  HOME = "/home",
  ORIGIN = "/origin",
  PROCESS = "/processes",
}

const routePaths = {
  home: [ERouterPaths.MAIN, ERouterPaths.HOME, ERouterPaths.ORIGIN],
};

const IntroContext = createContext<IIntroProviderContext>(
  {} as IIntroProviderContext
);

const AppIntroProvider: React.FC<AppIntroProviderPropTypes> = ({
  children,
}) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const pathname = useBasePathHook();

  const [enabled, setEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const [entityActions, onOpenEntityActions] = useState(false);
  const [homeTab, setHomeTab] = useState(false);
  const [autoComplete, setAutoComplete] = useState<"none" | "off">("off");
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/YaBrowser/i)) {
      setAutoComplete("none");
    } else {
      setAutoComplete("off");
    }
  }, []);

  const keyRemaster = (steps: Step[]) => {
    return steps.map((step) => {
      const position = step.position ? { position: step.position } : {};

      return {
        element: step.element,
        intro: t(step.intro as string),
        ...position,
      };
    });
  };

  useEffect(() => {
    switch (pathname) {
      case "/home":
        setSteps(keyRemaster(stepsHome));
        break;
      case "/":
        setSteps(keyRemaster(stepsHome));
        break;
      case "/origin":
        setSteps(keyRemaster(stepsOrigin));
        break;
      case "/processes":
        setSteps(keyRemaster(stepsProcesses));
        break;
    }
  }, [pathname, language]);

  const setOverflow = (overflow: "hidden" | "clear") => {
    const html = document.querySelector("html");
    const reset = () => html!.removeAttribute("style");
    switch (overflow) {
      case "hidden":
        html!.style.overflow = overflow;
        break;
      case "clear":
        reset();
        break;
      default:
        reset();
    }
  };

  const stepsOptions = {
    doneLabel: t("steps.action.end"),
    nextLabel: t("steps.action.next"),
    prevLabel: t("steps.action.back"),
    hidePrev: true,
    disableInteraction: true,
  };

  const onIntroDone = () => {
    setEnabled(false);
    onOpenEntityActions(false);
    setHomeTab(false);
    setOverflow("clear");
  };

  const onInitIntro = () => {
    setEnabled(true);
  };

  const changeSteps = (nextStep: number, nextElement: Element) => {
    setOverflow("hidden");
    if (pathname === ERouterPaths.ORIGIN && [6].includes(nextStep)) {
      onOpenEntityActions(true);
    } else {
      onOpenEntityActions(false);
    }

    const homeCondition =
      pathname === ERouterPaths.MAIN || pathname === ERouterPaths.HOME;

    if (homeCondition) {
      setHomeTab(true);
    } else {
      setHomeTab(false);
    }
  };

  return (
    <IntroContext.Provider
      value={{
        onInitIntro,
        routePaths,
        entityActions,
        autoComplete,
        homeTab,
      }}
    >
      <>
        <Steps
          steps={steps}
          onAfterChange={changeSteps}
          enabled={enabled}
          initialStep={initialStep}
          onExit={onIntroDone}
          options={stepsOptions}
        />
        {children}
      </>
    </IntroContext.Provider>
  );
};

export { IntroContext, AppIntroProvider };
