import React, { useState } from "react";
import { Select } from "antd";
import cn from "classnames";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

import { FilterFormItem } from ".";
import {
  FilterGroup,
  FilterItem,
  IEntityType,
} from "../../../../store/types/low-code.types";
import {
  AppDeleteIcon,
  ArrowDownIcon,
  PlusIcon,
} from "../../../../components/icons";
import { AppButton } from "../../../../components/app-button/app-button.component";

import s from "./low-code-filter-modal.module.scss";

type PropsType = {
  entity: IEntityType | null;
  group: FilterGroup;
  isFirst: boolean;
  raiseGroup: (group: FilterGroup) => void;
  removeGroup: (group: FilterGroup) => void;
};

const validate = (group: FilterGroup): boolean =>
  group.filters.every(({ isValid }) => isValid) &&
  group.groups.every(({ isValid }) => isValid);

export const FilterFormGroup: React.FC<PropsType> = ({
  entity,
  group,
  raiseGroup,
  removeGroup,
  isFirst,
}) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<number>(0);

  function addNewFilter(order: number): FilterItem {
    return { index: nanoid(), order, type: "filter", isValid: false };
  }

  function newGroup(order: number): FilterGroup {
    return {
      index: nanoid(),
      order,
      type: "group",
      filters: [addNewFilter(0)],
      groups: [],
      isValid: false,
      operator: "OR",
    };
  }

  const renderFilter = (filter: FilterItem) => {
    return (
      <div key={filter.index} className={s.filterItem}>
        <FilterFormItem
          entity={entity}
          filter={filter}
          onChange={({ attribute, condition, value, isValid }) => {
            const childFilter = group.filters.find(
              ({ index }) => index === filter.index
            );
            if (childFilter) {
              childFilter.attribute = attribute;
              childFilter.condition = condition;
              childFilter.value = value;
              childFilter.isValid = isValid;
            }
            raiseGroup(group);
          }}
        />
        {group.filters.length > 1 && (
          <AppButton
            onClick={() => {
              const idx = group.filters.findIndex(
                ({ index }) => index === filter.index
              );
              group.filters.splice(idx, 1);
              raiseGroup(group);
            }}
          >
            <AppDeleteIcon />
          </AppButton>
        )}
      </div>
    );
  };

  const renderGroup = (group: FilterGroup) => {
    return (
      <FilterFormGroup
        entity={entity}
        key={group.index}
        isFirst={false}
        group={group}
        raiseGroup={() => {
          group.isValid = validate(group);
          raiseGroup(group);
        }}
        removeGroup={(removedGroup) => {
          if (removedGroup.index === group.index) {
            removeGroup(removedGroup);
          } else {
            const idx = group.groups.findIndex(
              ({ index }) => index !== removedGroup.index
            );
            group.groups.splice(idx, 1);
            group.isValid = validate(group);
            raiseGroup(group);
          }
        }}
      />
    );
  };

  const renderItem = (item) => {
    if (item.type === "filter") {
      return renderFilter(item);
    } else {
      return renderGroup(item as FilterGroup);
    }
  };

  return (
    <div className={s.filterGroup}>
      <div
        className={cn(s.filterGroupItem, {
          [s.filterGroupItemBorder]:
            group.filters.length > 1 || group.groups.length,
        })}
      >
        {group.filters.length > 1 || group.groups.length ? (
          <div className={s.filterBrace}>
            <Select
              defaultValue="or"
              options={[
                { value: "OR", label: t("app.or") },
                { value: "AND", label: t("app.and") },
              ]}
              onSelect={(value) => {
                group.operator = value;
                raiseGroup(group);
              }}
              suffixIcon={<ArrowDownIcon />}
            />
          </div>
        ) : null}
        <div className={s.filterList}>
          {group.filters
            .concat(group.groups)
            .sort((a, b) => a.order - b.order)
            .map((item) => renderItem(item))}
        </div>
      </div>
      <div className={s.actions}>
        <div className={s.addActions}>
          <AppButton
            isOutline
            onClick={() => {
              group.filters.push(addNewFilter(order + 1));
              raiseGroup(group);
              setOrder(order + 1);
            }}
            sharedStyles={s.applyOperatorButton}
          >
            {t("page.process.form.step.lowcode.filter.operator.filter")} &nbsp;
            <PlusIcon width="14" height="14" />
          </AppButton>
          <AppButton
            isOutline
            sharedStyles={s.applyOperatorButton}
            onClick={() => {
              group.groups.push(newGroup(order + 1));
              raiseGroup(group);
              setOrder(order + 1);
            }}
          >
            {t("page.process.form.step.lowcode.filter.operator.group")} &nbsp;
            <PlusIcon width="14" height="14" />
          </AppButton>
        </div>
        {!isFirst && (
          <AppButton
            isOutline
            className={s.removeGroupButton}
            onClick={() => {
              removeGroup(group);
            }}
          >
            {t("page.process.form.step.lowcode.filter.group.remove")}
          </AppButton>
        )}
      </div>
    </div>
  );
};
