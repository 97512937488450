import s from "./app-input.module.scss";

import { AppPasswordIcon } from "../icons/app-password.icon";

import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ErrorMessage } from "@hookform/error-message";
import cn from "classnames";
import { RegisterOptions, Controller, Control } from "react-hook-form";
import { Input, InputProps, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

export interface IInputPropTypes {
  sharedStyles?: string;
  inputStyles?: string;
  name: string;
  registerOptions?: RegisterOptions;
  label?: string;
  isPassword?: boolean;
  icon?: ReactNode;
  rules?: RegisterOptions;
  errors?: FieldErrors;
  children?: Array<ReactElement<any, string | JSXElementConstructor<any>>>;
  control?: Control<any>;
  isTextArea?: boolean;
  withCustomChildren?: boolean;
  disabled?: boolean;
  wrapperClassName?: string;
}

export const AppInput: React.FC<
  (IInputPropTypes & InputProps & SelectProps) | any
> = ({
  sharedStyles,
  inputStyles,
  name,
  registerOptions,
  label,
  isPassword,
  isTextArea,
  withCustomChildren,
  children,
  control,
  icon,
  rules,
  errors,
  disabled = false,
  wrapperClassName = "",
  ...props
}) => {
  const [type, setType] = useState("text");
  const { t } = useTranslation();

  useEffect(() => {
    if (name === "password") {
      setType("password");
    } else {
      setType("text");
    }
  }, []);

  if (withCustomChildren) {
    return (
      <div className={cn(s.inputWrapper, wrapperClassName)} {...props}>
        <Controller
          control={control}
          rules={rules}
          render={() => children}
          name={name}
        />
      </div>
    );
  }
  if (children?.length) {
    return (
      <div className={cn(s.inputWrapper, wrapperClassName)}>
        {label && <span className={s.inputLabelTop}>{label}</span>}
        <Controller
          control={control}
          rules={rules}
          render={({ field }) => (
            <Select
              className={cn(s.select, sharedStyles)}
              notFoundContent={<>{t("app.input.select.not_found")}</>}
              disabled={disabled}
              {...field}
              {...props}
            >
              {children?.map((c, i) => {
                return React.cloneElement(c, { className: s.option, key: i });
              })}
            </Select>
          )}
          name={name}
        />
      </div>
    );
  }

  const renderError = useCallback(() => {
    if (errors) {
      return (
        <ErrorMessage
          name={name}
          errors={errors}
          render={({ message }) => (
            <span className={s.inputError}>{message}</span>
          )}
        />
      );
    }
  }, [errors]);

  const onChangeType = () => {
    switch (type) {
      case "password":
        setType("text");
        break;
      case "text":
        setType("password");
        break;
    }
  };

  const renderPassword = () => {
    return (
      <div className={s.iconWrapper} onClick={onChangeType}>
        <AppPasswordIcon
          show={type === "password"}
          width={"24"}
          height={"24"}
        />
      </div>
    );
  };

  const renderIcon = () => {
    return <div className={cn(s.iconWrapper)}>{icon}</div>;
  };

  return (
    <div className={cn(sharedStyles)}>
      <div className={cn(s.inputWrapper, wrapperClassName)}>
        {label && <span className={s.inputLabelTop}>{label}</span>}
        {type === "text" && icon && renderIcon()}
        {name === "password" && renderPassword()}
        <Controller
          control={control}
          rules={rules}
          render={({ field }) =>
            isTextArea ? (
              <TextArea
                className={cn(s.input, inputStyles)}
                rows={3}
                {...field}
                placeholder={props.placeholder}
                disabled={disabled}
                {...props}
              />
            ) : (
              <Input
                type={type}
                className={cn(s.input, inputStyles)}
                disabled={disabled}
                {...field}
                {...props}
              />
            )
          }
          name={name}
        />
        {renderError()}
      </div>
    </div>
  );
};
