import React, { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../store/hooks";
import { AppLogoutIcon } from "../icons/app-logout.icon";
import { AppQuestionIcon } from "../icons/app-question.icon";
import {
  ERouterPaths,
  IntroContext,
} from "../../providers/intro-provider/intro.provider";
import { selectAllConnections } from "../../store/connections/connnections.selector";
import { useBasePathHook } from "../../hooks/use-base-path.hook";
import { AppAnimatedLogo } from "../ui/app-animated-logo/app-animated-logo.component";
import { AppDLHLogoText } from "../icons/logo/app-dlh-logo-text.icon";
import { selectAllProcesses } from "../../store/processes/process-list/processes.selector";
import { AppNotification } from "../app-notification/app-notification.component";
import { storageEntitiesSelectors } from "../../store/storages/storage-entities.selector";
import { AppLanguage } from "./app-language/app-language.compenent";
import { selectCreatedProcess } from "../../store/processes/process-create/process-create.selector";

import s from "./app-header.module.scss";

export const AppHeader = () => {
  const navigate = useNavigate();
  const { onInitIntro, routePaths } = useContext(IntroContext);
  const { connections } = useAppSelector(selectAllConnections);
  const storageEntitiesTotal = useAppSelector(
    storageEntitiesSelectors.selectTotal
  );
  const { t, i18n } = useTranslation();
  const { entries: processData } = useAppSelector(selectAllProcesses);
  const pathname = useBasePathHook();
  const { touched: touchedProcessForm } = useAppSelector(selectCreatedProcess);

  const logOut = () => {
    navigate("/auth?logout=true");
  };

  const onShowIntro = () => {
    onInitIntro();
  };

  const renderIntroButton = useCallback(() => {
    const originCondition =
      ERouterPaths.ORIGIN === pathname && storageEntitiesTotal > 0;
    const entityCondition =
      [ERouterPaths.HOME, ERouterPaths.MAIN].includes(
        pathname as ERouterPaths
      ) && connections.length > 0;

    const processesCondition =
      [ERouterPaths.PROCESS].includes(pathname as ERouterPaths) &&
      processData.length > 0;

    if (originCondition || entityCondition || processesCondition) {
      return (
        <span id={"help-button"} onClick={onShowIntro} className={s.headerHow}>
          <AppQuestionIcon /> {t("header.tour")}
        </span>
      );
    }
  }, [connections, storageEntitiesTotal, processData, pathname]);

  return (
    <header className={s.header}>
      <Link to={"/"} className={s.headerLogo}>
        <AppAnimatedLogo className={s.logoAnimated} />
        <AppDLHLogoText />
      </Link>
      <div className={s.headerContent}>
        {renderIntroButton()}
        {/* comment in DLH-2816 */}
        {/* <AppNotification /> */}
        <AppLanguage />
        <div onClick={logOut} className={s.headerLogout}>
          <AppLogoutIcon />
        </div>
      </div>
    </header>
  );
};
