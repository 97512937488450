import React from "react";

type AppImportIconProps = {
  width?: string;
  height?: string;
};

export const AppImportIcon: React.FC<AppImportIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4286 15.2143V20.3571L20.0714 19.7143H4.64286L5.28571 20.3571V15.2143C5.28571 14.8543 4.99643 14.5714 4.64286 14.5714C4.28286 14.5714 4 14.8543 4 15.2143V20.3571C4 20.7107 4.28286 21 4.64286 21H20.0714C20.425 21 20.7143 20.7107 20.7143 20.3571V15.2143C20.7143 14.8543 20.425 14.5714 20.0714 14.5714C19.7114 14.5714 19.4286 14.8543 19.4286 15.2143ZM11.7143 3.64286V16.5C11.7143 16.8536 11.9971 17.1429 12.3571 17.1429C12.7107 17.1429 13 16.8536 13 16.5V3.64286C13 3.28286 12.7107 3 12.3571 3C11.9971 3 11.7143 3.28286 11.7143 3.64286ZM6.75786 11.8071L11.9007 16.95C12.1514 17.2007 12.5564 17.2007 12.8071 16.95L17.95 11.8071C18.2007 11.55 18.2007 11.145 17.95 10.8943C17.6929 10.6371 17.2879 10.6371 17.0371 10.8943L11.8943 16.0371H12.8007L7.65786 10.8943C7.40071 10.6371 6.99571 10.6371 6.745 10.8943C6.48786 11.145 6.48786 11.55 6.745 11.8007L6.75786 11.8071Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
