import { fetchConnections } from "../../api/connections/connections-actions.api";
import { randomColor } from "../../helpers/random-color.helper";
import {
  IConnectionsInitialStateTypes,
  IConnectionsTypes,
} from "../types/connections.types";

import { createSlice } from "@reduxjs/toolkit";

const initialState: IConnectionsInitialStateTypes = {
  connections: [],
  search: "",
  connectionTypes: [],
  error: "",
  loading: false,
};

const connectionsSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    onSearch: (state, { payload }) => {
      state.search = payload;
    },
  },
  extraReducers: {
    [fetchConnections.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchConnections.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      const result = payload.map((connect: IConnectionsTypes) => {
        connect.color = randomColor();
        return connect;
      });
      state.connections = result;
    },
    [fetchConnections.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { onSearch } = connectionsSlice.actions;

export const connectionReducer = connectionsSlice.reducer;
