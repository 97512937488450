import { createApi } from "@reduxjs/toolkit/query/react";

import { showMessage } from "../../store/snackbar/snackbar.slice";
import { ESnackbarState } from "../../store/types/snackbar.types";
import { IStorageType } from "../../store/types/storages.types";
import { setEntities } from "../storages/storage-entities.slice";
import { normalizedStorage } from "../../api/storages/storages-acitons.api";
import { setAttributes } from "../storages/storage-entity-attributes.slice";
import { setStorages } from "../storages/storages.slice";
import { setCurrentStorage } from "../storages/storage.slice";

export type Channel = "message" | "something else";

type NotificationDataType = {
  process_name: number;
  variant: ESnackbarState;
  message: string;
  timestamp: number;
};

type EventDataType = {
  event_name: String;
  message: IStorageType;
  timestamp: Number;
};

const getNotificationMessage = (data) => {
  switch (data.code) {
    case "process_created":
      return "notification.process.result";
    case "xlsx_import_error_invalid_xlsx_file":
      return "notification.file.xlsx.error";
    case "xlsx_import_correct_xlsx_file":
      return "notification.file.xlsx.success";
    case "storage_import_status":
      if (data.data.storage_import_status == "stopped") {
        if (data.data.storage_import_type == "one_time") {
          return "source.notification.one_time_data_import.stopped";
        } else if (data.data.storage_import_type == "continuous") {
          return "source.notification.permanent_import.stopped";
        }
      } else if (data.data.storage_import_status == "running") {
        if (data.data.storage_import_type == "one_time") {
          return "source.notification.one_time_data_import.running";
        } else if (data.data.storage_import_type == "continuous") {
          return "source.notification.permanent_import.running";
        }
      }
  }
};

const setNotification = (dispatch, data, type = ESnackbarState.SUCCESS) => {
  dispatch(
    showMessage({
      message: getNotificationMessage(data),
      transOptions: data.data,
      type,
      action: {
        actionText: "drawer.notification.errors_detected.explore",
        actionCb: data.code,
        actionData: data.data,
      },
    })
  );
};

export interface Message {
  message: NotificationDataType | EventDataType;
}

let ws: WebSocket;

const wsInited = () =>
  fetch("/config.json")
    .then((e) => e.json())
    .then((config) => {
      ws = new WebSocket(`${config.WS_URL}/log/stream/`);
    });

const wsConnected = () =>
  new Promise((resolve, reject) => {
    try {
      ws.addEventListener("open", (event) => {
        resolve(event);
      });
    } catch (err) {
      reject(err);
    }
  });

export const wsApi = createApi({
  reducerPath: "wsApi",
  async baseQuery() {
    await wsInited();
    ws.addEventListener("close", function close() {
      wsInited();
    });
    return { data: {} };
  },
  endpoints: (build) => ({
    getMessage: build.query<Message, Channel>({
      query: (channel) => `${channel}`,
      async onCacheEntryAdded(
        _,
        { dispatch, cacheEntryRemoved, cacheDataLoaded }
      ) {
        await cacheDataLoaded;
        await wsConnected();

        try {
          const listener = (event: MessageEvent) => {
            const { message: data } = JSON.parse(event.data);

            if (
              typeof data.data === "object" &&
              data.code === "process_created" &&
              data.data?.process_id
            ) {
              setNotification(dispatch, data);
            }

            switch (data.code) {
              case "model-import-result": {
                const normalizeData = normalizedStorage(data.message);

                dispatch(setEntities(normalizeData.entities));
                dispatch(setAttributes(normalizeData.attributes));
                dispatch(setStorages(normalizeData.storage));
                break;
              }
              case "process_created": {
                setNotification(dispatch, data);
                break;
              }
              case "xlsx_import_error_invalid_xlsx_file": {
                setNotification(dispatch, data, ESnackbarState.ERROR);
                break;
              }
              case "xlsx_import_correct_xlsx_file": {
                setNotification(dispatch, data, ESnackbarState.SUCCESS);
                break;
              }
              case "storage_import_status": {
                setNotification(dispatch, data);
                const {
                  storage_id,
                  storage_import_type,
                  storage_import_status,
                } = data.data;
                dispatch(
                  setCurrentStorage({
                    id: storage_id,
                    storage_import_type,
                    storage_import_status,
                  })
                );
                break;
              }
              default:
                dispatch(
                  showMessage({
                    message: data.message,
                    type: data.variant,
                  })
                );
            }
          };

          ws.addEventListener("message", listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        await cacheEntryRemoved;
        ws.close();
      },
    }),
  }),
});

export const { useGetMessageQuery } = wsApi;
