import { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

export const AppLogoutIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#737373"
      fillRule="evenodd"
      d="M13 21H5.286A1.287 1.287 0 0 1 4 19.714V4.286A1.287 1.287 0 0 1 5.286 3H13a1.287 1.287 0 0 1 1.286 1.286v1.928H13V4.286H5.286v15.428H13v-1.928h1.286v1.928A1.287 1.287 0 0 1 13 21Zm3.968-8.357-2.306 2.305.91.91L19.428 12 15.57 8.143l-.909.909 2.306 2.305h-9.11v1.286h9.11Z"
      clipRule="evenodd"
    />
  </svg>
);
