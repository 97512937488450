import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { ArrowDownIcon } from "../../../../components/icons";

import s from "./low-code-filter-modal.module.scss";

type PropsType = {
  operatorKey: string | null;
  onChange: (value: string | null) => void;
};

export const FilterFormItemOperatorKey: React.FC<PropsType> = ({
  operatorKey,
  onChange,
}) => {
  const { t } = useTranslation();
  const operators = [
    "is_empty",
    "is_not_empty",
    "is_true",
    "is_false",
    "in_list",
    "gt",
    "lt",
    "gte",
    "lte",
    "eq",
    "neq",
    "contains",
    "not_contains",
    "starts_with",
    "not_starts_with",
    "ends_with",
    "not_ends_with",
  ];

  return (
    <div className="filterSelect">
      <Select
        showArrow={true}
        showSearch={true}
        allowClear={true}
        placeholder={t("page.process.form.step.lowcode.filter.operator.select")}
        suffixIcon={<ArrowDownIcon fill="#0057FF" />}
        onChange={(key) => {
          onChange(operators.find((op) => op === key) || null);
        }}
        value={operatorKey}
      >
        {operators.map((key) => (
          <Select.Option value={key} key={key} className={s.filterSelectOption}>
            {t(`page.process.form.step.lowcode.filter.operator.${key}`)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
