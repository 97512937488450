import React from "react";

type PlusIconProps = {
  width?: string;
  height?: string;
};

export const PlusIcon: React.FC<PlusIconProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 8.99799H8V13.998C8 14.2632 7.89464 14.5176 7.70711 14.7051C7.51957 14.8926 7.26522 14.998 7 14.998C6.73478 14.998 6.48043 14.8926 6.29289 14.7051C6.10536 14.5176 6 14.2632 6 13.998V8.99799H1C0.734784 8.99799 0.48043 8.89263 0.292893 8.70509C0.105357 8.51756 0 8.2632 0 7.99799C0 7.73277 0.105357 7.47842 0.292893 7.29088C0.48043 7.10334 0.734784 6.99799 1 6.99799H6V1.99799C6 1.73277 6.10536 1.47842 6.29289 1.29088C6.48043 1.10334 6.73478 0.997986 7 0.997986C7.26522 0.997986 7.51957 1.10334 7.70711 1.29088C7.89464 1.47842 8 1.73277 8 1.99799V6.99799H13C13.2652 6.99799 13.5196 7.10334 13.7071 7.29088C13.8946 7.47842 14 7.73277 14 7.99799C14 8.2632 13.8946 8.51756 13.7071 8.70509C13.5196 8.89263 13.2652 8.99799 13 8.99799Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
