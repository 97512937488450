import { Input, Tooltip } from "antd";
import { useCallback } from "react";

import { IAttributeRule } from "../../store/types/attribute-rule.types";
import { AppTruncateText } from "../app-truncate-text/app-truncate.component";
import {
  Card,
  CardBody,
  CardLabelBox,
  CardMenuItem,
  CardTitle,
} from "../ui/app-card/app-card.component";
import s from "./rule-card.module.scss";
import { AppEditIcon } from "../icons/app-edit.icon";
import { AppDeleteIcon } from "../icons/app-delete.icon";
import { useTranslation } from "react-i18next";

type PropTypes = {
  data: IAttributeRule;
};

export const RuleCard: React.FC<PropTypes> = ({ data }) => {
  const { t } = useTranslation();

  const createMarkup = useCallback(() => {
    return { __html: data.rule_template_description };
  }, [data]);

  const cardActions = [
    <CardMenuItem
      key="edit"
      text={t("page.process.card.actions.edit")}
      icon={<AppEditIcon />}
      isDisabled={true}
    />,
    <CardMenuItem
      key="delete"
      text={t("page.process.card.actions.delete")}
      icon={<AppDeleteIcon />}
      isDisabled={true}
    />,
  ];

  const inputRegexp = (
    <Input className={s.inputRegexp} value={data.rule_template_content} />
  );

  return (
    <Card actions={cardActions}>
      <CardTitle>{data.rule_template_name}</CardTitle>
      <CardBody>
        <CardLabelBox className="mb-16" label={t("page.rule.card.regex")}>
          {data.rule_template_content.length >= 50 ? (
            <Tooltip
              title={data.rule_template_content}
              placement="topRight"
              trigger={"focus"}
              color={"#ffffff"}
            >
              {inputRegexp}
            </Tooltip>
          ) : (
            inputRegexp
          )}
        </CardLabelBox>
        <CardLabelBox label={t("page.rule.card.description")}>
          <AppTruncateText
            height={26}
            content={<span dangerouslySetInnerHTML={createMarkup()} />}
          >
            {data.rule_template_description}
          </AppTruncateText>
        </CardLabelBox>
      </CardBody>
    </Card>
  );
};
