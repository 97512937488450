import s from "./origin-button.module.scss";

import { IConnectionsTypes } from "../../../store/types/connections.types";

import { AppDbIcon } from "../../icons/app-db.icon";
import { AppTruncateText } from "../../app-truncate-text/app-truncate.component";
import { AppSyncIcon } from "../../icons/app-sync.icon";
import { useAppDispatch } from "../../../store/hooks";
import { updateModelAction } from "../../../api/storages/storages-acitons.api";

import { AppDoneIcon } from "../../icons/app-done.icon";

import cn from "classnames";
import React, { useState, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type TOriginButtonType = {
  origin: IConnectionsTypes;
  index: number;
};

export const OriginButton: React.FC<TOriginButtonType> = ({
  origin,
  index,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const { t } = useTranslation();

  function updateModel(event: MouseEvent) {
    event.preventDefault();
    setLoading(true);
    dispatch(updateModelAction(origin.id))
      .unwrap()
      .then(() =>
        setTimeout(() => {
          setLoading(false);
          setComplete(true);
          setTimeout(() => setComplete(false), 2000);
        }, 2000)
      )
      .catch((err) => {
        setLoading(false);
        setComplete(false);
      });
  }

  const setId = () => {
    if (index === 0) {
      return {
        id: "first-current",
      };
    }
  };

  return (
    <Link to={`/origin/${origin.id}`} {...setId()} className={s.originBlock}>
      <div className={s.originBlockHover}>
        <div className={cn(s.originRow, s.rowContainer)}>
          <AppTruncateText height={26} content={<h3>{origin.storage_name}</h3>}>
            {origin.storage_name}
          </AppTruncateText>
          <div
            className={s.originColor}
            style={{ backgroundColor: `#${origin.color}` }}
          ></div>
        </div>
        <div className={s.rowContainer}>
          <div className={s.originConnectionType}>
            <span>{t("page.list.card.source_type")}</span>
            <AppDbIcon type={origin.storage_datasource_code} />
          </div>
          <div className={s.originConnectionType}>
            <span>{t("page.list.card.source_status")}</span>
            <span className={s.originConnectionState}>
              {t("page.list.card.source_online")}
            </span>
          </div>
        </div>
      </div>
      {origin.storage_datasource_code !== "ds_xlsx" && (
        <div
          id={"refresh-current-storage-button"}
          className={cn(s.actionWrapper, s.originWrapper)}
          onClick={updateModel}
        >
          <div className={s.originRefresh}>
            {!complete ? (
              <>
                {t("page.list.card.update_model")}
                <AppSyncIcon
                  sharedStyles={cn({ [s.onLoading]: loading })}
                  height={"20"}
                  width={"20"}
                />
              </>
            ) : (
              <>
                {t("app.model.updated")}!
                <AppDoneIcon height={"20"} width={"20"} />
              </>
            )}
          </div>
        </div>
      )}
    </Link>
  );
};
