import { ISnackBarInitialStateType } from "../types/snackbar.types";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ISnackBarInitialStateType = {
  message: "",
  type: null,
  action: {} as any,
  transOptions: {},
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showMessage: (
      state,
      { payload }: PayloadAction<ISnackBarInitialStateType>
    ) => {
      const { message, type, action, transOptions } = payload;
      state.message = message;
      state.type = type;
      state.action = action;
      state.transOptions = transOptions;
    },
    clearMessage: (state) => {
      state.message = "";
      state.type = null;
      state.action = {} as any;
      state.transOptions = {};
    },
  },
});

export const { showMessage, clearMessage } = snackbarSlice.actions;

export const snackbarReducer = snackbarSlice.reducer;
