import React from "react";

import { AppContent, AppRightBar } from "../../components/app-main.layout";
import { AppNavigation } from "../../components/app-navigation/app-navigation.component";

import { AttributeRulesList } from "./attribute-rules-list.page";

export const DataQuality = () => {
  return (
    <>
      <AppRightBar>
        <AppNavigation />
      </AppRightBar>

      <AppContent>
        <AttributeRulesList />
      </AppContent>
    </>
  );
};
