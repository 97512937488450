import { useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row, Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { TransformationIncomerType } from "../../../../store/types/low-code.types";
import { AppJoinIcon, AppSearchIcon } from "../../../../components/icons";
import { JoinInfo } from "./low-code-join-info";
import { AppButton } from "../../../../components/app-button/app-button.component";
import { JoinCollapseStep2 } from "./low-code-join-collapse-step2";
import { AppInput } from "../../../../components/app-input/app-input.component";
import { debounce } from "../../../../utils/utils";
import { useAppSelector } from "../../../../store/hooks";
import { filterEntitiesAndAttributes } from "../../../../helpers/search.helper";
import { selectCreatedProcess } from "../../../../store/processes/process-create/process-create.selector";
import { truncateText } from "../../../../helpers/truncate-text.helper";

import s from "./join-modal.module.scss";

type PropsType = {
  source: TransformationIncomerType[];
  onSubmit: any;
  onCancel: any;
  transformNode: any;
  changeStep: (number) => void;
};

export const JoinFormStep2: React.FC<PropsType> = ({
  source: incomers,
  onSubmit,
  onCancel,
  transformNode: node,
  changeStep,
}) => {
  const { t } = useTranslation();
  const { joinsData } = useAppSelector(selectCreatedProcess);
  const [attributes, setAttributes] = useState(
    joinsData.map(({ title }) => {
      return {
        attribute_name: title,
        entity_name: "",
      };
    })
  );

  const originEntities = incomers.map(({ entity }) => entity);

  const [entities, setEntities] = useState<any[]>(originEntities);

  const onChangeSearch = (event: React.FormEvent<HTMLInputElement>) => {
    const searchValue = (event.target as HTMLInputElement).value;
    setEntities(
      searchValue
        ? filterEntitiesAndAttributes(originEntities, searchValue)
        : originEntities
    );
  };
  const debounceSearch = debounce(onChangeSearch, 500);

  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: { name: "" },
  });

  const addAttribute = (attribute) => {
    setAttributes([...attributes, attribute]);
  };

  const removeAttribute = (attribute) => {
    const newArr = attributes.filter(
      ({ attribute_name }) => attribute_name !== attribute
    );
    setAttributes(newArr);
  };

  const addAttributes = (allAttributes) => {
    setAttributes([...attributes, ...allAttributes]);
  };

  const removeAllAttributes = (allAttributes) => {
    const filteredAttr = attributes
      .filter(
        ({ attribute_name }) =>
          !allAttributes.find(
            ({ attribute_name: attrName }) => attrName === attribute_name
          )
      )
      .concat(
        allAttributes.filter(
          ({ attribute_name }) =>
            !attributes.find(
              ({ attribute_name: attrName }) => attrName === attribute_name
            )
        )
      );
    setAttributes(filteredAttr);
  };

  return (
    <form
      onSubmit={handleSubmit(() => {
        onCancel();
        // if (isValidSettings) {
        //   submitForm({ ...data, joins, name });
        // }
      })}
    >
      <Row gutter={0}>
        <Col span={18}>
          <div className={s.leftBlock}>
            <div className={s.tableName}>
              <AppJoinIcon />
              <div className={s.tableNameElement}>table name</div>
            </div>
            <Table
              columns={attributes.map(({ attribute_name, entity_name }) => {
                const wholeTitle = entity_name
                  ? `${attribute_name}_${entity_name}`
                  : attribute_name;
                const { text: truncatedTitle, isTruncated } = truncateText(
                  wholeTitle,
                  24
                );

                return {
                  title: () =>
                    isTruncated ? (
                      <Tooltip title={wholeTitle} color={"white"}>
                        {truncatedTitle}
                      </Tooltip>
                    ) : (
                      truncatedTitle
                    ),
                  dataIndex: wholeTitle,
                  key: wholeTitle,
                  width: "260px",
                };
              })}
              scroll={{ x: "max-content" }}
            />
            <JoinInfo title="page.process.form.step.lowcode.join.modal.information.text.table" />
          </div>
        </Col>
        <Col span={6}>
          <div className={s.rightBlock}>
            <div className={s.rightBlockHeader}>
              {t("page.process.form.step.lowcode.join.modal.add.entities")}
            </div>
            <AppInput
              icon={<AppSearchIcon />}
              sharedStyles={s.searchInput}
              onChange={debounceSearch}
              name={"search"}
              control={control}
              placeholder={t(
                "page.process.form.step.lowcode.union.search.placeholder" as string
              )}
            />
            {entities.map(({ id, entity_name, attributes }) => {
              return (
                <JoinCollapseStep2
                  key={id}
                  id={id}
                  entityName={entity_name}
                  attributes={attributes}
                  addAttribute={addAttribute}
                  addAttributes={addAttributes}
                  removeAttribute={removeAttribute}
                  removeAllAttributes={removeAllAttributes}
                />
              );
            })}
          </div>
        </Col>
      </Row>
      <div className={s.footer}>
        <div className={s.footerLeftBtns}>
          <AppButton onClick={() => changeStep(1)}>
            <span>{t("page.process.form.step.action.back")}</span>
          </AppButton>
          <AppButton disabled={true}>
            <span>{t("page.process.form.step.lowcode.merge.view_sql")}</span>
          </AppButton>
        </div>
        <div className={s.footerTitle}>
          {t("page.process.form.step.lowcode.join.result.table")}
        </div>
        <AppButton
          disabled={joinsData.every((item) => item.title == undefined)}
          htmlType="submit"
        >
          {t("page.process.form.step.lowcode.merge.submit")}
        </AppButton>
      </div>
    </form>
  );
};
