import { Select } from "antd";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useWizard } from "react-use-wizard";
import type { FilterFunc, DefaultOptionType } from "rc-select/lib/Select";

import { AppInput } from "../../app-input/app-input.component";
import { AppSchemeIcon } from "../../icons/app-scheme.icon";
import s from "../create-process-modal.module.scss";
import { getStorageIdByKey } from "../../../helpers/process.helper";

import { StepFooter, StepLayout } from "./step-layout.component";

const { Option } = Select;
export const STORAGE_ID_PREFIX = "storageid";

export const Step2 = ({ storages, processData, onUpdate }: any) => {
  const { nextStep } = useWizard();
  const formInstance = useForm({
    mode: "onChange",
    defaultValues: {
      storageIds: processData.rawStorageIds || [],
      entities: processData.rawEntities || {},
    },
  });
  const {
    control,
    unregister,
    watch,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = formInstance;
  const watchEntityIds = watch("storageIds") || [];

  const submitForm = (data: any) => {
    const prepareEntities = Object.entries(data.entities).reduce<
      { id: number }[]
    >((acc, curr: any) => {
      if (Array.isArray(curr[1])) {
        return [...acc, ...curr[1].map((id) => ({ id }))];
      }
      return acc;
    }, []);

    onUpdate({
      entities: prepareEntities,
      rawStorageIds: data.storageIds,
      rawEntities: data.entities,
    });
    nextStep();
  };

  const storageEntities = storages
    ? watchEntityIds.map((id: number) => {
        return storages.find(
          (storage: { id: any }) => Number(storage.id) === Number(id)
        );
      })
    : [];

  const handleFilterSearchStoragses: FilterFunc<DefaultOptionType> = (
    input,
    option
  ) => {
    return String(option?.children ?? "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  useEffect(() => {
    // Выполняю сравнение текущих источников и значений сущностей в форме
    // Если есть несовпадения, то удаляю данные по тем источникам которых уже нет в форме
    const entitiesValue = getValues("entities") || {};
    Object.keys(entitiesValue).forEach((key) => {
      const storageId = getStorageIdByKey(key);
      if (!watchEntityIds.includes(storageId)) {
        unregister(`entities.${name}`);
      }
    });
  }, [watchEntityIds]);

  if (!storages) return null;

  return (
    <StepLayout onValidate={trigger}>
      <form
        className={s.formWrapper}
        onSubmit={handleSubmit((data) => submitForm(data))}
      >
        <div>
          <h3 className={s.formTitle}>Выберите источники</h3>

          <AppInput
            control={control}
            showArrow={true}
            showSearch={true}
            filterOption={handleFilterSearchStoragses}
            rules={{ required: "Заполните это поле" }}
            errors={errors}
            name={"storageIds"}
            placeholder={"Выберите источники"}
            mode="multiple"
          >
            <></>
            <>
              {storages.map((conn: any) => (
                <Option value={conn.id} key={conn.id}>
                  {conn.storage_name}
                </Option>
              ))}
            </>
          </AppInput>

          <p>Выберите сущности для установления связей между ними:</p>

          {storageEntities.length > 0 ? (
            <div>
              {storageEntities.map(
                ({ id, storage_name, entities }: any, index: number) => (
                  <div className={s.entityWrapper} key={`${id}_${index}`}>
                    <div className={s.entityTitle}>
                      <AppSchemeIcon />
                      Источник {storage_name}
                    </div>
                    <AppInput
                      control={control}
                      showArrow={true}
                      showSearch={true}
                      filterOption={handleFilterSearchStoragses}
                      rules={{ required: "Заполните это поле" }}
                      errors={errors}
                      name={`entities.${STORAGE_ID_PREFIX}_${id}`}
                      placeholder={"Выберите сущности для связи"}
                      mode="multiple"
                    >
                      <></>
                      <>
                        {entities.map((entity: any) => (
                          <Option value={entity.id} key={entity.id}>
                            {entity.entity_name}
                          </Option>
                        ))}
                      </>
                    </AppInput>
                  </div>
                )
              )}
            </div>
          ) : null}
        </div>

        <StepFooter disabledNext={!isValid} />
      </form>
    </StepLayout>
  );
};
