import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { fetchStorageById } from "../../api/storages/storages-acitons.api";
import { IStorageType } from "../types/storages.types";

export const storagesAdapter = createEntityAdapter<IStorageType>({
  selectId: (storage) => storage.id,
});

const storagesSlice = createSlice({
  name: "storages",
  initialState: storagesAdapter.getInitialState(),
  reducers: {
    setStorages: storagesAdapter.upsertMany,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStorageById.fulfilled, (state, action) => {
      storagesAdapter.upsertMany(state, action.payload.storage);
    });
  },
});

export const { setStorages } = storagesSlice.actions;

export const storagesReducer = storagesSlice.reducer;
