import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Pagination, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams, useParams } from "react-router-dom";

import { IntroContext } from "../../../providers/intro-provider/intro.provider";
import { AppOriginTab } from "./app-origin-tab/app-origin-tab.component";
import { storageEntitiesSelectors } from "../../../store/storages/storage-entities.selector";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { intersection } from "../../../helpers/intersection.helper";
import {
  fetchStorageAttributes,
  fetchStorageEntities,
  fetchStorageEntitiesSearch,
} from "../../../api/storages/storages-acitons.api";
import { IStorageEntriesType } from "../../../store/types/storages.types";
import {
  getEntitiesCount,
  getEntitiesData,
} from "../../../store/storages/storage-entities.selector";
import { AppLogoLoader } from "../../../components/ui/app-animated-logo/app-animated-logo.component";
import { EEntitySelectedStateType } from "../../../store/types/storages.types";
import { removeAttributes } from "../../../store/storages/storage-entity-attributes.slice";

import s from "./app-origin-tabs.module.scss";

const { Search } = Input;

const FIRST_PAGE = 1;

type PropTypes = {
  modifiedAttributeIds: number[];
  activateLoader: (value: boolean) => void;
  changeTab: () => void;
  setIsWholeEntitySelected: (EEntitySelectedStateType) => void;
};

export const AppOriginTabs: React.FC<PropTypes> = ({
  modifiedAttributeIds,
  activateLoader,
  changeTab,
  setIsWholeEntitySelected,
}) => {
  const { autoComplete } = useContext(IntroContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [query] = useSearchParams();
  const { t } = useTranslation();

  const entityId = Number(query.get("tab"));
  const storageId = Number(params.id);

  const storageEntities = useAppSelector((state) => getEntitiesData(state));
  const entititesCount = useAppSelector((state) => getEntitiesCount(state));

  const storageEntitiesTotal = useAppSelector(
    storageEntitiesSelectors.selectTotal
  );

  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(true);
  const [pagination, setPagination] = useState({
    current: Number(localStorage.getItem("entitiesPaginationPage")) || 1,
    pageSize: 50,
  });
  const tabParent = useRef<HTMLInputElement>(null);
  const entities = Object.values(storageEntities) as IStorageEntriesType[];

  useEffect(() => {
    setLoading(true);
    if (search) {
      setLoading(true);
      dispatch(
        fetchStorageEntitiesSearch({
          storageId,
          search,
          offset: pagination.current - 1,
        })
      )
        .unwrap()
        .then(() => {
          setLoading(false);
          setIsSearchDisabled(true);
        });
    } else {
      dispatch(
        fetchStorageEntities({
          storageId,
          offset: pagination.current - 1,
        })
      )
        .unwrap()
        .then(() => {
          setLoading(false);
        });
    }
  }, [pagination.current]);

  const handlePaginationChange = (page, pageSize) => {
    setPagination({ ...pagination, current: page });
    localStorage.setItem("entitiesPaginationPage", page);
    dispatch(removeAttributes());
  };

  const onSetQuery = (id: number) => {
    navigate({ search: `?tab=${id}&page=${FIRST_PAGE}` });
    activateLoader(true);
    changeTab();
    dispatch(fetchStorageAttributes({ storageId, entityId: id }))
      .unwrap()
      .then((data) => {
        setIsWholeEntitySelected(data.results.entity_selected_state);
        activateLoader(false);
      });
  };

  const toScroll = useCallback(
    (offsetY?: number) => {
      if (tabParent && offsetY) {
        const tabContainerHeight = tabParent.current!.offsetHeight;
        setTimeout(() => {
          tabParent.current?.scrollTo(0, offsetY - tabContainerHeight);
        }, 100);
      }
    },
    [tabParent]
  );

  const checkUnsavedAttributes = (tab: IStorageEntriesType): boolean => {
    return (
      tab?.attributes &&
      intersection(tab?.attributes as any, modifiedAttributeIds).length > 0
    );
  };
  const onSearch = () => {
    setLoading(true);
    dispatch(
      fetchStorageEntitiesSearch({
        storageId,
        search,
        offset: 0,
      })
    )
      .unwrap()
      .then(() => {
        setLoading(false);
        setIsSearchDisabled(true);
      });
  };

  const onChangeSearch = (e: FormEvent<HTMLInputElement>) => {
    setIsSearchDisabled(false);
    const value = e.currentTarget.value;
    setSearch(value);
  };

  return (
    <div className={s.originTabsWrapper}>
      <div className={"searchInputStorage"}>
        <Search
          defaultValue={search}
          loading={loading}
          size="large"
          placeholder={
            t("source.detail.card.placeholder.entity_search") as string
          }
          onChange={onChangeSearch}
          onSearch={onSearch}
          enterButton={
            <Button type="primary" disabled={isSearchDisabled}>
              <SearchOutlined />
            </Button>
          }
        />
      </div>
      {storageEntitiesTotal ? (
        <div className={s.originTabList}>
          {loading ? (
            <AppLogoLoader loading={true} />
          ) : (
            <div className={s.originTabs} id={"entity-list"} ref={tabParent}>
              {entities?.map((tab: any, i: number) => {
                return (
                  <AppOriginTab
                    toScroll={toScroll}
                    key={i}
                    onSetActive={onSetQuery}
                    tabId={tab?.id}
                    selectedId={Number(entityId)}
                    isSync={tab?.entity_is_selected}
                    isUnsaved={checkUnsavedAttributes(tab)}
                    name={tab?.entity_name}
                    schemaName={tab?.entity_schema}
                  />
                );
              })}
              <Pagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={entititesCount}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                style={{ textAlign: "center" }}
                size={"small"}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={s.emptyTabs}>
          {t("source.detail.card.flow.no_entities")}
        </div>
      )}
    </div>
  );
};
