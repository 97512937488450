import { memo, useContext } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { ProcessModeContext } from "../../../../providers/process-mode-provider/processMode.provider";
import { IEntityType } from "../../../../store/types/low-code.types";

import s from "./low-code-merge-modal.module.scss";

const { Option } = Select;

type PropTypes = {
  leftEntity: IEntityType;
  leftAttributeId: string | null;
  onChange: (value: string) => void;
};

export default memo(function ({
  leftEntity,
  leftAttributeId,
  onChange,
}: PropTypes) {
  const { t } = useTranslation();
  const mode = useContext(ProcessModeContext);

  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <div className={s.fromSelect}>
      <div className={s.entityName} data-testid="left-entity-name">
        {leftEntity.entity_name}.
      </div>
      <Select
        data-testid="left-attribute"
        className={cn({
          "select-from-entity": true,
          "select-from-entity-collapsed": !leftAttributeId,
        })}
        placeholder={t("page.process.form.step.lowcode.merge.select_attr")}
        onChange={handleChange}
        value={leftAttributeId}
        disabled={mode === "view"}
      >
        {leftEntity.attributes.map(({ id, attribute_name, attribute_type }) => (
          <Option
            key={id}
            value={String(id)}
            label={attribute_name}
            className="select-from-entity-option"
          >
            <div
              className="select-from-entity-option-item"
              data-testid={`attr-name-${id}`}
            >
              {attribute_name}
            </div>
            <div>
              <span className="select-from-entity-option-label">
                {t("page.process.form.step.lowcode.merge.type")}
              </span>
              <span
                className="select-from-entity-option-type"
                data-testid={`attr-type-${id}`}
              >
                {attribute_type}
              </span>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
});
