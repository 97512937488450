import { format } from "date-fns";

export const renderDate = (date: string, formatData: string) => {
  return format(new Date(date), formatData);
};

export const getTimeZone = () => {
  const offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  const result =
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    "." +
    ("00" + (o % 60)).slice(-2);
  if (result === "+3:30") {
    return parseFloat("3.5");
  }
  if (result === "-3:45") {
    return parseFloat("-3.75");
  }
  return parseFloat(result);
};
