import { SVGAttributes } from "react";

export const AppSmallCrossIcon: React.FC<
  {
    width?: string;
    height?: string;
    sharedStyles?: string;
  } & SVGAttributes<any>
> = ({ width = "8", height = "8", sharedStyles, ...props }) => (
  <svg
    width={width}
    height={height}
    className={sharedStyles}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66683 6.66732L1.3335 1.33398M6.66683 1.33398L1.3335 6.66732"
      stroke="#979797"
      strokeLinecap="round"
    />
  </svg>
);
