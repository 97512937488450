import React from "react";

type AppDeleteIconProps = {
  width?: string;
  height?: string;
  fill?: string;
};

export const AppDeleteIcon: React.FC<AppDeleteIconProps> = ({
  width = "24",
  height = "24",
  fill = "var(--app-icon-color, #737373)",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4375 4.71127H8.25C8.35313 4.71127 8.4375 4.6257 8.4375 4.52113V4.71127H15.5625V4.52113C15.5625 4.6257 15.6469 4.71127 15.75 4.71127H15.5625V6.42253H17.25V4.52113C17.25 3.68213 16.5773 3 15.75 3H8.25C7.42266 3 6.75 3.68213 6.75 4.52113V6.42253H8.4375V4.71127ZM20.25 6.42253H3.75C3.33516 6.42253 3 6.76241 3 7.1831V7.94366C3 8.04824 3.08437 8.1338 3.1875 8.1338H4.60312L5.18203 19.5502C5.21953 20.3607 5.88047 21 6.67969 21H17.3203C18.1219 21 18.7805 20.363 18.818 19.5502L19.3969 8.1338H20.8125C20.9156 8.1338 21 8.04824 21 7.94366V7.1831C21 6.76241 20.6648 6.42253 20.25 6.42253ZM17.1398 19.2887H6.86016L6.29297 8.1338H17.707L17.1398 19.2887Z"
        fill={fill}
      />
    </svg>
  );
};
