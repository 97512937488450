import { useEffect, useState } from "react";

import {
  FilterFormAttribute,
  FilterFormItemOperatorKey,
  FilterFormItemOperatorValue,
} from ".";
import {
  AttributeType,
  FilterItem,
  IEntityType,
} from "../../../../store/types/low-code.types";

import s from "./low-code-filter-modal.module.scss";

type PropsType = {
  entity: IEntityType | null;
  filter: FilterItem;
  onChange: (filter: Omit<FilterItem, "index" | "order" | "type">) => void;
};

export const FilterFormItem: React.FC<PropsType> = ({
  entity,
  filter,
  onChange,
}) => {
  const [operatorKey, setOperatorKey] = useState<string | null>(
    filter.condition ?? null
  );
  const [operatorValue, setOperatorValue] = useState<string | string[] | null>(
    filter.value ?? null
  );
  const [attribute, setAttribute] = useState<AttributeType | null>(
    filter.attribute ?? null
  );

  const onAttributeChange = (attr: AttributeType | undefined) => {
    setAttribute(attr ?? null);
  };

  useEffect(() => {
    if (!operatorKey || !attribute) {
      onChange({ isValid: false });
      return;
    }

    const emptyValue =
      operatorKey === "is_empty" ||
      operatorKey === "is_not_empty" ||
      operatorKey === "is_true" ||
      operatorKey === "is_false";

    if (!emptyValue && !operatorValue) {
      onChange({ isValid: false });
      return;
    }

    onChange({
      attribute,
      condition: operatorKey,
      value: emptyValue ? null : operatorValue,
      isValid: true,
    });
  }, [operatorKey, operatorValue, attribute]);

  return (
    <div className={s.filterItemSettings}>
      <FilterFormAttribute
        entity={entity}
        attribute={attribute}
        onChange={onAttributeChange}
      />
      <FilterFormItemOperatorKey
        operatorKey={operatorKey}
        onChange={setOperatorKey}
      />
      <FilterFormItemOperatorValue
        operatorKey={operatorKey}
        operatorValue={operatorValue}
        onChange={setOperatorValue}
      />
    </div>
  );
};
