import { useState, useEffect, useCallback, FormEvent, memo } from "react";
import { Dropdown, Menu, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import cn from "classnames";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { storageEntitiesSelectors } from "../../../../store/storages/storage-entities.selector";
import { selectStorage } from "../../../../store/storages/storage.selector";
import { ESnackbarState } from "../../../../store/types/snackbar.types";
import { fetchStorageAttributesSearch } from "../../../../api/storages/storages-acitons.api";
import {
  AppButton,
  EButtonSize,
} from "../../../app-button/app-button.component";
import { AppArrowIcon } from "../../../icons/app-arrow.icon";
import { AppImportIcon } from "../../../icons/app-import.icon";

import s from "../app-origin-table.module.scss";

const { Search } = Input;

type PropTypes = {
  storageId: number;
  entityId: number;
  fileFormat: string;
  isLoader: boolean;
  onShowUploadCsvModal: () => void;
  activateLoader: (boolean) => void;
  changeSearch: (string) => void;
  search: string;
};

export const OriginTableSearch: React.FC<PropTypes> = memo(
  ({
    storageId,
    entityId,
    fileFormat,
    onShowUploadCsvModal,
    activateLoader,
    isLoader,
    changeSearch,
    search,
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const [disabledUploadFileBtn, setDisabledUploadFileBtn] = useState(true);
    const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(true);

    const { t } = useTranslation();

    const entity = useAppSelector((state) =>
      storageEntitiesSelectors.selectById(state, entityId)
    );
    const { currentStorage } = useAppSelector(selectStorage);
    const { storage_import_status, storage_import_type } = currentStorage;

    const displayFileUploadBtn = () => {
      if (
        (storage_import_status === "not_started" && !storage_import_type) ||
        (storage_import_status === "stopped" &&
          storage_import_type === "one_time")
      ) {
        setDisabledUploadFileBtn(false);
      } else {
        setDisabledUploadFileBtn(true);
      }
    };

    useEffect(() => {
      const { id } = currentStorage;
      if (id === storageId) {
        displayFileUploadBtn();
      }
    }, [storage_import_status, storage_import_type]);

    const getMenuItems = useCallback(() => {
      return [
        {
          key: "1",
          disabled: disabledUploadFileBtn,
          label: (
            <span
              className={cn(s.importFileAction, {
                [s.actionDisabled]:
                  !entity?.entity_is_selected || disabledUploadFileBtn,
              })}
              onClick={() => {
                if (disabledUploadFileBtn) return;
                if (!entity?.entity_is_selected) {
                  enqueueSnackbar(
                    t("source.detail.card.table.head.sync.choice"),
                    {
                      variant: ESnackbarState.ERROR,
                    }
                  );
                  return;
                }
                onShowUploadCsvModal();
              }}
            >
              <AppImportIcon width={"20"} height={"20"} />
              {t("source.detail.card.actions.actions_button.upload", {
                fileFormat,
              })}
            </span>
          ),
        },
      ];
    }, [fileFormat, entity?.entity_is_selected, disabledUploadFileBtn]);

    const renderButton = () => {
      return (
        <Dropdown
          overlay={() => <Menu items={getMenuItems()} />}
          placement="bottomRight"
        >
          <div className={s.searchButton} id={"actions-button"}>
            <AppButton
              isOutline
              buttonSize={EButtonSize.HUG}
              sharedStyles={s.inputButton}
            >
              <>
                {t("source.detail.card.actions.actions_button")}
                <AppArrowIcon side={"down"} />
              </>
            </AppButton>
          </div>
        </Dropdown>
      );
    };

    const onSearch = () => {
      activateLoader(true);
      dispatch(
        fetchStorageAttributesSearch({
          storageId,
          entityId,
          search,
          offset: 0,
        })
      )
        .unwrap()
        .then(() => {
          activateLoader(false);
          setIsSearchDisabled(true);
        });
    };

    const onChangeSearch = (e: FormEvent<HTMLInputElement>) => {
      setIsSearchDisabled(false);
      const value = e.currentTarget.value;
      changeSearch(value);
    };

    return (
      <div className={s.originTableSearch}>
        <div className={"searchInputStorageAttr"}>
          <Search
            defaultValue={search}
            loading={isLoader}
            size="large"
            placeholder={
              t("source.detail.card.placeholder.attribute_search") as string
            }
            onChange={onChangeSearch}
            onSearch={onSearch}
            enterButton={
              <Button type="primary" disabled={isSearchDisabled}>
                <SearchOutlined />
              </Button>
            }
          />
        </div>
        {renderButton()}
      </div>
    );
  }
);
