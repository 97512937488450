import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  fetchProcessById,
  fetchViewProcesses,
} from "../../api/processes/processes-actions.api";
import { AppContent } from "../../components/app-main.layout";
import { AppArrowIcon } from "../../components/icons/app-arrow.icon";
import { AppLogoLoader } from "../../components/ui/app-animated-logo/app-animated-logo.component";
import { useAppDispatch } from "../../store/hooks";

import s from "./process-result.module.scss";
import { useTranslation } from "react-i18next";
import { IDomainProcess } from "../../store/types/processes.types";

export const ProcessResultPage = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [process, setProcess] = useState<IDomainProcess | null>(null);
  const [dataSupeset, setDataSupeset] = useState<any>(null);
  const [error, setError] = useState(false);

  const processId = Number(params.id);
  const SUPERSET_HOST =
    (window as any).config?.SUPERSET_URL || "http://172.10.0.18:8088";

  useEffect(() => {
    Promise.all([
      dispatch(fetchProcessById(processId)).unwrap(),
      dispatch(fetchViewProcesses(processId)).unwrap(),
    ])
      .then(([responseProcess, responseSuperset]) => {
        setProcess(responseProcess.data);
        setDataSupeset(responseSuperset.data);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  return (
    <>
      <AppContent isFull>
        <div className={s.header}>
          <div className={s.actionBlock}>
            <NavLink to={"/processes"} className={s.title}>
              <AppArrowIcon side={"left"} />
              <span>{t("page.process.result.superset.title")}</span>
              {" - "}
              <span>{process?.process_name}</span>
            </NavLink>
          </div>
        </div>

        {!dataSupeset && !error ? <AppLogoLoader loading={true} /> : null}

        {error ? (
          <div className={s.error}>
            {t("page.process.result.superset.error.title")}
          </div>
        ) : null}

        {dataSupeset ? (
          <div className={s.container}>
            <iframe
              src={`${SUPERSET_HOST}/superset/slice/${dataSupeset.chart_id}/?standalone=1`}
              seamless
            />
          </div>
        ) : null}
      </AppContent>
    </>
  );
};
