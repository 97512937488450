import React from "react";

export const AppInfoIcon: React.FC<{
  width?: string;
  height?: string;
  sharedStyles?: string;
  fill?: string;
}> = ({ width = "24", height = "24", sharedStyles, fill }) => {
  return (
    <svg
      className={sharedStyles}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02991 7.02991 3 12 3C16.9701 3 21 7.02991 21 12C21 16.9701 16.9701 21 12 21C7.02991 21 3 16.9701 3 12ZM4.52679 12C4.52679 16.1263 7.87366 19.4732 12 19.4732C16.1263 19.4732 19.4732 16.1263 19.4732 12C19.4732 7.87366 16.1263 4.52679 12 4.52679C7.87366 4.52679 4.52679 7.87366 4.52679 12ZM11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8ZM11 11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11Z"
        fill={fill ? fill : "var(--app-icon-color, #737373)"}
      />
    </svg>
  );
};
