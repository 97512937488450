import React from "react";

type AppIconProps = {
  width?: string;
  height?: string;
};

export const AppEnIcon: React.FC<AppIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2014_12342)">
        <path
          d="M20 17V6H7.36842C7.09895 6 6.85979 6.03808 6.85979 6.03808L6.85642 7.52223L4.55748 7.51885C4.55748 7.51885 4.52211 7.56877 4.45053 7.69231C4.15471 8.20672 3.9993 8.7905 4 9.38462V17H20Z"
          fill="#00247D"
        />
        <path
          d="M20 6H18.037L14.1053 8.76608V6H9.89473V8.17377L6.85979 6.03808C6.35356 6.11406 5.87167 6.30664 5.45179 6.60077L9.41137 9.38462H7.94947L4.81432 7.177C4.72186 7.28559 4.63608 7.39975 4.55747 7.51885L7.21095 9.38462H4V13.6154H7.27158L4 15.9558V17H6.80589L9.89473 14.8271V17H14.1053V14.2339L18.0362 17H20V15.2832L17.6295 13.6154H20V9.38462H17.6303L20 7.71685V6Z"
          fill="#EEEEEE"
        />
        <path
          d="M13.2632 6H10.7368V10.2308H4V12.7692H10.7368V17H13.2632V12.7692H20V10.2308H13.2632V6Z"
          fill="#CF1B2B"
        />
        <path
          d="M20.0001 6H18.7697L14.1053 9.28223V9.38462H15.4283L20.0001 6.16754V6ZM5.45185 6.60077C5.21609 6.76515 5.00191 6.95875 4.81437 7.177L7.94953 9.38462H9.41058L5.45185 6.60077ZM9.42069 13.6154L4.61816 17H6.07248L9.89479 14.3109V13.6154H9.42069ZM20.0001 16.8325V15.8002L16.8952 13.6154H15.4274L20.0001 16.8325Z"
          fill="#CF1B2B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2014_12342">
          <rect x="4" y="6" width="16" height="11" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
