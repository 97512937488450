import React, { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

const APP_ICON_COLOR = "var(--app-icon-color, #8A8A8A)";

export const AppLeftJoinIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9999 4.5C13.9678 4.49995 12.947 4.71449 12.0022 5.13C12.6059 5.39436 13.1721 5.73733 13.6859 6.15C14.5646 5.95002 15.4769 5.95051 16.3553 6.15141C17.2337 6.35232 18.0556 6.74849 18.7599 7.31052C19.4642 7.87255 20.0329 8.58599 20.4237 9.39789C20.8146 10.2098 21.0175 11.0993 21.0175 12.0004C21.0175 12.9015 20.8146 13.791 20.4237 14.6029C20.0329 15.4148 19.4642 16.1282 18.7599 16.6902C18.0556 17.2523 17.2337 17.6484 16.3553 17.8493C15.4769 18.0502 14.5646 18.0507 13.6859 17.8507C13.1721 18.2634 12.6059 18.6064 12.0022 18.8707C13.0239 19.3168 14.1322 19.5293 15.2465 19.493C16.3607 19.4567 17.4528 19.1724 18.4433 18.6608C19.4338 18.1492 20.2978 17.4231 20.9723 16.5355C21.6469 15.6479 22.115 14.6211 22.3426 13.5297C22.5703 12.4384 22.5517 11.31 22.2883 10.2268C22.0249 9.14351 21.5233 8.13261 20.8199 7.26766C20.1166 6.40271 19.2292 5.70547 18.2224 5.2267C17.2156 4.74793 16.1148 4.49967 14.9999 4.5Z"
      fill={APP_ICON_COLOR}
    />
    <path
      d="M9 12C9.00235 13.3619 9.46735 14.6825 10.3188 15.7454C11.1702 16.8083 12.3575 17.5504 13.686 17.85C14.5637 17.1484 15.2722 16.2582 15.7592 15.2456C16.2461 14.2329 16.499 13.1237 16.499 12C16.499 10.8763 16.2461 9.76708 15.7592 8.75441C15.2722 7.74174 14.5637 6.85162 13.686 6.14999C12.3575 6.44957 11.1702 7.19165 10.3188 8.25456C9.46735 9.31747 9.00235 10.6381 9 12Z"
      fill={APP_ICON_COLOR}
    />
    <path
      d="M7.5 12C7.50189 10.5413 7.9287 9.11473 8.72827 7.89467C9.52783 6.67462 10.6655 5.71392 12.0022 5.13C10.8608 4.63008 9.61274 4.42245 8.3709 4.52588C7.12906 4.62931 5.93256 5.04054 4.88952 5.72241C3.84649 6.40428 2.98979 7.3353 2.39685 8.43133C1.80391 9.52736 1.49341 10.7539 1.49341 12C1.49341 13.2461 1.80391 14.4726 2.39685 15.5687C2.98979 16.6647 3.84649 17.5957 4.88952 18.2776C5.93256 18.9595 7.12906 19.3707 8.3709 19.4741C9.61274 19.5776 10.8608 19.3699 12.0022 18.87C10.6655 18.2861 9.52783 17.3254 8.72827 16.1053C7.9287 14.8853 7.50189 13.4587 7.5 12Z"
      fill={APP_ICON_COLOR}
    />
  </svg>
);
