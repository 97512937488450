import React, { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

const APP_ICON_COLOR = "var(--app-icon-color, #8A8A8A)";

export const AppRightJoinIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 4.5C13.9665 4.49982 12.9441 4.71436 11.9978 5.13C13.3357 5.71232 14.4745 6.67263 15.2743 7.89304C16.0741 9.11345 16.5002 10.5408 16.5002 12C16.5002 13.4592 16.0741 14.8866 15.2743 16.107C14.4745 17.3274 13.3357 18.2877 11.9978 18.87C13.0195 19.3169 14.1281 19.5303 15.2427 19.4947C16.3574 19.459 17.45 19.1752 18.4411 18.664C19.4322 18.1527 20.2968 17.4268 20.9719 16.5392C21.6471 15.6516 22.1157 14.6245 22.3438 13.5329C22.5719 12.4413 22.5536 11.3125 22.2903 10.2288C22.0271 9.14516 21.5254 8.13384 20.8219 7.26853C20.1184 6.40322 19.2308 5.7057 18.2236 5.22677C17.2165 4.74784 16.1153 4.49955 15.0001 4.5Z"
      fill={APP_ICON_COLOR}
    />
    <path
      d="M7.5 12C7.49958 13.1238 7.75234 14.2333 8.2395 15.2461C8.72667 16.2588 9.43572 17.1488 10.314 17.85C11.6429 17.5518 12.8307 16.8099 13.6818 15.7467C14.5329 14.6834 14.9967 13.362 14.9967 12C14.9967 10.638 14.5329 9.31662 13.6818 8.25333C12.8307 7.19004 11.6429 6.44823 10.314 6.14999C9.43572 6.85115 8.72667 7.74115 8.2395 8.75391C7.75234 9.76667 7.49958 10.8762 7.5 12Z"
      fill={APP_ICON_COLOR}
    />
    <path
      d="M8.99998 18C8.15465 18.0027 7.31829 17.8267 6.54572 17.4836C5.77314 17.1406 5.08178 16.6381 4.51693 16.0092C3.95209 15.3802 3.52652 14.6391 3.26811 13.8342C3.00969 13.0293 2.92427 12.179 3.01743 11.3388C3.1106 10.4986 3.38025 9.68757 3.80871 8.95887C4.23718 8.23017 4.81479 7.60023 5.50369 7.11034C6.19259 6.62044 6.97724 6.28164 7.80621 6.11614C8.63519 5.95065 9.48978 5.96218 10.314 6.15C10.8279 5.73733 11.394 5.39436 11.9977 5.13C10.8563 4.63008 9.60822 4.42245 8.36638 4.52588C7.12454 4.62931 5.92804 5.04054 4.88501 5.72241C3.84197 6.40428 2.98528 7.3353 2.39233 8.43133C1.79939 9.52736 1.48889 10.7539 1.48889 12C1.48889 13.2461 1.79939 14.4726 2.39233 15.5687C2.98528 16.6647 3.84197 17.5957 4.88501 18.2776C5.92804 18.9595 7.12454 19.3707 8.36638 19.4741C9.60822 19.5776 10.8563 19.3699 11.9977 18.87C11.394 18.6056 10.8279 18.2627 10.314 17.85C9.88287 17.9484 9.44218 17.9987 8.99998 18Z"
      fill={APP_ICON_COLOR}
    />
  </svg>
);
