import React, { SVGAttributes } from "react";
import cn from "classnames";

interface IAppArrowIconType {
  side?: "right" | "down" | "left" | "top";
  width?: string;
  height?: string;
  sharedStyles?: string;
  type?: "fill" | "outline";
}

export const AppArrowIcon: React.FC<IAppArrowIconType & SVGAttributes<any>> = ({
  side = "right",
  width = "20",
  height = "20",
  sharedStyles,
  type = "outline",
  ...props
}) => {
  const showSide = () => {
    switch (side) {
      case "right":
        return 0;
      case "down":
        return 90;
      case "left":
        return 180;
      case "top":
        return -90;
      default:
        return 0;
    }
  };

  if (type === "fill") {
    return (
      <svg
        width={width}
        height={height}
        style={{ transform: `rotate(${showSide()}deg)` }}
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.00003 6.33337H7.00003C7.06078 6.33318 7.12032 6.31642 7.17225 6.28491C7.22419 6.25339 7.26654 6.20831 7.29476 6.15452C7.32298 6.10072 7.33599 6.04025 7.3324 5.97961C7.3288 5.91897 7.30874 5.86045 7.27436 5.81037L4.27436 1.47704C4.15003 1.29737 3.8507 1.29737 3.72603 1.47704L0.726028 5.81037C0.691303 5.86035 0.67094 5.91889 0.66715 5.97963C0.663361 6.04038 0.67629 6.10099 0.704533 6.1549C0.732776 6.20881 0.775254 6.25395 0.82735 6.28542C0.879446 6.31688 0.939168 6.33346 1.00003 6.33337Z"
          fill="var(--app-icon-color, #737373)"
          {...props}
        />
      </svg>
    );
  }

  return (
    <svg
      className={cn(sharedStyles)}
      style={{ transform: `rotate(${showSide()}deg)` }}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70711 2.29289C6.31658 1.90237 5.68342 1.90237 5.29289 2.29289C4.90237 2.68342 4.90237 3.31658 5.29289 3.70711L11.5858 10L5.29289 16.2929C4.90237 16.6834 4.90237 17.3166 5.29289 17.7071C5.68342 18.0976 6.31658 18.0976 6.70711 17.7071L13.7071 10.7071C13.9512 10.463 14.0427 10.1242 13.9817 9.80901C13.9451 9.61991 13.8536 9.43934 13.7071 9.29289C13.707 9.29277 13.7069 9.29265 13.7067 9.29253L6.70711 2.29289Z"
        fill="var(--app-icon-color, #737373)"
        {...props}
      />
    </svg>
  );
};
