import { connectionReducer } from "./connections/connections.slice";
import { authReducer } from "./auth/auth.slice";
import { snackbarReducer } from "./snackbar/snackbar.slice";
import { attributeRulesReducer } from "./storages/attribute-rules.slice";

import {
  Action,
  configureStore,
  combineReducers,
  ThunkAction,
} from "@reduxjs/toolkit";
import type { PreloadedState } from "@reduxjs/toolkit";
import { processesReducer } from "./processes/process-list/processes.slice";
import { lowCodeReducer } from "./low-code/low-code.slice";
import { notificationReducer } from "./notification/notification.slice";
import { wsApi } from "./notification/ws.query";
import { customizedMiddleware } from "./middlewares/searialize.middleware";
import { storageEntitiesReducer } from "./storages/storage-entities.slice";
import { storageEntityAttributesReducer } from "./storages/storage-entity-attributes.slice";
import { storagesReducer } from "./storages/storages.slice";
import { storageReducer } from "./storages/storage.slice";
import { processesCreateReducer } from "./processes/process-create/process-create.slice";
import { processDetailReducer } from "./processes/process-detail/process-detail.slice";
import networkMiddleware from "./middlewares/online.middleware";
import { networkReducer } from "./network/network.slice";

const rootReducer = combineReducers({
  connections: connectionReducer,
  storages: storagesReducer,
  currentStorage: storageReducer,
  storageEntities: storageEntitiesReducer,
  storageEntityAttributes: storageEntityAttributesReducer,
  authData: authReducer,
  snackbar: snackbarReducer,
  attributeRules: attributeRulesReducer,
  processes: processesReducer,
  processesCreate: processesCreateReducer,
  processeDetail: processDetailReducer,
  notification: notificationReducer,
  network: networkReducer,
  lowCode: lowCodeReducer,
  [wsApi.reducerPath]: wsApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: customizedMiddleware.concat(
      networkMiddleware,
      wsApi.middleware
    ),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
