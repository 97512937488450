import { createSlice } from "@reduxjs/toolkit";
import { initialProcessData } from "../../../pages/create-process/create-process.page";

export type CreateProcessType = {
  createdProcess: typeof initialProcessData;
  touched: boolean;
  joinsData: any[];
};

const baseProcessInitialState: CreateProcessType = {
  createdProcess: {
    process_name: "",
    process_description: "",
    process_scheduler: {
      minute: null,
      hour: null,
      week: null,
      month: null,
      timezone_offset: null,
    },
    interval: "perhour" as any,
    onSchedule: "default",
    process_is_overwriting: true,
    entities: [],
    process_sql: "",
  },
  touched: false,
  joinsData: [
    {
      id: 1,
      attributes: [],
      title: undefined,
    },
  ],
};

const initialState: CreateProcessType = { ...baseProcessInitialState };

const processesCreateSlice = createSlice({
  name: "processCreate",
  initialState,
  reducers: {
    setProcess: (state, { payload }) => {
      state.createdProcess = { ...state.createdProcess, ...payload };
      state.touched = true;
    },
    resetProcess: (state) => {
      state.createdProcess = { ...baseProcessInitialState.createdProcess };
      state.touched = false;
    },
    setTouched: (state, { payload }) => {
      state.touched = payload;
    },

    addJoin: (state, { payload }) => {
      state.joinsData = [...state.joinsData, payload];
    },

    resetJoins: (state) => {
      state.joinsData = baseProcessInitialState.joinsData;
    },

    removeJoinFromList: (state, { payload }) => {
      const filteredJoins = state.joinsData.filter(
        (item) => item.id !== payload
      );
      state.joinsData = filteredJoins;
    },

    addAttribute: (state, { payload }) => {
      state.joinsData = state.joinsData.map((item) =>
        item.id === payload.id
          ? {
              id: item.id,
              attributes: [...item.attributes, payload.attribute],
              title: item.title,
            }
          : item
      );
    },

    updateAttributes: (state, { payload }) => {
      state.joinsData = state.joinsData.map((item) =>
        item.id === payload.id
          ? {
              id: item.id,
              attributes: payload.attributes,
              title: item.title,
            }
          : item
      );
    },

    updateTitle: (state, { payload }) => {
      state.joinsData = state.joinsData.map((item) =>
        item.id === payload.id
          ? {
              id: item.id,
              attributes: item?.attributes,
              title: payload.title,
            }
          : item
      );
    },
  },
});

export const {
  setProcess,
  resetProcess,
  setTouched,
  addJoin,
  removeJoinFromList,
  updateAttributes,
  addAttribute,
  updateTitle,
  resetJoins,
} = processesCreateSlice.actions;
export const processesCreateReducer = processesCreateSlice.reducer;
