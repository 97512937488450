import s from "./app-truncate.module.scss";

import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import cn from "classnames";

type AppTruncatePropTypes = {
  height: number;
  content: ReactElement;
  children: string;
};

export const AppTruncateText: React.FC<AppTruncatePropTypes> = ({
  content,
  height,
  children,
}) => {
  const ref = useRef<any>(null);
  const [truncate, setTruncate] = useState(false);

  useEffect(() => {
    const textHeight = ref.current.clientHeight;
    if (textHeight > height) {
      setTruncate(true);
    } else {
      setTruncate(false);
    }
  }, [ref]);
  return (
    <>
      {truncate ? (
        <Tooltip title={children} color={"var(--white)"}>
          {React.cloneElement(content, {
            ref,
            className: cn(s.originRowTitle, {
              [s._truncate]: truncate,
            }),
          })}
        </Tooltip>
      ) : (
        <>
          {React.cloneElement(content, {
            ref,
          })}
        </>
      )}
    </>
  );
};
