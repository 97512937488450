import { RootState } from "../index";
import { storageEntityAttributesAdapter } from "./storage-entity-attributes.slice";

export const storageEntityAttributesSelectors =
  storageEntityAttributesAdapter.getSelectors<RootState>(
    (state) => state.storageEntityAttributes
  );

export const getAttributesCount = (state: RootState) =>
  state.storageEntityAttributes.count;
