import React from "react";

type AppPlusIconProps = {
  width?: string;
  height?: string;
  fill?: string;
};

export const AppPlusIcon: React.FC<AppPlusIconProps> = ({
  width = "24",
  height = "24",
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
    >
      <path
        d="M11.002 18L11.002 13L6.00195 13C5.73674 13 5.48238 12.8946 5.29484 12.7071C5.10731 12.5196 5.00195 12.2652 5.00195 12C5.00195 11.7348 5.10731 11.4804 5.29484 11.2929C5.48238 11.1054 5.73674 11 6.00195 11L11.002 11L11.002 6C11.002 5.73478 11.1073 5.48043 11.2948 5.29289C11.4824 5.10536 11.7367 5 12.002 5C12.2672 5 12.5215 5.10536 12.7091 5.29289C12.8966 5.48043 13.002 5.73478 13.002 6L13.002 11L18.002 11C18.2672 11 18.5215 11.1054 18.7091 11.2929C18.8966 11.4804 19.002 11.7348 19.002 12C19.002 12.2652 18.8966 12.5196 18.7091 12.7071C18.5215 12.8946 18.2672 13 18.002 13L13.002 13L13.002 18C13.002 18.2652 12.8966 18.5196 12.7091 18.7071C12.5215 18.8946 12.2672 19 12.002 19C11.7367 19 11.4824 18.8946 11.2948 18.7071C11.1073 18.5196 11.002 18.2652 11.002 18Z"
        fill="#737373"
      />
    </svg>
  );
};
