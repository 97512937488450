import { Select } from "antd";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { ArrowDownIcon } from "../../../../components/icons";

import {
  AttributeType,
  IEntityType,
} from "../../../../store/types/low-code.types";

import s from "./low-code-filter-modal.module.scss";

type PropsType = {
  entity: IEntityType | null;
  attribute: AttributeType | null;
  onChange: (attribute: AttributeType | undefined) => void;
};

export const FilterFormAttribute: React.FC<PropsType> = ({
  entity,
  attribute,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn("filter-form-add-entity", "filterSelect", s.rightJoin, {
        [s.rightJoinEmpty]: !attribute,
        [s.rightJoinFull]: !!attribute,
      })}
    >
      <Select
        optionLabelProp="children"
        className="select-from-entity"
        placeholder={t("page.process.form.step.lowcode.merge.select_attr")}
        onChange={(value: string) => {
          const attr = entity?.attributes.find(
            ({ id }) => String(id) === value
          );
          onChange(attr);
        }}
        value={attribute ? String(attribute.id) : null}
        suffixIcon={<ArrowDownIcon fill="#0057FF" />}
      >
        {entity?.attributes.map(({ id, attribute_name, attribute_type }) => (
          <Select.Option
            key={id}
            value={String(id)}
            label={attribute_name}
            className="select-from-entity-option"
          >
            <div>
              <div className="select-from-entity-option-item">
                {attribute_name}
              </div>
              <div>
                <span className="select-from-entity-option-type">
                  {t("page.process.form.step.lowcode.merge.type")}
                </span>
                <span className="select-from-entity-option-label">
                  {attribute_type}
                </span>
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
