import s from "./app-create-rule.module.scss";

import { AppInput } from "../../app-input/app-input.component";
import { AppButton } from "../../app-button/app-button.component";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IntroContext } from "../../../providers/intro-provider/intro.provider";
import { useForm, RegisterOptions } from "react-hook-form";
import React, { useContext } from "react";
import { ICreateRule } from "../../../store/types/attribute-rule.types";
import { addRule } from "../../../api/storages/storages-acitons.api";
import { selectAllAttributeRules } from "../../../store/storages/attribute-rules.selector";
import { useTranslation } from "react-i18next";

type AppCreateRuleFormPropTypes = {
  onCancel: () => void;
};

export const AppCreateRule: React.FC<AppCreateRuleFormPropTypes> = ({
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { autoComplete } = useContext(IntroContext);
  const { rules } = useAppSelector(selectAllAttributeRules);
  const { t } = useTranslation();
  const methods = useForm<ICreateRule>({
    mode: "onChange",
    defaultValues: {
      rule_template_name: "",
      rule_template_description: "",
      rule_template_content: "",
    },
  });

  const {
    handleSubmit,
    formState,
    control,
    formState: { errors },
  } = methods;

  const validateSymbols = (value: string): string | boolean => {
    const regex = new RegExp(/[^a-zA-ZЁёА-я0-9\s-]/g);
    return (
      !value.match(regex) ||
      (t("page.process.form.step.validation.spec_symbols") as string)
    );
  };

  const validateSameName = (value: string): string | boolean => {
    const ruleNames = rules.map((rule) =>
      rule.rule_template_name.toLowerCase()
    );

    return (
      !ruleNames.includes(value.toLowerCase()) ||
      (t("page.rule.action.add.validation.exist") as string)
    );
  };

  const nameValidation: RegisterOptions = {
    required: true,
    maxLength: {
      value: 30,
      message: t("page.rule.action.add.validation.name-100"),
    },
    validate: {
      validateSymbols,
      validateSameName,
    },
  };

  const descriptionValidation: RegisterOptions = {
    required: true,
    maxLength: {
      value: 300,
      message: t("page.rule.action.add.validation.description-300"),
    },
  };

  const ruleValidation: RegisterOptions = {
    required: true,
  };

  const submitForm = (data: ICreateRule) => {
    dispatch(addRule(data)).then(() => {
      onCancel();
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit((data) => submitForm(data))}>
        <h2 className={s.formTitles}>
          {t("page.rule.action.add.form.basic_details")}
        </h2>
        <AppInput
          rules={nameValidation}
          errors={errors}
          autoComplete={autoComplete}
          control={control}
          name={"rule_template_name"}
          placeholder={
            t("page.rule.action.add.form.placeholder.name") as string
          }
        />
        <AppInput
          sharedStyles={s.formTitles}
          rules={descriptionValidation}
          errors={errors}
          autoComplete={autoComplete}
          control={control}
          name={"rule_template_description"}
          placeholder={
            t("page.rule.action.add.form.placeholder.description") as string
          }
          isTextArea={true}
        />

        <h2 className={s.formTitles}>
          {t("page.rule.action.add.form.placeholder.rule")}
        </h2>

        <AppInput
          sharedStyles={s.formTitles}
          rules={ruleValidation}
          errors={errors}
          control={control}
          name={"rule_template_content"}
          placeholder={
            t("page.rule.action.add.form.placeholder.rule.info") as string
          }
          isTextArea={true}
        />

        <AppButton disabled={!formState.isValid} htmlType={"submit"}>
          {t("page.rule.action.add")}
        </AppButton>
      </form>
    </>
  );
};
