import React from "react";

export const AppDoneIcon: React.FC<{
  width?: string;
  height?: string;
  sharedStyles?: string;
}> = ({ width = "24", height = "24", sharedStyles }) => {
  return (
    <svg
      className={sharedStyles}
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.56834 15.9303L4.81496 12.1769C4.71681 12.0776 4.59992 11.9987 4.47106 11.9449C4.3422 11.891 4.20394 11.8633 4.06429 11.8633C3.92463 11.8633 3.78637 11.891 3.65751 11.9449C3.52865 11.9987 3.41176 12.0776 3.31361 12.1769C3.21426 12.2751 3.13539 12.392 3.08155 12.5208C3.02772 12.6497 3 12.788 3 12.9276C3 13.0673 3.02772 13.2055 3.08155 13.3344C3.13539 13.4632 3.21426 13.5801 3.31361 13.6783L7.80694 18.1716C8.22517 18.5898 8.90078 18.5898 9.31902 18.1716L20.6864 6.81496C20.7857 6.71681 20.8646 6.59992 20.9184 6.47106C20.9723 6.3422 21 6.20394 21 6.06429C21 5.92463 20.9723 5.78637 20.9184 5.65751C20.8646 5.52865 20.7857 5.41176 20.6864 5.31361C20.5882 5.21426 20.4713 5.13539 20.3425 5.08155C20.2136 5.02772 20.0754 5 19.9357 5C19.7961 5 19.6578 5.02772 19.5289 5.08155C19.4001 5.13539 19.2832 5.21426 19.185 5.31361L8.56834 15.9303Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
