import { useState, useContext } from "react";
import { Col, Row } from "antd";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";
import type { Node } from "reactflow";

import { ProcessModeContext } from "../../../../providers/process-mode-provider/processMode.provider";
import { FilterIcon } from "../../../../components/icons";
import { AppButton } from "../../../../components/app-button/app-button.component";
import {
  FilterGroup,
  IEntityType,
  TransformationIncomerType,
} from "../../../../store/types/low-code.types";
import { FilterFormGroup } from ".";
import { nameValidation } from "../../../../helpers/low-code.helper";

import s from "./low-code-filter-modal.module.scss";

const validate = (group: FilterGroup): boolean =>
  group.filters.every(({ isValid }) => isValid) &&
  group.groups.every(({ isValid }) => isValid);

type TransformationType = {
  entityName: string | null;
  name: string;
  settings: FilterGroup;
};

type PropsType = {
  incomers: TransformationIncomerType[];
  transformation: TransformationType;
  onSubmit: (data: TransformationType) => void;
  onCancel: () => void;
  transformNode: Node;
};

export const FilterForm: React.FC<PropsType> = ({
  incomers,
  onSubmit,
  onCancel,
  transformNode: node,
  transformation,
}) => {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<IEntityType>(incomers[0].entity);
  const [group, setGroup] = useState<FilterGroup>(initGroup);
  const mode = useContext(ProcessModeContext);

  function initGroup() {
    return transformation?.settings
      ? JSON.parse(JSON.stringify(transformation.settings))
      : addGroup();
  }

  function addGroup(): FilterGroup {
    return {
      index: nanoid(),
      order: 0,
      type: "group",
      filters: [{ index: nanoid(), order: 0, type: "filter", isValid: false }],
      groups: [],
      isValid: false,
      operator: "OR",
    };
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isValid: isValidForm },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name:
        transformation?.name ??
        `${t("page.process.form.step.lowcode.transformation.node.filter")} ${
          node.data.index
        }`,
      entityName: String(incomers[0].entity.entity_name),
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (group.isValid && isValidForm) {
          onSubmit({ ...data, settings: group });
          onCancel();
        }
      })}
    >
      <div className={s.header}>
        <Row gutter={16}>
          <Col span={12}>
            <div className={s.textInput}>
              <FilterIcon />
              <Controller
                name="name"
                control={control}
                rules={nameValidation(t)}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder={t(
                      "page.process.form.step.lowcode.filter.name_placeholder"
                    )}
                    {...field}
                  />
                )}
              />
            </div>
            <ErrorMessage
              key={"name"}
              name={"name"}
              errors={errors}
              render={({ message }) => (
                <span className={s.inputError}>{message}</span>
              )}
            />
          </Col>
          <Col span={12}>
            <div className={s.textInput}>
              <Controller
                control={control}
                name={"entityName"}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder={t(
                      "page.process.form.step.lowcode.merge.select_left_entity_placeholder"
                    )}
                    disabled={true}
                    {...field}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <div className={s.content}>
          <FilterFormGroup
            entity={entity}
            group={group}
            raiseGroup={(changedGroup) => {
              if (group.index === changedGroup.index) {
                group.isValid = validate(group);
                setGroup({ ...changedGroup });
              } else {
                const idx = group.groups.findIndex(
                  ({ index }) => index === changedGroup.index
                );
                group.groups.splice(idx, 1, changedGroup);
                group.isValid = validate(group);
                setGroup({ ...group });
              }
            }}
            removeGroup={(removedGroup) => {
              const idx = group.groups.findIndex(
                ({ index }) => index === removedGroup.index
              );
              group.groups.splice(idx, 1);
              group.isValid = validate(group);
              setGroup({ ...group });
            }}
            isFirst={true}
          />
        </div>
      </div>
      <div className={s.footer}>
        <div />
        <AppButton
          disabled={!group.isValid || !isValidForm || mode === "view"}
          htmlType="submit"
          sharedStyles={s.applyOperatorButton}
        >
          {t("page.process.form.step.lowcode.merge.submit")}
        </AppButton>
      </div>
    </form>
  );
};
