import type { Node, XYPosition, ReactFlowInstance } from "reactflow";
import { TFunction } from "i18next";
import { RegisterOptions } from "react-hook-form";
import { nameRegexp } from "../utils/utils";

export type RF = ReactFlowInstance & {
  mergeIndex: number;
  filterIndex: number;
  groupIndex: number;
  joinIndex: number;
};

export const createTransformNode = (
  position: XYPosition,
  nodeType:
    | "mergeTransformNode"
    | "joinTransformNode"
    | "filterTransformNode"
    | "groupTransformNode",
  rf: RF
): Node => {
  const type = nodeType.slice(0, nodeType.indexOf("TransformNode"));
  const typeIndex = type.concat("Index");

  const maxIndex = rf
    .getNodes()
    .filter(({ type }) => type === nodeType)
    .reduce(
      (prevIndex: number, cur: Node) =>
        cur.data?.index > prevIndex ? cur.data.index : prevIndex,
      rf[typeIndex]
    );

  const index = maxIndex + 1;
  rf[typeIndex] = index;

  return {
    id: type.concat(`_${index}`),
    type: nodeType,
    position,
    data: { index },
  };
};

const validateSymbols = (value: string, t: TFunction): string | boolean => {
  return (
    !value.match(nameRegexp) ||
    (t("page.process.form.step.validation.spec_symbols") as string)
  );
};

export const nameValidation = (t: TFunction): RegisterOptions => ({
  maxLength: {
    value: 50,
    message: t("page.process.form.step.validation.length.50") as string,
  },
  validate: {
    symbols: (v) => validateSymbols(v, t),
  },
});
