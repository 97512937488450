import s from "./app-button.module.scss";

import { Button, ButtonProps } from "antd";

import React, { ReactNode } from "react";
import cn from "classnames";

export type IButtonDropdownOptionsType = {
  path: string;
  name: string;
  icon?: ReactNode;
};

export enum EButtonSize {
  FILL = "fill",
  HUG = "hug",
}

interface IButtonTypes {
  options?: IButtonDropdownOptionsType[];
  onChangePath?: (path: string) => void;
  children: string | ReactNode;
  sharedStyles?: string;
  buttonSize?: EButtonSize;
  isSmall?: boolean;
  isOutline?: boolean;
  isFilled?: boolean;
  isDanger?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export const AppButton: React.FC<ButtonProps & IButtonTypes> = ({
  sharedStyles,
  buttonSize = EButtonSize.HUG,
  children,
  isSmall,
  isFilled,
  isOutline,
  isDanger,
  disabled,
  ...props
}) => {
  return (
    <>
      <Button
        className={cn(
          s.button,
          {
            [s._hug]: buttonSize === EButtonSize.HUG,
            [s._fill]: buttonSize === EButtonSize.FILL,
            [s._small]: isSmall,
            [s._outline]: isOutline,
            [s._filled]: isFilled,
            [s._danger]: !isOutline && isDanger,
            [s._outlineDanger]: isOutline && isDanger,
            [s._disabled]: disabled,
          },
          sharedStyles
        )}
        disabled={disabled}
        size={"large"}
        {...props}
      >
        {children}
      </Button>
    </>
  );
};
