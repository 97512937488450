import React, { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

export const AppMergeIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L12 4.41421L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.90237 7.31658 6.90237 6.68342 7.29289 6.29289L11.2929 2.29289Z"
      fill={"var(--app-icon-color)" || "black"}
      {...props}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V8.39393V8.394V8.39407C11.0001 9.33861 10.7669 10.2686 10.3213 11.1013C9.8756 11.9341 9.23121 12.644 8.4453 13.1679C7.38542 13.8745 6.51639 14.8319 5.91536 15.955C5.31435 17.0781 4.99992 18.3322 5 19.606V19.6061V21C5 21.5523 5.44772 22 6 22C6.55228 22 7 21.5523 7 21V19.606V19.6059C6.99993 18.6614 7.23308 17.7314 7.67874 16.8987C8.1244 16.0659 8.76879 15.356 9.5547 14.8321C10.5658 14.158 11.4033 13.2557 12 12.1989C12.5967 13.2557 13.4342 14.158 14.4453 14.8321C15.2312 15.356 15.8756 16.0659 16.3213 16.8987C16.7669 17.7314 17.0001 18.6614 17 19.6059V19.606V21C17 21.5523 17.4477 22 18 22C18.5523 22 19 21.5523 19 21V19.6061V19.606C19.0001 18.3322 18.6857 17.0781 18.0846 15.955C17.4836 14.8319 16.6146 13.8745 15.5547 13.1679C14.7688 12.644 14.1244 11.9341 13.6787 11.1013C13.2331 10.2686 12.9999 9.33861 13 8.39407C13 8.39404 13 8.39402 13 8.394V8.39393V3Z"
      fill={"var(--app-icon-color)" || "black"}
      {...props}
    />
  </svg>
);
