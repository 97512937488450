import { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

export const AppSaveIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.3 21C5.42479 21 4.58542 20.6523 3.96655 20.0335C3.34768 19.4146 3 18.5752 3 17.7V6.3C3 5.42479 3.34768 4.58542 3.96655 3.96655C4.58542 3.34768 5.42479 3 6.3 3H14.6568C15.1691 2.99987 15.6765 3.1007 16.1499 3.29672C16.6232 3.49273 17.0533 3.7801 17.4156 4.1424L19.8576 6.5844C20.5896 7.3164 21 8.3088 21 9.3432V17.7C21 18.5752 20.6523 19.4146 20.0335 20.0335C19.4146 20.6523 18.5752 21 17.7 21H6.3ZM4.8 6.3V17.7C4.8 18.528 5.472 19.2 6.3 19.2H6.6V15.3C6.6 14.5839 6.88446 13.8972 7.39081 13.3908C7.89716 12.8845 8.58392 12.6 9.3 12.6H14.7C15.4161 12.6 16.1028 12.8845 16.6092 13.3908C17.1155 13.8972 17.4 14.5839 17.4 15.3V19.2H17.7C18.528 19.2 19.2 18.528 19.2 17.7V9.3432C19.2 8.7852 18.9792 8.2512 18.5844 7.8576L16.2 5.4732V6.9C16.2 7.61608 15.9155 8.30284 15.4092 8.80919C14.9028 9.31554 14.2161 9.6 13.5 9.6H10.5C9.78392 9.6 9.09716 9.31554 8.59081 8.80919C8.08446 8.30284 7.8 7.61608 7.8 6.9V4.8H6.3C5.472 4.8 4.8 5.472 4.8 6.3ZM15.6 19.2V15.3C15.6 15.0613 15.5052 14.8324 15.3364 14.6636C15.1676 14.4948 14.9387 14.4 14.7 14.4H9.3C9.06131 14.4 8.83239 14.4948 8.6636 14.6636C8.49482 14.8324 8.4 15.0613 8.4 15.3V19.2H15.6ZM9.6 6.9C9.6 7.3968 10.0032 7.8 10.5 7.8H13.5C13.7387 7.8 13.9676 7.70518 14.1364 7.5364C14.3052 7.36761 14.4 7.1387 14.4 6.9V4.8H9.6V6.9Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
