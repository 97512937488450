import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
  saveStoragesSelect,
  fetchStorageAttributes,
} from "../../api/storages/storages-acitons.api";
import { IEntriesAttributeTypes } from "../types/storages.types";

export const storageEntityAttributesAdapter =
  createEntityAdapter<IEntriesAttributeTypes>({
    selectId: (attr) => attr.id,
  });

export const attributeOriginsAdapter =
  createEntityAdapter<IEntriesAttributeTypes>({
    selectId: (attr) => attr.id,
  });

const storageEntityAttributesSlice = createSlice({
  name: "storageEntityAttributes",
  initialState: {
    ...storageEntityAttributesAdapter.getInitialState(),
    count: 0,
  },
  reducers: {
    updateAttribute: storageEntityAttributesAdapter.updateOne,
    updateManyAttribute: storageEntityAttributesAdapter.updateMany,
    setAttributes: storageEntityAttributesAdapter.setAll,
    removeAttributes: storageEntityAttributesAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStorageAttributes.fulfilled, (state, action) => {
      state.count = action.payload.count;
      if (action.payload.results.attributes) {
        storageEntityAttributesAdapter.setAll(
          state,
          action.payload.results.attributes
        );
      }
    });
    // builder.addCase(saveStoragesSelect.fulfilled, (state, action) => {
    //   storageEntityAttributesAdapter.upsertMany(
    //     state,
    //     action.payload.attributes
    //   );
    // });
  },
});

export const {
  updateAttribute,
  updateManyAttribute,
  setAttributes,
  removeAttributes,
} = storageEntityAttributesSlice.actions;

export const storageEntityAttributesReducer =
  storageEntityAttributesSlice.reducer;
