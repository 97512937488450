import { useEffect, useRef, useCallback, FormEvent, useState } from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { ProcessIcon } from "../../../../components/icons/process.icon";
import { AppModelIcon } from "../../../../components/icons/app-model.icon";
import { AppInput } from "../../../../components/app-input/app-input.component";
import { AppSearchIcon } from "../../../../components/icons/app-search.icon";
import { EProcessStatus } from "../../../../store/types/processes.types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { fetchShortProcesses } from "../../../../api/processes/processes-actions.api";
import { selectAllProcesses } from "../../../../store/low-code/low-code.selector";
import { debounce } from "../../../../utils/utils";
import s from "../aside.module.scss";

const BUNCH_SIZE = 10;

export const ProcessesList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState<string>("");
  const [offset, setOffset] = useState(0);

  const processes = useAppSelector((state) => selectAllProcesses(state));
  const { hasMore, loading } = useAppSelector(
    (state) => state.lowCode.processes
  );

  useEffect(() => {
    dispatch(
      fetchShortProcesses({
        limit: BUNCH_SIZE,
        offset,
        short: true,
        process_status: [EProcessStatus.ACTIVE, EProcessStatus.RUNNING].join(
          ","
        ),
        query,
      })
    );
  }, [offset, query]);

  if (!processes) return null;

  const observer = useRef<IntersectionObserver>();

  const lastProcessElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset(offset + BUNCH_SIZE);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, hasMore]
  );

  const { control } = useForm({ mode: "onChange" });

  const onChangeSearch = (e: FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setQuery(value);
    setOffset(0);
  };

  const debouncedSearch = debounce(onChangeSearch);

  return (
    <>
      <div className={s.asideBody}>
        <div className={s.searchWrapper}>
          <AppInput
            icon={<AppSearchIcon width="16" height="16" />}
            sharedStyles={s.searchInputWrapper}
            inputStyles={s.searchInput}
            onChange={debouncedSearch}
            name={"search"}
            control={control}
            placeholder={t("page.process.search") as string}
          />
        </div>
        <div className={cn(s.asideList, s._nodeList)} role="listbox">
          {processes.map((item, index) => (
            <div
              key={item.id}
              className={cn(s.asideItem)}
              onDragStart={(event) => {
                event.dataTransfer.setData(
                  "application/reactflow",
                  "processNode"
                );
                event.dataTransfer.setData(
                  "application/process",
                  JSON.stringify(item)
                );
                event.dataTransfer.effectAllowed = "move";
              }}
              draggable
              role="option"
              ref={
                processes.length === index + 1 ? lastProcessElementRef : null
              }
            >
              <div className={s.asideItemCol}>
                <ProcessIcon />
                <div className={s.asideItemContainer}>
                  <div className={s.asideItemTitle}>{item.name}</div>
                  <div className={s.asideItemStatus}>
                    {t(`app.process.status.${item.status}`)}
                  </div>
                </div>
              </div>
              <AppModelIcon />
            </div>
          ))}
          {loading && (
            <div className={s.spinWrapper}>
              <Spin />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
