import React, { useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Checkbox, Radio } from "antd";

import { selectProcessDetail } from "../../../store/processes/process-detail/process-detail.selector";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchProcesses } from "../../../api/processes/processes-actions.api";
import { EInterval, intervalMapper } from "../../create-process/process.helper";

import { AppButton } from "../../../components/app-button/app-button.component";
import { AppInput } from "../../../components/app-input/app-input.component";
import { AppCheckbox } from "../../../components/app-checkbox/app-checkbox.component";
import { AppTabCalendar } from "../../../components/app-tab-calendar/app-tab-calendar.component";
import { AppScheduleIcon } from "../../../components/icons/app-schedule.icon";

import s from "../../create-process/create-process.module.scss";

type PropTypes = {
  onNext: () => void;
  isLowcode?: boolean;
};

export const StepDetail1: React.FC<PropTypes> = ({ onNext, isLowcode }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { currentProcess } = useAppSelector(selectProcessDetail);
  const {
    process_description,
    process_name,
    process_is_overwriting,
    process_scheduler,
  } = currentProcess;

  const { control } = useForm();

  useEffect(() => {
    dispatch(fetchProcesses());
  }, []);

  const scheduleOptions = [
    {
      key: intervalMapper(EInterval.PER_HOUR),
      value: "page.process.form.step.one.schedule.option.hour",
      engKey: "perhour",
    },
    {
      key: intervalMapper(EInterval.PER_DAY),
      value: "page.process.form.step.one.schedule.option.day",
      engKey: "perday",
    },
    {
      key: intervalMapper(EInterval.PER_WEEK),
      value: "page.process.form.step.one.schedule.option.week",
      engKey: "perweek",
    },
    {
      key: intervalMapper(EInterval.PER_MONTH),
      value: "page.process.form.step.one.schedule.option.month",
      engKey: "permonth",
    },
  ];

  const currentInterval = useMemo(
    () =>
      scheduleOptions.find((item) => item.key === process_scheduler?.interval),
    [process_scheduler]
  );

  return (
    <form className={cn(s.formWrapper, s.stepOneForm)} onSubmit={onNext}>
      <div className={s.formSection}>
        <div className={s.formTitleContainer}>
          <h3 className={s.formTitle}>
            {t("page.process.form.step.description")}
          </h3>
        </div>
        <div className={s.formFields}>
          <AppInput
            disabled
            control={control}
            name={"process_name"}
            placeholder={process_name}
          />
          <AppInput
            disabled
            control={control}
            name={"process_description"}
            placeholder={process_description}
            isTextArea={true}
          />
        </div>
      </div>
      {isLowcode ? null : (
        <div className={s.formSection}>
          <div className={s.formTitleContainer}>
            <h3 className={s.formTitle}>
              {t("page.process.form.step.one.schedule.title")}
            </h3>
          </div>
          <div className={s.formFields}>
            <Radio value={"scheduled"} disabled defaultChecked>
              {process_scheduler?.interval
                ? t("page.process.form.step.one.schedule.not_once")
                : t("page.process.form.step.one.schedule.once")}
            </Radio>
            {process_scheduler?.interval && (
              <div>
                <AppInput
                  control={control}
                  disabled
                  name={"option_name"}
                  placeholder={t(`${currentInterval?.value}`)}
                />
                <ScheduleInputs
                  control={control}
                  processScheduler={process_scheduler}
                  timeType={currentInterval?.engKey}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {isLowcode ? null : (
        <div className={s.formSection}>
          <div className={s.formTitleContainer}>
            <h3 className={s.formTitle}>
              {t("page.process.form.step.one.additional")}
            </h3>
          </div>
          <div className={s.formFields}>
            <AppCheckbox control={control} name={"process_is_overwriting"}>
              <Checkbox indeterminate={process_is_overwriting} disabled>
                {t("page.process.form.step.one.delete_notification")}
              </Checkbox>
              <></>
            </AppCheckbox>
          </div>
        </div>
      )}
      <div className={s.formFooterButtons}>
        <div></div>
        <AppButton disabled={false} htmlType={"submit"}>
          {t("steps.action.next")}
        </AppButton>
      </div>
    </form>
  );
};

const ScheduleInputs = ({ control, timeType, processScheduler }) => {
  const { t } = useTranslation();

  const itemsDays = [
    {
      key: "1",
      value: t("form.schedule.days.pn"),
      full: t("form.schedule.days.pn.full"),
    },
    {
      key: "2",
      value: t("form.schedule.days.vt"),
      full: t("form.schedule.days.vt.full"),
    },
    {
      key: "3",
      value: t("form.schedule.days.sr"),
      full: t("form.schedule.days.sr.full"),
    },
    {
      key: "4",
      value: t("form.schedule.days.cht"),
      full: t("form.schedule.days.cht.full"),
    },
    {
      key: "5",
      value: t("form.schedule.days.pt"),
      full: t("form.schedule.days.pt.full"),
    },
    {
      key: "6",
      value: t("form.schedule.days.sb"),
      full: t("form.schedule.days.sb.full"),
    },
    {
      key: "0",
      value: t("form.schedule.days.vs"),
      full: t("form.schedule.days.vs.full"),
    },
  ];

  const { minute, hour, week, month } = processScheduler;

  const itemsMonths = new Array(31).fill(0).map((el, i) => {
    const reducer = `${i + 1}`;
    return {
      key: reducer,
      value: reducer,
    };
  });

  const PROCESS_SCHEDULER_MINUTE = "process_scheduler.minute";
  const PROCESS_SCHEDULER_HOUR = "process_scheduler.hour";
  const MINUTE_TRANSLATE_KEY = "page.process.form.step.one.schedule.minutes";
  const HOUR_TRANSLATE_KEY = "page.process.form.step.one.schedule.hours";

  const pluralWeek = (key: string) => {
    if ([1, 2, 4].includes(Number(key))) {
      return "form.schedule.each.two";
    }
    if ([3, 5, 6].includes(Number(key))) {
      return "form.schedule.each.one";
    }
    return "form.schedule.each.five";
  };

  const checkNumber = (value: string) => {
    return String(value).length >= 2 ? value : `0${value}`;
  };

  const findWeek =
    itemsDays.find((day) => day.key === String(week))?.full || "";

  const renderPrompt = useCallback(() => {
    switch (timeType as EInterval) {
      case EInterval.PER_HOUR:
        if (minute) {
          return (
            <span className={s.promptValue}>
              <AppScheduleIcon height={"50"} />{" "}
              {t("form.schedule.option.result.minutes", {
                0: minute,
                1: t("form.schedule.minute.one"),
              })}
            </span>
          );
        }
        break;
      case EInterval.PER_DAY:
        if (hour && minute) {
          return (
            <span className={s.promptValue}>
              <AppScheduleIcon height={"50"} />{" "}
              {t("form.schedule.option.result.hours", {
                0: checkNumber(hour),
                1: checkNumber(minute),
              })}
            </span>
          );
        }
        break;
      case EInterval.PER_WEEK:
        if (hour && minute && week) {
          return (
            <span className={s.promptValue}>
              <AppScheduleIcon height={"50"} />{" "}
              {t("form.schedule.option.result.weeks", {
                0: t(pluralWeek(week)),
                1: findWeek,
                2: checkNumber(hour),
                3: checkNumber(minute),
              })}
            </span>
          );
        }
        break;
      case EInterval.PER_MONTH:
        if (hour && minute && month) {
          return (
            <span className={s.promptValue}>
              <AppScheduleIcon height={"50"} />{" "}
              {t("form.schedule.option.result.months", {
                0: month,
                1: checkNumber(hour),
                2: checkNumber(minute),
              })}
            </span>
          );
        }
        break;
    }
  }, [timeType, hour, week, month]);

  return (
    <>
      {timeType === "perhour" && (
        <div className={s.intervalInputs}>
          <AppInput
            label={t(MINUTE_TRANSLATE_KEY) as string}
            type={"number"}
            sharedStyles={cn(s.intervalInput, s._small)}
            control={control}
            name={PROCESS_SCHEDULER_MINUTE}
            placeholder={processScheduler.minute}
            defaultValue={processScheduler.minute}
            disabled
          />
          {renderPrompt()}
        </div>
      )}
      {timeType === "perday" && (
        <div className={s.intervalInputs}>
          <AppInput
            label={t(HOUR_TRANSLATE_KEY) as string}
            sharedStyles={cn(s.intervalInput, s._small)}
            type={"number"}
            control={control}
            name={PROCESS_SCHEDULER_HOUR}
            placeholder={hour}
            defaultValue={hour}
            disabled
          />
          <span style={{ lineHeight: "50px" }}>:</span>
          <AppInput
            label={t(MINUTE_TRANSLATE_KEY) as string}
            sharedStyles={cn(s.intervalInput, s._small)}
            type={"number"}
            control={control}
            name={PROCESS_SCHEDULER_MINUTE}
            placeholder={minute}
            defaultValue={minute}
            disabled
          />
          {renderPrompt()}
        </div>
      )}
      {timeType === "perweek" && (
        <div className={cn(s.intervalInputs, s._month)}>
          <AppTabCalendar
            control={control}
            name={"process_scheduler.week"}
            items={itemsDays}
            label={t("page.process.form.step.one.schedule.week") as string}
            selectedItem={week}
            disabled
          />
          <div className={s.intervalInputs}>
            <AppInput
              label={t(HOUR_TRANSLATE_KEY) as string}
              type={"number"}
              sharedStyles={cn(s.intervalInput, s._small)}
              control={control}
              name={PROCESS_SCHEDULER_HOUR}
              placeholder={hour}
              defaultValue={hour}
              disabled
            />
            <span style={{ lineHeight: "50px" }}>:</span>
            <AppInput
              label={t(MINUTE_TRANSLATE_KEY) as string}
              type={"number"}
              sharedStyles={cn(s.intervalInput, s._small)}
              control={control}
              name={PROCESS_SCHEDULER_MINUTE}
              placeholder={minute}
              defaultValue={minute}
              disabled
            />
            {renderPrompt()}
          </div>
        </div>
      )}
      {timeType === "permonth" && (
        <div className={cn(s.intervalInputs, s._month)}>
          <AppTabCalendar
            control={control}
            name={"process_scheduler.month"}
            items={itemsMonths}
            label={t("page.process.form.step.one.schedule.month") as string}
            selectedItem={month}
            disabled
          />
          <div className={s.intervalInputs}>
            <AppInput
              label={t(HOUR_TRANSLATE_KEY) as string}
              sharedStyles={cn(s.intervalInput, s._small)}
              type={"number"}
              control={control}
              name={PROCESS_SCHEDULER_HOUR}
              placeholder={hour}
              defaultValue={hour}
              disabled
            />
            <span style={{ lineHeight: "50px" }}>:</span>
            <AppInput
              label={t(MINUTE_TRANSLATE_KEY) as string}
              sharedStyles={cn(s.intervalInput, s._small)}
              type={"number"}
              control={control}
              name={PROCESS_SCHEDULER_MINUTE}
              placeholder={minute}
              defaultValue={minute}
              disabled
            />
            {renderPrompt()}
          </div>
        </div>
      )}
    </>
  );
};
