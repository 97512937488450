import React from "react";

type AppImportIconProps = {
  width?: string;
  height?: string;
};

export const AppEditIcon: React.FC<AppImportIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8175 7.99307C19.1647 7.64591 19.3557 7.18487 19.3557 6.69443C19.3557 6.204 19.1647 5.74296 18.8175 5.3958L17.3609 3.93919C17.0138 3.59203 16.5527 3.401 16.0623 3.401C15.5719 3.401 15.1108 3.59203 14.7646 3.93827L5 13.6725V17.7273H9.05296L18.8175 7.99307ZM16.0623 5.23783L17.5198 6.69352L16.0595 8.14828L14.6029 6.6926L16.0623 5.23783ZM6.83683 15.8905V14.4348L13.3025 7.9894L14.7591 9.446L8.29435 15.8905H6.83683ZM5 19.5642H19.6946V21.401H5V19.5642Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
