import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStorage: {
    id: 1,
    storage_import_type: "",
    storage_import_status: "",
  },
};

const storageSlice = createSlice({
  name: "currentStorage",
  initialState,
  reducers: {
    setCurrentStorage: (state, { payload }) => {
      state.currentStorage = { ...payload };
    },
    resetCurrentStorage: (state) => {
      state.currentStorage = { ...initialState.currentStorage };
    },
  },
});

export const { setCurrentStorage, resetCurrentStorage } = storageSlice.actions;
export const storageReducer = storageSlice.reducer;
