import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import {
  AppButton,
  EButtonSize,
} from "../../../../components/app-button/app-button.component";
import { AppSaveIcon } from "../../../../components/icons/app-save.icon";
import { AppLogoutIcon } from "../../../../components/icons/app-logout.icon";
import { AppStartIcon } from "../../../../components/icons/app-start.icon";

import s from "./finish-modal.module.scss";

export const FinishModal = ({
  open,
  disabledActivate,
  onDraft,
  onActivate,
  onExit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const handleDraft = () => {
    onDraft();
    onCancel();
  };

  const handleActivate = () => {
    onActivate();
    onCancel();
  };

  const handleExit = () => {
    onExit();
    onCancel();
  };
  //modal
  return (
    <Modal
      destroyOnClose={true}
      open={open}
      footer={false}
      width={750}
      onCancel={onCancel}
      title={<div>{t("page.process.form.step.lowcode.finish.title")}</div>}
    >
      <div className={s.container}>
        <div className={cn(s.column)}>
          <div className={s.info}>
            <AppSaveIcon width="48" height="48" />
            <div className={s.infoTitle}>
              {t("page.process.form.step.lowcode.finish.draft.title")}
            </div>
            <div className={s.infoDescription}>
              {t("page.process.form.step.lowcode.finish.draft.description")}
            </div>
          </div>
          <AppButton
            buttonSize={EButtonSize.FILL}
            isOutline={true}
            onClick={handleDraft}
          >
            {t("page.process.form.step.lowcode.finish.draft.title")}
          </AppButton>
        </div>
        <div className={cn(s.column, s._divider)}>
          <div className={cn(s.info)}>
            <AppStartIcon className={s.startIcon} width="48" height="48" />
            <div className={s.infoTitle}>
              {t("page.process.form.step.lowcode.finish.activate.title")}
            </div>
            <div className={s.infoDescription}>
              {t("page.process.form.step.lowcode.finish.activate.description")}
            </div>
          </div>
          <AppButton
            buttonSize={EButtonSize.FILL}
            disabled={disabledActivate}
            onClick={handleActivate}
          >
            {t("page.process.form.step.lowcode.finish.activate.title")}
          </AppButton>
        </div>
        <div className={cn(s.column, s._divider)}>
          <div className={s.info}>
            <AppLogoutIcon width="48" height="48" />
            <div className={s.infoTitle}>
              {t("page.process.form.step.lowcode.finish.exit.title")}
            </div>
            <div className={s.infoDescription}>
              {t("page.process.form.step.lowcode.finish.exit.description")}
            </div>
          </div>
          <AppButton
            buttonSize={EButtonSize.FILL}
            isDanger={true}
            onClick={handleExit}
          >
            {t("page.process.prompt.exit.confirm")}
          </AppButton>
        </div>
      </div>
    </Modal>
  );
};
