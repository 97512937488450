import { Modal } from "antd";
import { useEffect, useState } from "react";
import { Wizard } from "react-use-wizard";
import { createProcess } from "../../api/processes/processes-actions.api";
import { fetchStorageForProcess } from "../../api/storages/storages-acitons.api";
import { useAppDispatch } from "../../store/hooks";

import s from "./create-process-modal.module.scss";
import { Step1 } from "./steps/step1.component";
import { Step2 } from "./steps/step2.component";
import { Step3 } from "./steps/step3.component";

type PropTypes = {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const initialProcessData = {
  process_name: "",
  process_description: "",
  entities: [],
  process_sql: "",
};

export const CreateProcessModal: React.FC<PropTypes> = ({
  open,
  onSubmit,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const [storages, setStorages] = useState<any>(null);
  const [processData, setProcessData] = useState(initialProcessData);

  const handleUpdateProcessData = (data: any) => {
    setProcessData({ ...processData, ...data });
  };

  const handleSubmit = (data: { process_sql: string }) => {
    handleUpdateProcessData(data);

    const request = {
      process_name: processData.process_name,
      process_description: processData.process_description,
      entities: processData.entities,
      process_sql: data.process_sql,
    };
    dispatch(createProcess(request))
      .unwrap()
      .then(() => {
        setProcessData(initialProcessData);
        onSubmit();
      });
  };

  useEffect(() => {
    //FIXME: можно будет убрать если нормализовать стейт для источников и сущностей
    dispatch(fetchStorageForProcess())
      .unwrap()
      .then((data) => {
        const filtered = data.data.filter(
          (storage: { entities: string | any[] }) => storage.entities.length > 0
        );
        setStorages(filtered);
      });
  }, []);

  return (
    <Modal
      open={open}
      destroyOnClose={true}
      width={1000}
      footer={false}
      onCancel={onCancel}
      bodyStyle={{ padding: 0 }}
      title={<h2 className={s.modalTitle}>Создание процесса</h2>}
    >
      <Wizard>
        <Step1 processData={processData} onUpdate={handleUpdateProcessData} />
        <Step2
          storages={storages}
          processData={processData}
          onUpdate={handleUpdateProcessData}
        />
        <Step3
          storages={storages}
          processData={processData}
          onSubmit={handleSubmit}
        />
      </Wizard>
    </Modal>
  );
};
