import React from "react";

export const AppSchemeIcon: React.FC<{
  width?: string;
  height?: string;
}> = ({ width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8571 4V6H8.14286V4H3V9.33333H8.14286V7.33333H12.6576C12.2328 7.90713 12.0017 8.6099 12 9.33333V14.6667C11.9993 15.1969 11.7959 15.7052 11.4344 16.0801C11.0729 16.455 10.5827 16.666 10.0714 16.6667H8.14286V14.6667H3V20H8.14286V18H10.0714C10.9236 17.9989 11.7406 17.6474 12.3431 17.0225C12.9457 16.3976 13.2847 15.5504 13.2857 14.6667V9.33333C13.2855 9.07081 13.3352 8.81082 13.432 8.56822C13.5287 8.32562 13.6706 8.10516 13.8495 7.91944C14.0284 7.73371 14.2409 7.58636 14.4747 7.48579C14.7086 7.38523 14.9592 7.33342 15.2124 7.33333H15.8571V9.33333H21V4H15.8571ZM6.85714 8H4.28571V5.33333H6.85714V8ZM6.85714 18.6667H4.28571V16H6.85714V18.6667ZM19.7143 8H17.1429V5.33333H19.7143V8Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
