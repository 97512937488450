import s from "./app-under-maintain.module.scss";
import { AppDLHLogo } from "../icons/logo/app-dlh-logo.icon";

export const AppUnderMaintain = () => {
  return (
    <div className={s.maintainContainer}>
      <AppDLHLogo width={"140"} />
      <h2>Раздел находится в разработке</h2>
    </div>
  );
};
