import { useTranslation } from "react-i18next";
import { FC, ReactNode, useEffect, useState } from "react";
import { Dropdown, MenuProps } from "antd";
import { AppRuIcon } from "../../icons/langs/app-ru.icon";
import { AppEnIcon } from "../../icons/langs/app-en.icon";
import s from "./app-language.module.scss";
import { AppArrowIcon } from "../../icons/app-arrow.icon";
import cn from "classnames";

const languages = [
  {
    label: <span className={s.languageVariant}>RU</span>,
    key: "1",
    icon: <AppRuIcon />,
  },

  {
    label: <span className={s.languageVariant}>EN</span>,
    key: "2",
    icon: <AppEnIcon />,
  },
];

export const AppLanguage: FC<{ sharedStyles?: string }> = ({
  sharedStyles,
}) => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState<ReactNode>(languages[0].label);
  const activeLang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    switch (activeLang) {
      case "ru":
        setLang(languages[0].label);
        break;
      case "en":
        setLang(languages[1].label);
        break;
      default:
        setLang(languages[0].label);
    }
  }, []);

  const items: MenuProps["items"] = languages.map(({ key, icon, label }) => {
    return {
      key,
      label,
      icon,
    };
  });

  const changeI18n = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const changeLanguage = (data) => {
    const selectLang =
      languages.find((language) => language.key === data.key)?.label ||
      languages[0].label;
    setLang(selectLang);
    switch (data.key) {
      case "1":
        changeI18n("ru");
        break;
      case "2":
        changeI18n("en");
        break;
      default:
        changeI18n("ru");
    }
  };

  return (
    <Dropdown placement="bottomLeft" menu={{ items, onClick: changeLanguage }}>
      <div className={cn(s.languageWrapper, sharedStyles)}>
        <div className={s.languageData}>
          {lang} <AppArrowIcon side={"down"} width={"16"} />
        </div>
      </div>
    </Dropdown>
  );
};
