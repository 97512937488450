import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  useNodesState,
} from "reactflow";
import {
  IEntriesAttributeTypes,
  INodeTreeTypes,
} from "../../../store/types/storages.types";
import { NodeTree } from "../../custom-model-node/custom-model-node";
import React, { useEffect } from "react";
import { useAppSelector } from "../../../store/hooks";
import s from "./app-origin-tree.module.scss";
import { storageEntitiesSelectors } from "../../../store/storages/storage-entities.selector";
import { storageEntityAttributesSelectors } from "../../../store/storages/storage-entity-attributes.selector";

const nodeTypes = {
  customColorNode: NodeTree,
};

export const AppOriginTree: React.FC = () => {
  const storageEntities = useAppSelector(storageEntitiesSelectors.selectAll);
  const storageEntityAttributes = useAppSelector(
    storageEntityAttributesSelectors.selectAll
  );

  const [nodes, setNodes, onNodesChange] = useNodesState<Array<INodeTreeTypes>>(
    []
  );

  useEffect(() => {
    if (!storageEntities) {
      return;
    }

    const columnNumber = Math.floor(Math.sqrt(storageEntities.length));

    const columnOffsetY: any = {};

    const entities = storageEntities.map((e, i: number) => {
      const gap = 50;
      const offsetX = 400;
      const offsetY = 500;
      const columnOffsetX: number = i * (220 + gap) + offsetX;

      const data: INodeTreeTypes = {
        id: e.id.toString(),
        type: "customColorNode",
        position: { x: columnOffsetX, y: offsetY },
        data: {
          id: e.id.toString(),
          title: e.entity_name,
          schema: e.entity_schema,
          synchronization: e.entity_is_selected,
          fields: storageEntityAttributes.map((attribute: any) => {
            return {
              id: String(attribute.id),
              name: attribute.attribute_name,
              type: attribute.attribute_type,
              synchronization: attribute.attribute_is_selected,
            };
          }),
        },
      };
      // x - (i % columnNumber) * 300
      // y - columnOffsetY[(i % columnNumber)] || 0
      // columnOffsetY[(i % columnNumber)] = (columnOffsetY[(i % columnNumber)] || 0) + (e.attributes.length * 48) + 56 + 20 //(100 * (i - (i % 4))) + (e.attributes.length * 30);

      return data;
    });
    setNodes(entities as any);
  }, [storageEntities]);

  return (
    <div className={s.homeRender}>
      <ReactFlow
        id={"entity-area"}
        fitView
        fitViewOptions={{ maxZoom: 700, padding: 1 }}
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={[]}
        nodeTypes={nodeTypes}
      >
        <MiniMap />
        <Background
          variant={"dots" as BackgroundVariant}
          gap={20}
          size={1}
          color={"var(--grey-400)"}
        />
        <Controls />
      </ReactFlow>
    </div>
  );
};
