import cn from "classnames";

import { AppInfoIcon } from "../../icons/app-info.icon";
import s from "./app-alert.module.scss";
import { useTranslation } from "react-i18next";

type PropTypes = {
  type?: "info";
  title?: string;
  children?: React.ReactNode;
  className?: string;
};

export const Alert: React.FC<PropTypes> = ({
  type = "info",
  title = "app.alert.title",
  children,
  className,
}) => {
  const icon = <AppInfoIcon />;
  const { t } = useTranslation();

  return (
    <div className={cn(s.container, className)}>
      <section className={s.wrapper}>
        <span className={s.icon}>{icon}</span>
        <span className={s.title}>{t(title)}</span>
      </section>
      <section className={s.wrapper}>{children}</section>
    </div>
  );
};
