import { Select } from "antd";
import type { SelectProps } from "antd";
import { useTranslation } from "react-i18next";
import { ArrowDownIcon } from "../../../../components/icons";

import s from "./low-code-filter-modal.module.scss";

type PropsType = {
  operatorKey: string | null;
  operatorValue: string | string[] | null;
  onChange: (value: string | string[]) => void;
};

const options: SelectProps["options"] = [];

for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

export const FilterFormItemOperatorValue: React.FC<PropsType> = ({
  operatorKey,
  operatorValue,
  onChange,
}) => {
  const { t } = useTranslation();

  if (!operatorKey) {
    return null;
  }

  switch (operatorKey) {
    case "is_empty":
    case "is_not_empty":
    case "is_true":
    case "is_false":
      return null;
    case "gt":
    case "lt":
    case "gte":
    case "lte":
    case "eq":
    case "neq":
    case "contains":
    case "not_contains":
    case "starts_with":
    case "not_starts_with":
    case "ends_with":
    case "not_ends_with":
      return (
        <div className={s.textInput}>
          <input
            type="text"
            placeholder={t(
              "page.process.form.step.lowcode.filter.operator.value"
            )}
            onChange={(event) => {
              onChange(event.target.value);
            }}
          />
        </div>
      );
    default:
      return (
        <div className="filterSelect">
          <Select
            mode="multiple"
            allowClear
            placeholder={t(
              "page.process.form.step.lowcode.filter.operator.value"
            )}
            value={operatorValue ?? []}
            onChange={(value) => {
              onChange(value);
            }}
            options={options}
            style={{ width: "100%", marginRight: "16px" }}
            dropdownStyle={{ width: "100%", marginRight: "16px" }}
            suffixIcon={<ArrowDownIcon />}
          />
        </div>
      );
  }
};
