import React from "react";

export const AppEntityIcon: React.FC<{
  width?: string;
  height?: string;
}> = ({ width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4 8.7H14.7V6.6H15.9V3H12.3V4.2H6.6V3H3V6.6H4.2V12.3H3V15.9H6.6V14.7H9.3V17.4H8.1V21H11.7V19.8H17.4V21H21V17.4H19.8V11.1H21V7.5H17.4V8.7ZM13.5 4.2H14.7V5.4H13.5V4.2ZM14.7 13.5V14.7H13.5V13.5H14.7ZM4.2 4.2H5.4V5.4H4.2V4.2ZM5.4 14.7H4.2V13.5H5.4V14.7ZM6.6 13.5V12.3H5.4V6.6H6.6V5.4H12.3V6.6H13.5V12.3H12.3V13.5H6.6ZM10.5 19.8H9.3V18.6H10.5V19.8ZM19.8 19.8H18.6V18.6H19.8V19.8ZM18.6 8.7H19.8V9.9H18.6V8.7ZM18.6 17.4H17.4V18.6H11.7V17.4H10.5V14.7H12.3V15.9H15.9V12.3H14.7V9.9H17.4V11.1H18.6V17.4Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
