import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Col, Row, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import type { Node } from "reactflow";
import { nanoid } from "@reduxjs/toolkit";
import cn from "classnames";

import {
  AppDeleteIcon,
  ArrowDownIcon,
  GroupIcon,
  PlusIcon,
} from "../../../../components/icons";
import {
  AppButton,
  EButtonSize,
} from "../../../../components/app-button/app-button.component";
import {
  AttributeType,
  IEntityType,
  TransformationIncomerType,
} from "../../../../store/types/low-code.types";
import { nameValidation } from "../../../../helpers/low-code.helper";
import { GroupFormAttribute } from "./group-attribute.component";

import s from "./low-code-group.module.scss";

type TransformationType = {
  entityName: string | null;
  name: string;
};

type PropsType = {
  incomers: TransformationIncomerType[];
  transformation: TransformationType;
  onSubmit: (data: TransformationType) => void;
  onCancel: () => void;
  node: Node;
};

type AttributeItem = {
  id: number | string;
  attribute: AttributeType | null;
  isValid: boolean;
};

const aggregations = [
  "avg",
  "count",
  "countd",
  "max",
  "median",
  "min",
  "sum",
  "stdev",
  "variance",
];

type IndexItemType = {
  id: string;
  name?: string;
  attribute?: AttributeType | null;
  aggregation?: string;
  isValid: boolean;
};

type IndexItemProps = {
  id: string;
  entity: IEntityType | null;
  attribute: AttributeType | null;
  name?: string;
  aggregation?: string;
  onChange: (key: string, value) => void;
};

const IndexItem: React.FC<IndexItemProps> = ({
  entity,
  attribute,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.index}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={s.indexInput}>
            <Input
              placeholder={t("page.process.form.step.lowcode.group.index.name")}
              onChange={(value) => {
                onChange("name", value);
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <GroupFormAttribute
            entity={entity}
            attribute={attribute}
            onChange={(value) => {
              onChange("attribute", value);
            }}
          />
        </Col>
        <Col span={12}>
          <Select
            optionLabelProp="children"
            showArrow={true}
            showSearch={true}
            allowClear={true}
            placeholder={t(
              "page.process.form.step.lowcode.group.index.aggregation"
            )}
            onChange={(value) => {
              onChange("aggregation", value);
            }}
            suffixIcon={<ArrowDownIcon fill="#0057FF" />}
          >
            {aggregations.map((key) => (
              <Select.Option
                value={key}
                key={key}
                className={s.groupSelectOption}
              >
                {key} -&nbsp;
                {t(`page.process.form.step.lowcode.group.aggregation.${key}`)}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export const GroupForm: React.FC<PropsType> = ({
  node,
  transformation,
  incomers,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<IEntityType>(incomers[0].entity);
  const [attributes, setAttributes] = useState<AttributeItem[]>([]);
  const [indexes, setIndexes] = useState<IndexItemType[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid: isValidForm },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name:
        transformation?.name ??
        `${t("page.process.form.step.lowcode.transformation.node.group")} ${
          node.data.index
        }`,
      entityName: String(incomers[0].entity.entity_name),
    },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <div className={s.header}>
        <Row gutter={16}>
          <Col span={12}>
            <div className={s.textInput}>
              <GroupIcon />
              <Controller
                name="name"
                control={control}
                rules={nameValidation(t)}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder={t(
                      "page.process.form.step.lowcode.merge.name_placeholder"
                    )}
                    {...field}
                  />
                )}
              />
            </div>
            <ErrorMessage
              key={"name"}
              name={"name"}
              errors={errors}
              render={({ message }) => (
                <span className={s.inputError}>{message}</span>
              )}
            />
          </Col>
          <Col span={12}>
            <div className={s.textInput}>
              <Controller
                data-testid="group-entity-id"
                control={control}
                name={"entityName"}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder={t(
                      "page.process.form.step.lowcode.merge.select_left_entity_placeholder"
                    )}
                    disabled={true}
                    {...field}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <div className={s.contentWrapper}>
          <div className={s.content}>
            <div className={s.contentLeft}>
              {attributes.length ? (
                <p className={s.title}>
                  {t(
                    "page.process.form.step.lowcode.transformation.node.group"
                  )}
                </p>
              ) : null}
              <Row gutter={[0, 16]}>
                {attributes.map(({ id, attribute }) => {
                  return (
                    <Col
                      lg={{ span: 16 }}
                      xl={{ span: 11 }}
                      xxl={{ span: 8 }}
                      className={s.attributeWrapper}
                      key={id}
                    >
                      <GroupFormAttribute
                        entity={entity}
                        attribute={attribute}
                        onChange={(newAttribute) => {
                          setAttributes(
                            attributes.map((item) => {
                              if (item?.id === id) {
                                return {
                                  ...item,
                                  attribute: newAttribute ?? null,
                                  isValid: !!newAttribute,
                                };
                              } else {
                                return item;
                              }
                            })
                          );
                        }}
                      />
                      <AppButton
                        isOutline
                        className={s.removeButton}
                        onClick={() => {
                          setAttributes(
                            attributes.filter((item) => item.id !== id)
                          );
                        }}
                      >
                        <AppDeleteIcon />
                      </AppButton>
                    </Col>
                  );
                })}
                <Col
                  lg={{ span: attributes.length ? 16 : 24 }}
                  xl={{ span: attributes.length ? 11 : 24 }}
                  xxl={{ span: attributes.length ? 8 : 24 }}
                >
                  <AppButton
                    className={cn(s.addButton, s.addLeftButton)}
                    onClick={() => {
                      setAttributes([
                        ...attributes,
                        { id: nanoid(), attribute: null, isValid: false },
                      ]);
                    }}
                    buttonSize={EButtonSize.FILL}
                    isOutline
                  >
                    {t("page.process.form.step.lowcode.group.attribute.add")}
                    &nbsp;
                    <PlusIcon width="14" height="14" />
                  </AppButton>
                </Col>
              </Row>
            </div>
            <div className={s.contentRight}>
              {indexes.length ? (
                <p className={s.title}>
                  {t("page.process.form.step.lowcode.group.index.title")}
                </p>
              ) : null}
              <div className={s.indexesWrapper}>
                {indexes.map(({ id, attribute }) => {
                  return (
                    <div className={s.indexWrapper} key={id}>
                      <IndexItem
                        id={id}
                        entity={entity}
                        attribute={attribute ?? null}
                        onChange={(key, value) => {
                          setIndexes(
                            indexes.map((item) => {
                              if (item?.id === id) {
                                const newIndex = { ...item, [key]: value };
                                newIndex.isValid =
                                  Boolean(newIndex.name) &&
                                  Boolean(newIndex.attribute) &&
                                  Boolean(newIndex.aggregation);
                                return newIndex;
                              } else {
                                return item;
                              }
                            })
                          );
                        }}
                      />
                      <div
                        className={s.removeButtonWrapper}
                        onClick={() => {
                          setIndexes(indexes.filter((item) => item.id !== id));
                        }}
                      >
                        <AppButton isOutline className={s.removeButton}>
                          <AppDeleteIcon />
                        </AppButton>
                      </div>
                    </div>
                  );
                })}
              </div>
              <AppButton
                buttonSize={EButtonSize.FILL}
                isOutline
                className={s.addButton}
                onClick={() => {
                  setIndexes([
                    ...indexes,
                    { id: nanoid(), attribute: null, name: "", isValid: false },
                  ]);
                }}
              >
                {t("page.process.form.step.lowcode.group.index.add")} &nbsp;
                <PlusIcon width="14" height="14" />
              </AppButton>
            </div>
          </div>
        </div>
      </div>
      <div className={s.footer}>
        <div>{errors?.name?.message}</div>
        <AppButton
          disabled={
            !isValidForm ||
            !(
              (!!attributes.length &&
                attributes.every(({ isValid }) => isValid)) ||
              (!!indexes.length && indexes.every(({ isValid }) => isValid))
            )
          }
          htmlType="submit"
          sharedStyles={s.applyOperatorButton}
        >
          {t("page.process.form.step.lowcode.merge.submit")}
        </AppButton>
      </div>
    </form>
  );
};
