import s from "./custom-model-node.module.scss";

import { AppModelIcon } from "../icons/app-model.icon";

import { AppConnectionIcon } from "../icons/app-connection.icon";

import {
  INodeTreeDataFieldsTypes,
  INodeTreeDataTypes,
} from "../../store/types/storages.types";

import React, { memo, useEffect, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";

import cn from "classnames";
import { AppEntityIcon } from "../icons/app-entity.icon";
import { useTranslation } from "react-i18next";

export const NodeTree: React.FC<NodeProps<INodeTreeDataTypes>> = memo(
  ({ data, isConnectable }) => {
    const { t } = useTranslation();
    const [syncView, setSyncView] = useState<boolean>(false);
    useEffect(() => {
      const syncStateCondition = data.fields.every(
        (attr) => attr.synchronization === false
      );

      if (syncStateCondition) {
        setSyncView(true);
      } else {
        setSyncView(false);
      }
    }, [data]);

    return (
      <>
        <div className={cn(s.customNode)} id={"entity-one"}>
          {syncView && Boolean(data.fields.length) && (
            <div className={s.nodeSynchronization}>
              <AppConnectionIcon fill={"var(--grey-900)"} />
              <p>{t("source.detail.card.flow.sync_stopped")}</p>
            </div>
          )}
          <div className={s.customNodeTitle}>
            <AppModelIcon />
            <div className={s.customNodeTitleContainer}>
              <span>{data.title}</span>
              <span className={s.customNodeSchema}>
                {t("page.process.form.step.three.tree.theme")}:{" "}
                <span>{data.schema}</span>
              </span>
            </div>
          </div>
          {Boolean(!data.fields.length) && (
            <div className={s.emptyAttr}>
              <AppEntityIcon />
              <p>{t("source.detail.card.flow.no_attributes")}</p>
            </div>
          )}
          {data.fields.map((e: INodeTreeDataFieldsTypes, i: number) => (
            <div
              className={cn(s.customNodeField, {
                [s._synchronization]: e.synchronization,
              })}
              key={i}
            >
              <div className={s.customNodeName}>{e.name}</div>
              <div className={cn(s.customNodeName, s.customNodeAttr)}>
                {e.type}
              </div>
              <Handle
                className={s.customNodeDot}
                type="source"
                position={Position.Left}
                id={"a" + i}
                isConnectable={isConnectable}
              />
              <Handle
                className={s.customNodeDot}
                type="source"
                position={Position.Right}
                id={"b" + i}
                isConnectable={isConnectable}
              />
            </div>
          ))}
        </div>
      </>
    );
  }
);
