import React from "react";

type AppExclamationIconProps = {
  width?: string;
  height?: string;
  color?: string;
};

export const AppExclamationIcon: React.FC<AppExclamationIconProps> = ({
  width = "24",
  height = "24",
  color = "#737373",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9701 16.9701 21 12 21C7.02991 21 3 16.9701 3 12C3 7.02991 7.02991 3 12 3C16.9701 3 21 7.02991 21 12ZM19.4732 12C19.4732 7.87366 16.1263 4.52679 12 4.52679C7.87366 4.52679 4.52679 7.87366 4.52679 12C4.52679 16.1263 7.87366 19.4732 12 19.4732C16.1263 19.4732 19.4732 16.1263 19.4732 12ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16ZM13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13L11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8L13 13Z"
        fill={`var(--app-icon-color, ${color})`}
      />
    </svg>
  );
};
