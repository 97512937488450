import { showMessage } from "../../store/snackbar/snackbar.slice";
import { ESnackbarState } from "../../store/types/snackbar.types";
import {
  IConnectionsTypes,
  ICreateConnectionTypes,
  IEditConnectionTypes,
} from "../../store/types/connections.types";
import axios from "../../store/axios-instanse";

import { RejectedWithValueActionFromAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchStorageById } from "../storages/storages-acitons.api";

export const fetchConnections = createAsyncThunk(
  "connections/fetchList",
  async (
    _,
    { rejectWithValue, dispatch }
  ): Promise<
    IConnectionsTypes[] | RejectedWithValueActionFromAsyncThunk<any>
  > => {
    try {
      const response = await axios.get("/dlh-backend/storages/");
      return response.data;
    } catch (error) {
      dispatch(
        showMessage({
          message: "page.source.list.fetch.connection.error",
          type: ESnackbarState.ERROR,
        })
      );
      return rejectWithValue(error.code);
    }
  }
);

export const createConnections = createAsyncThunk(
  "connections/createConnection",
  async (connection: ICreateConnectionTypes, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "/dlh-backend/storages/",
        data: connection,
      });

      dispatch(
        showMessage({
          message: "page.source.list.add.success",
          type: ESnackbarState.SUCCESS,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showMessage({
          message: "page.source.list.add.error",
          type: ESnackbarState.ERROR,
        })
      );
      return rejectWithValue(error.response);
    }
  }
);

export const editConnectionsById = createAsyncThunk(
  "connections/editConnectionById",
  async (
    { id, connection }: IEditConnectionTypes,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "PUT",
        url: `dlh-backend/storages/${id}/`,
        data: connection,
      });

      dispatch(
        showMessage({
          message: "page.source.list.update.success",
          type: ESnackbarState.SUCCESS,
        })
      );
      dispatch(fetchStorageById(id));
      return response.data;
    } catch (error) {
      dispatch(
        showMessage({
          message: "page.source.list.update.error",
          type: ESnackbarState.ERROR,
        })
      );
      return rejectWithValue(error.response.status);
    }
  }
);
