import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";

import { AppContent, AppRightBar } from "../../components/app-main.layout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  AppButton,
  EButtonSize,
} from "../../components/app-button/app-button.component";
import { AppNavigation } from "../../components/app-navigation/app-navigation.component";
import {
  archiveProcessAction,
  fetchProcesses,
  runProcessAction,
} from "../../api/processes/processes-actions.api";
import {
  selectAllProcesses,
  selectSearchProcesses,
} from "../../store/processes/process-list/processes.selector";
import { ProcessCard } from "../../components/process-card/process-card.component";
import { AppInput } from "../../components/app-input/app-input.component";
import { AppSearchIcon } from "../../components/icons/app-search.icon";
import { useForm } from "react-hook-form";
import { searchProcess } from "../../store/processes/process-list/processes.slice";
import { CreateProcessModal } from "../../components/create-process-modal/create-process-modal.component";
import { AppConfirmModal } from "../../components/ui/app-confirm-modal/app-confirm-modal.component";
import {
  EProcessStatus,
  IDomainProcess,
} from "../../store/types/processes.types";
import { AppLogoLoader } from "../../components/ui/app-animated-logo/app-animated-logo.component";

import s from "./process.module.scss";

export const ProcessPage = () => {
  const dispatch = useAppDispatch();
  const { entries, search, loading } = useAppSelector(selectAllProcesses);
  const searchList = useAppSelector(selectSearchProcesses);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createProcessModal, showCreateProcessModal] = useState(false);
  const [archiveProcessModal, showArchiveProcessModal] =
    useState<IDomainProcess | null>(null);

  const { control } = useForm({ mode: "onChange" });

  const isSearch = Boolean(search);
  const processesList = isSearch ? searchList : entries;

  const onChangeSearch = (value: string) => {
    dispatch(searchProcess(value));
  };

  useEffect(() => {
    dispatch(fetchProcesses());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(searchProcess(""));
    };
  }, []);

  const handleHideCreateProcessModal = () => showCreateProcessModal(false);

  const handleSubmitCreateProcess = () => {
    handleHideCreateProcessModal();
    dispatch(fetchProcesses());
  };

  const handleShowArchiveProcessModal = (process: IDomainProcess) => {
    showArchiveProcessModal(process);
  };
  const handleHideArchiveProcessModal = () => {
    showArchiveProcessModal(null);
  };

  const handleSubmitConfirmArchiveProcess = () => {
    if (archiveProcessModal) {
      dispatch(
        archiveProcessAction({
          id: archiveProcessModal.id,
          process_is_deleted:
            archiveProcessModal.process_status_flow !== EProcessStatus.ARCHIVED,
        })
      );
    }

    showArchiveProcessModal(null);
  };

  const handleRunProcess = (id: number, process_name_slug: string) => {
    dispatch(
      runProcessAction({
        id,
        process_name_slug,
      })
    );
  };

  const processNewMenu = [
    {
      key: "1",
      label: (
        <Link to={"/processes/create"}>
          <span>{t("page.process.action.add.default")}</span>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to={"/processes/create-low-code"}>
          <span>{t("page.process.action.add.low_code")}</span>
        </Link>
      ),
    },
  ];

  const renderAddButton = useCallback(() => {
    return (
      <>
        <p>{t("page.process.first_process")}</p>
        <Dropdown menu={{ items: processNewMenu }} placement="bottomRight">
          <div className={s.searchButton}>
            <AppButton
              buttonSize={EButtonSize.HUG}
              sharedStyles={s.rightBarButton}
            >
              <>
                {t("page.process.first_process.action.add")}
                <PlusOutlined size={56} />
              </>
            </AppButton>
          </div>
        </Dropdown>
      </>
    );
  }, []);

  const renderEmptyState = () => {
    return (
      <div className={s.emptyState}>
        <div className={cn(s.emptyContent, s.notSelected)}>
          <h2>{t("page.process.first_process.none")}</h2>
          {renderAddButton()}
        </div>
      </div>
    );
  };

  const openProcessCard = (item: IDomainProcess): void => {
    navigate(`/processes/${item.id}`);
  };

  const renderList = () => {
    return (
      <>
        <div className={s.searchContainer}>
          <AppInput
            icon={<AppSearchIcon />}
            sharedStyles={s.searchInput}
            onChange={(e) => onChangeSearch(e.currentTarget.value)}
            name={"search"}
            control={control}
            placeholder={t("page.process.search") as string}
          />
          <Dropdown
            menu={{ items: processNewMenu }}
            placement="bottomRight"
            trigger={["click"]}
          >
            <div className={s.searchButton} id={"actions-button"}>
              <AppButton
                isOutline
                buttonSize={EButtonSize.HUG}
                sharedStyles={s.rightBarButton}
                id={"process-button-add"}
              >
                <>
                  {t("page.process.action.add")}
                  <PlusOutlined size={56} />
                </>
              </AppButton>
            </div>
          </Dropdown>
        </div>
        <div className={s.domainListWrapper}>
          {processesList.length ? (
            <div className={s.domainList}>
              {processesList.map((item, index) => (
                <ProcessCard
                  id={`process-card-${index}`}
                  data={item}
                  key={`${item.process_name}_${index}`}
                  onArchive={handleShowArchiveProcessModal}
                  onRun={handleRunProcess}
                  onEdit={() => openProcessCard(item)}
                />
              ))}
            </div>
          ) : null}

          <div>
            {isSearch && !processesList.length && (
              <div className={s.searchEmpty}>
                {t("page.process.search.not_found")}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <AppRightBar>
        <AppNavigation />
      </AppRightBar>
      <AppContent>
        <div className={s.container} id="domain-page">
          <AppLogoLoader loading={loading} />

          {renderList()}
          {!entries.length && !isSearch && renderEmptyState()}
        </div>
      </AppContent>

      <CreateProcessModal
        open={createProcessModal}
        onSubmit={handleSubmitCreateProcess}
        onCancel={handleHideCreateProcessModal}
      />

      <AppConfirmModal
        open={Boolean(archiveProcessModal)}
        title={
          archiveProcessModal?.process_status_flow === EProcessStatus.ARCHIVED
            ? "page.process.card.archive.form.title"
            : "page.process.card.archive.form.confirm"
        }
        isDanger={
          archiveProcessModal?.process_status_flow !== EProcessStatus.ARCHIVED
        }
        btnTextConfirm={
          archiveProcessModal?.process_status_flow === EProcessStatus.ARCHIVED
            ? "page.process.card.actions.unarchive"
            : "page.process.card.actions.archive"
        }
        onConfirm={handleSubmitConfirmArchiveProcess}
        onCancel={handleHideArchiveProcessModal}
      >
        {archiveProcessModal ? (
          <>
            {archiveProcessModal.process_status_flow ===
            EProcessStatus.ARCHIVED ? (
              <p className="mb-16">
                <Trans
                  i18nKey={
                    "page.process.card.archive.form.description.one.unarchive"
                  }
                  t={t}
                  values={{
                    process_name: archiveProcessModal.process_name,
                  }}
                  components={[
                    <span
                      key={0}
                      style={{ color: "var(--accent-first)" }}
                    ></span>,
                  ]}
                ></Trans>
              </p>
            ) : (
              <p className="mb-16">
                <Trans
                  i18nKey={
                    "page.process.card.archive.form.description.one.archive"
                  }
                  t={t}
                  values={{
                    process_name: archiveProcessModal.process_name,
                  }}
                  components={[
                    <span
                      key={0}
                      style={{ color: "var(--accent-first)" }}
                    ></span>,
                  ]}
                ></Trans>
              </p>
            )}

            {archiveProcessModal.process_status_flow ===
            EProcessStatus.ARCHIVED ? null : (
              <p className="mb-16">
                {t("page.process.card.archive.form.description.three")}
              </p>
            )}
            <p>{t("page.process.card.archive.form.description.two")}</p>
          </>
        ) : null}
      </AppConfirmModal>
    </>
  );
};
