import React, { useCallback, useEffect, useState, useRef } from "react";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import { Modal, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import isEqual from "lodash.isequal";

import { AppContent } from "../../components/app-main.layout";
import { AppCreateCurrent } from "../../components/forms/app-create-current-form/app-create-current.form";
import { AppArrowIcon } from "../../components/icons/app-arrow.icon";
import { AppOriginTabs } from "../../components/origin/app-origin-tabs/app-origin-tabs.component";
import { AppOriginTable } from "../../components/origin/app-origin-table/app-origin-table.component";
import { AppButton } from "../../components/app-button/app-button.component";
import { AppSaveIcon } from "../../components/icons/app-save.icon";
import { AppConditionSwitcher } from "../../components/app-condition-switcher/app-condition-switcher.component";
import { AppOriginTree } from "../../components/origin/app-origin-tree/app-origin-tree.component";
import { OriginHeaderActions } from "../../components/origin/origin-header-actions/origin-header-actions.component";
import { AppLogoLoader } from "../../components/ui/app-animated-logo/app-animated-logo.component";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchRules,
  fetchStorageById,
  fetchStorageAttributes,
  saveStoragesSelect,
  fetchStorageEntities,
} from "../../api/storages/storages-acitons.api";
import { truncateText } from "../../helpers/truncate-text.helper";
import { EEntitySelectedStateType } from "../../store/types/storages.types";
import {
  IEntriesAttributeTypes,
  IStorageEntriesType,
  IStorageType,
} from "../../store/types/storages.types";
import { storagesSelectors } from "../../store/storages/storages.selector";
import { storageEntityAttributesSelectors } from "../../store/storages/storage-entity-attributes.selector";
import { clearEntities } from "../../store/storages/storage-entities.slice";
import { storageEntitiesSelectors } from "../../store/storages/storage-entities.selector";
import { setCurrentStorage } from "../../store/storages/storage.slice";
import { selectStorage } from "../../store/storages/storage.selector";

import s from "./origin-page.module.scss";

export const OriginPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [query] = useSearchParams();
  const storageId = Number(params.id);
  const entityId = Number(query.get("tab"));
  const { t } = useTranslation();

  const { currentStorage } = useAppSelector(selectStorage);
  const { storage_import_status } = currentStorage;
  const storageData = useAppSelector((state) =>
    storagesSelectors.selectById(state, storageId)
  ) as IStorageType;

  const storageEntitiesTotal = useAppSelector(
    storageEntitiesSelectors.selectTotal
  );
  const entity = useAppSelector((state) =>
    storageEntitiesSelectors.selectById(state, entityId)
  );
  const storageEntityAttributes = useAppSelector((state) =>
    storageEntityAttributesSelectors.selectAll(state)
  );

  const [disableStorageActions, setDisableStorageActions] = useState(false);
  const [showSettingStorageModal, setShowSettingStorageModal] = useState(false);
  const [view, changeView] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [modifiedAttributes, setModifiedAttributes] = useState<any[]>([]);
  const [isWholeEntitySelected, setIsWholeEntitySelected] =
    useState<EEntitySelectedStateType>("empty");

  const modifiedAttributeIds = modifiedAttributes.map((id) => Number(id));

  const disableActions = () => {
    if (
      storage_import_status === "starting" ||
      storage_import_status === "stopping_inprogress" ||
      storage_import_status === "running" ||
      storage_import_status === "stopping_started"
    ) {
      setDisableStorageActions(true);
    } else {
      setDisableStorageActions(false);
    }
  };

  useEffect(() => {
    const { id } = currentStorage;
    if (id === storageId) {
      disableActions();
    }
  }, [currentStorage]);

  /** Проверка на наличие хотя-бы одного выбранного атрибута */

  const canEdit = storageEntityAttributes.some(
    (attr) => attr?.attribute_is_selected
  );

  const activateLoader = (value) => {
    setIsLoader(value);
  };

  const entityType = storageData?.storage_datasource_code;
  /** Мерж данных из стора с модифицированными данными из таблицы, пока данные не сохранены */

  const handleShowSettingStorageModal = () => setShowSettingStorageModal(true);
  const handleHideSettingStorageModal = () => setShowSettingStorageModal(false);

  const saveAttributeSettings = () => {
    const _entity = {
      ...entity,
      entity_selected_state: isWholeEntitySelected,
      attributes: isWholeEntitySelected === "partial" ? modifiedAttributes : [], //если "выбрано все" и "не выбрано ничего", тогда бэк самостоятельно изменяет атрибуты на своей стороне
    };

    dispatch(
      saveStoragesSelect({
        storageId,
        entity: _entity as IStorageEntriesType,
      })
    )
      .unwrap()
      .then(() => {
        // После того как сохранили источник, удаляем модифицированные аттрибуты из конкретной сущности
        const _modified = modifiedAttributes;
        storageEntityAttributes.forEach((id: any) => {
          const index = _modified.findIndex((obj) => obj.id === id);
          if (index !== -1) {
            _modified.splice(index, 1);
          }
        });
        const paginationPage = localStorage.getItem("entitiesPaginationPage");
        dispatch(
          fetchStorageEntities({
            storageId,
            offset: Number(paginationPage) - 1,
          })
        );
        dispatch(
          fetchStorageAttributes({
            storageId,
            entityId,
            offset: Number(query.get("page")) - 1,
          })
        ).then(() => {
          setModifiedAttributes([]);
        });
      });
  };

  const changeSelectAll = (value: EEntitySelectedStateType) => {
    setIsWholeEntitySelected(value);
  };

  const handleEntitySelected = (entityIsSelected: boolean, attributes) => {
    if (entityIsSelected) {
      changeSelectAll("full");
    } else {
      changeSelectAll("empty");
    }
    if (!entity) return;

    const filteredAttributes = attributes.filter(
      (item) => item.attribute_is_selected !== entityIsSelected
    );

    const _modified = filteredAttributes.map((item) => {
      return {
        ...item,
        attribute_is_selected: entityIsSelected,
      };
    });
    setModifiedAttributes([..._modified]);
  };

  const handleAttributeSelected = (
    id: number,
    changes: Partial<IEntriesAttributeTypes>
  ) => {
    const _attribute = {
      ...storageEntityAttributes.find((item) => item.id == id),
      ...changes,
    };

    changeSelectAll("partial");

    if (
      modifiedAttributes.find((item) => item?.id == id) &&
      isEqual(
        _attribute,
        storageEntityAttributes.find((item) => item.id == id)
      )
    ) {
      const _modified = modifiedAttributes;
      const index = _modified.findIndex((obj) => obj.id === id);
      if (index !== -1) {
        _modified.splice(index, 1);
      }
      setModifiedAttributes([..._modified]);
    } else {
      setModifiedAttributes([...modifiedAttributes, _attribute]);
    }
  };

  /** Lifecycle */
  useEffect(() => {
    dispatch(fetchStorageById(storageId)).then((data: any) => {
      const { id, storage_import_type, storage_import_status } =
        data.payload.storage[storageId];

      const currentStorage = {
        id,
        storage_import_type,
        storage_import_status,
      };
      dispatch(setCurrentStorage(currentStorage));
    });
    dispatch(fetchStorageEntities({ storageId }));
    dispatch(fetchRules());

    return () => {
      dispatch(clearEntities());
    };
  }, []);

  /** Render */
  const renderTitle = useCallback(() => {
    const { text, isTruncated } = truncateText(storageData.storage_name, 24);
    if (isTruncated) {
      return (
        <Tooltip title={storageData.storage_name} color={"var(--white)"}>
          <span>{text}</span>
        </Tooltip>
      );
    } else {
      return <span>{text}</span>;
    }
  }, [storageData?.storage_name]);

  const renderSaveButton = () => {
    if (modifiedAttributes.length) {
      return (
        <div className={s.saveContainer}>
          <AppButton
            isFilled={!disableStorageActions}
            id={"save-button"}
            onClick={saveAttributeSettings}
            sharedStyles={s.rightBarButton}
            disabled={disableStorageActions}
          >
            {t("source.detail.card.save_changes")}
            <AppSaveIcon />
          </AppButton>
        </div>
      );
    }
  };

  const changeTab = () => {
    setModifiedAttributes([]);
  };

  if (!storageData)
    return (
      <AppContent isFull>
        <AppLogoLoader loading={true} />
      </AppContent>
    );

  return (
    <>
      <AppContent isFull>
        <div className={s.originSettings}>
          <div className={s.actionBlock} id={"first-current"}>
            <NavLink to={"/home"} className={s.originBlock}>
              <AppArrowIcon side={"left"} />
              <span>{renderTitle()}</span>{" "}
            </NavLink>
            {Boolean(storageEntitiesTotal) && (
              <>
                <div className={s.divider}></div>
                <AppConditionSwitcher
                  uncheckedValue={t("source.detail.card.view.table")}
                  checkedValue={t("source.detail.card.view.diagram")}
                  onChange={changeView}
                />
              </>
            )}
          </div>
          <OriginHeaderActions
            disableStorageActions={disableStorageActions}
            storageId={storageId}
            storageSourceType={entityType}
            onShowSettingStorage={handleShowSettingStorageModal}
          />
        </div>
        <>
          {view ? (
            <AppOriginTree />
          ) : (
            <div className={s.originContent}>
              <AppOriginTabs
                modifiedAttributeIds={modifiedAttributeIds}
                activateLoader={activateLoader}
                changeTab={changeTab}
                setIsWholeEntitySelected={(value: EEntitySelectedStateType) =>
                  setIsWholeEntitySelected(value)
                }
              />
              <div className={s.originTable}>
                <AppOriginTable
                  isLoader={isLoader}
                  activateLoader={activateLoader}
                  disableStorageActions={disableStorageActions}
                  entity={entity}
                  entityType={entityType}
                  modifiedAttributes={
                    modifiedAttributes as IEntriesAttributeTypes[]
                  }
                  storageId={storageId}
                  onEntitySelected={handleEntitySelected}
                  onAttributeSelected={handleAttributeSelected}
                  isWholeEntitySelected={isWholeEntitySelected}
                  setIsWholeEntitySelected={(value: EEntitySelectedStateType) =>
                    setIsWholeEntitySelected(value)
                  }
                />
                {renderSaveButton()}
              </div>
            </div>
          )}
        </>
      </AppContent>

      <Modal
        open={showSettingStorageModal}
        footer={false}
        onCancel={handleHideSettingStorageModal}
        title={t("modal.source.create.title.edit")}
      >
        <AppCreateCurrent
          origin={storageData}
          onCancel={handleHideSettingStorageModal}
        />
      </Modal>
    </>
  );
};
