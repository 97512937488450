import { Transformation } from ".";

type GroupDataType = {
  // TODO
};

export class Group extends Transformation {
  protected children = new Map<string, Group>();
  public parent!: Group | null;

  constructor(data?: any | undefined | null) {
    super(data);
  }

  getEntities(): number[] {
    // TODO
    return [];
  }

  getResult(): any {
    // TODO
  }

  toObject(): any {
    // TODO add recursion
    return this.data;
  }
}
