import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { onLogin } from "../../../store/auth/auth.slice";
import { fetchAuthLogin } from "../../../api/auth/auth-actions";
import { useAppDispatch } from "../../../store/hooks";
import { AppButton } from "../../app-button/app-button.component";
import { AppInput } from "../../app-input/app-input.component";

import s from "./app-auth-form.module.scss";

export const AppAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const { t } = useTranslation();

  const methods = useForm<{ login: string; password: string }>({
    mode: "onChange",
    defaultValues: {
      password: "",
      login: "",
    },
  });
  const { handleSubmit, formState, control } = methods;

  const submitForm = (data: { login: string; password: string }) => {
    setError("");
    dispatch(
      fetchAuthLogin({
        username: data.login,
        password: data.password,
      })
    ).then((res: any) => {
      if (res?.error) {
        setError("auth.form.error.wrong_credentials");
      } else {
        dispatch(onLogin(res.payload));
        navigate("/");
      }
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => submitForm(data))}
        className={s.authForm}
      >
        <AppInput
          rules={{ required: true }}
          autoComplete={"off"}
          control={control}
          name={"login"}
          placeholder={t("modal.source.create.placeholder.login") as string}
        />
        <AppInput
          rules={{ required: true }}
          autoComplete={"off"}
          control={control}
          name={"password"}
          placeholder={t("modal.source.create.placeholder.password") as string}
        />
        <div className={s.buttonContainer}>
          <AppButton disabled={!formState.isValid} htmlType={"submit"}>
            {t("auth.form.action.submit")}
          </AppButton>
          {error && <span className={s.error}>{t(error)}</span>}
        </div>
      </form>
    </>
  );
};
