import React, { memo, useContext } from "react";
import { Handle, NodeProps, Position, useNodeId } from "reactflow";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { AppSmallCrossIcon, ProcessIcon } from "../icons";
import { AppButton, EButtonSize } from "../app-button/app-button.component";
import { TransformContext } from "../../pages/create-process/steps/transform.provider";
import s from "./node.module.scss";
import { ScheduleType } from "../../store/types/low-code.types";
import { IStorageEntriesType } from "../../store/types/storages.types";

export type TProcessNodeField = {
  id: string;
  name: string;
};

export type TProcessNode = {
  id: string;
  title: string;
  status: string;
  schedule: ScheduleType | null;
  entity: IStorageEntriesType;
};

export const ProcessNode: React.FC<NodeProps<TProcessNode>> = memo(
  ({ data, selected, isConnectable }) => {
    const { t } = useTranslation();
    const nodeId = useNodeId();
    const { onEmitDeleteNode } = useContext(TransformContext);

    const handleDelete = (event: React.MouseEvent) => {
      event.stopPropagation();
      onEmitDeleteNode(String(nodeId));
    };

    return (
      <>
        <div
          className={cn(s.customNode, s._process, {
            [s.customNodeSelected]: selected,
          })}
          data-testid="process-node"
        >
          <div
            className={s.customNodeClose}
            onClick={handleDelete}
            role="img"
            aria-label={`remove process ${nodeId}`}
          >
            <AppSmallCrossIcon />
          </div>
          <div className={s.customNodeTitle}>
            <ProcessIcon width="16" height="16" />
            <span>{data.title}</span>
          </div>
          <Handle
            type="source"
            id={`process-${data.id}`}
            position={Position.Right}
            isConnectable={isConnectable}
            style={{ background: "#b7b7b7", width: "8px", height: "8px" }}
          />
          <div className={s.customNodeBody}>
            <AppButton
              buttonSize={EButtonSize.FILL}
              isOutline={true}
              isSmall={true}
            >
              {t("page.process.form.step.lowcode.node.process.view")}
            </AppButton>
          </div>
        </div>
      </>
    );
  }
);
