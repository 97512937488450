import { useRef, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import { Input } from "antd";

import { AttributeItem } from "./attribute-item.component";
import {
  updateAttributes,
  addAttribute,
  updateTitle,
} from "../../../../store/processes/process-create/process-create.slice";
import { useAppDispatch } from "../../../../store/hooks";
import {
  AppEqualsIcon,
  AppDeleteIcon,
  AppPlusIcon,
} from "../../../../components/icons";

import s from "./join-modal.module.scss";

type PropsType = {
  itemType: string;
  count: number; //count of drop blocks
  removeJoin: () => void;
  attributes: any;
  title: string;
};

export const DropSection: React.FC<PropsType> = memo(
  ({ itemType, removeJoin, count, attributes, title }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isTypeError, setIsTypeError] = useState(false);

    const [{ canDrop, isOver }, drop] = useDrop(
      () => ({
        accept: itemType,
        drop: (item, monitor) => {
          dispatch(addAttribute({ id: count, attribute: item }));
        },
        canDrop: (item: any) => {
          const elementIsIncluded = Boolean(
            attributes.find((element) => element.entity_id === item.entity_id)
          );

          const isEqualType = attributes.every(
            (element) => element.type === item.type
          );
          setIsTypeError(!isEqualType);

          return !elementIsIncluded && isEqualType;
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      }),
      [attributes]
    );

    let dropStyle;
    if (canDrop) {
      dropStyle = {
        backgroundColor: "rgba(0, 87, 255, 0.05)",
      };
    }

    const removeAttribute = (id) => {
      const filteredAttributes = attributes.filter((item) => item.id !== id);
      dispatch(updateAttributes({ id: count, attributes: filteredAttributes }));
    };

    return (
      <div
        className={s.dropContainer}
        style={{ borderColor: isOver && isTypeError ? "red" : "#d9d9d9" }}
      >
        <div className={s.dropBlockCommon} ref={drop} style={{ ...dropStyle }}>
          {attributes.length > 0 ? (
            <div className={s.dropContainerSum}>
              {attributes.map((item, index) => {
                return (
                  <>
                    {attributes.length > 1 && index > 0 && (
                      <div>
                        <AppPlusIcon />
                      </div>
                    )}
                    <div className={s.firstSummand} key={item.name}>
                      <AttributeItem
                        removeAttribute={removeAttribute}
                        itemType={itemType}
                        attribute={item}
                      />
                    </div>
                  </>
                );
              })}
              <div>
                <AppEqualsIcon />
              </div>
              <AttributeInput count={count} title={title} />
            </div>
          ) : (
            <div className={s.dropField}>
              <div>
                {t("page.process.form.step.lowcode.join.modal.drop.field")}
              </div>
            </div>
          )}
          {isOver && isTypeError && (
            <div className={s.dropContainerError}>
              {t("create.join.page.selected.attributes.types.dont.match")}
            </div>
          )}
        </div>
        {count !== 1 && (
          <div className={s.deleteIcon} onClick={removeJoin}>
            <AppDeleteIcon fill={"rgba(255, 46, 0, 1)"} />
          </div>
        )}
      </div>
    );
  }
);

export const AttributeInput = ({ count, title }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const newAttributeTitle = useRef<string>("");

  const saveTitle = () => {
    dispatch(updateTitle({ id: count, title: newAttributeTitle.current }));
  };

  return (
    <div className={s.result}>
      <Input
        onBlur={saveTitle}
        defaultValue={title}
        onChange={({ target }) => {
          newAttributeTitle.current = target.value;
        }}
        size="large"
        placeholder={t("create.low.code.join.modal.set.attribute.name")}
      />
    </div>
  );
};
