import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDrop } from "react-dnd";
import { Tooltip } from "antd";

import { AppBoxIcon, AppCloseIcon } from "../../../../components/icons";
import { truncateText } from "../../../../helpers/truncate-text.helper";

import s from "./join-modal.module.scss";

type PropsType = {
  itemType: string;
  attribute?;
  removeAttribute: (id: number) => void;
};

export const AttributeItem: React.FC<PropsType> = ({
  itemType,
  attribute,
  removeAttribute,
}) => {
  const { t } = useTranslation();
  const [currentDropItem, setCurrentDropItem] = useState<any>(attribute || {});

  const removeItem = () => {
    setCurrentDropItem({});
    removeAttribute(currentDropItem.id);
  };

  const renderTitle = (title: string) => {
    const { text, isTruncated } = truncateText(title, 20);
    if (isTruncated) {
      return (
        <Tooltip title={title} color={"var(--white)"}>
          <span>{text}</span>
        </Tooltip>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  return Object.keys(currentDropItem).length !== 0 ? (
    <div className={s.itemContainer}>
      <div className={s.itemContainerLeft}>
        <AppBoxIcon width="24" height="24" />
        <div>
          <div className={s.attributeTitle}>
            {renderTitle(currentDropItem.name)}
          </div>
          <div className={s.attributeType}>
            {t("source.detail.card.table.head.type")}:{" "}
            <span>{currentDropItem.type}</span>
          </div>
          <div className={s.entityTitle}>
            {t("create.low.code.join.modal.entity")}:{" "}
            <span>{currentDropItem.entity}</span>
          </div>
        </div>
      </div>
      <div onClick={removeItem} className={s.removeIcon}>
        <AppCloseIcon />
      </div>
    </div>
  ) : null;
};
