import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { AppButton } from "../../../../components/app-button/app-button.component";
import { AppConfirmAlert } from "../../../../components/ui/app-alert/app-confirm-alert.component";
import type { SelectProps } from "antd";
import type { FilterFunc, DefaultOptionType } from "rc-select/lib/Select";
import s from "./low-code-edit-entity-modal.module.scss";

type PropTypes = {
  storageOptions: SelectProps["options"];
  nodeValues: string[];
  onSave: (saved: string[], deleted: string[]) => void;
  connections: any;
};

export const LowCodeEditEntityForm: React.FC<PropTypes> = ({
  storageOptions,
  nodeValues,
  onSave,
  connections,
}) => {
  const { t } = useTranslation();
  const [entities, setEntities] = useState(nodeValues);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState("");

  const handleFilterSearchStoragses: FilterFunc<DefaultOptionType> = (
    input,
    option
  ) => {
    return String(option?.children ?? "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleSave = () => {
    const deleted = (storageOptions as Array<{ value: string; label: string }>)
      .map(({ value }) => value)
      .filter((value: string) => !entities.includes(value));

    return onSave(entities, deleted);
  };

  const labelToDelete = useMemo(
    () =>
      storageOptions?.find(({ value }) => value === selectedToDelete)?.label,
    [selectedToDelete]
  );

  return (
    <div className={s.formWrapper}>
      <div className={s.formInner}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Select
            mode="multiple"
            showArrow={true}
            showSearch={true}
            filterOption={handleFilterSearchStoragses}
            style={{ width: "100%", marginRight: "16px" }}
            placeholder={
              t("page.process.form.lowcode.entity.placeholder") || ""
            }
            notFoundContent={<>{t("app.input.select.not_found")}</>}
            options={storageOptions}
            value={entities}
            onSelect={(value: string) => {
              setEntities([...entities, value]);
            }}
            dropdownStyle={{ width: "100%", marginRight: "16px" }}
            onDeselect={(value: string) => {
              if (
                connections?.find(({ sourceHandle }) => value === sourceHandle)
              ) {
                setShowConfirmAlert(true);
                setSelectedToDelete(value);
              } else {
                setEntities(entities.filter((entity) => entity !== value));
              }
            }}
          />
          <AppButton onClick={handleSave}>
            {t("page.process.form.step.lowcode.entity.edit.save")}
          </AppButton>
        </div>
        {showConfirmAlert && (
          <AppConfirmAlert
            onConfirm={() => {
              setEntities(
                entities.filter((entity) => entity !== selectedToDelete)
              );
              setShowConfirmAlert(false);
            }}
            onCancel={() => {
              setShowConfirmAlert(false);
            }}
          >
            <span className={s.storageName}>{labelToDelete}</span>
            {t("page.process.form.step.lowcode.entity.edit.confirmation")}
          </AppConfirmAlert>
        )}
      </div>
    </div>
  );
};
