import { createSlice } from "@reduxjs/toolkit";
import {
  archiveProcessAction,
  fetchProcesses,
} from "../../../api/processes/processes-actions.api";
import { IDomainProcessState } from "../../types/processes.types";

const initialState: IDomainProcessState = {
  entries: [],
  search: "",
  error: "",
  loading: false,
};

const processesSlice = createSlice({
  name: "processes",
  initialState,
  reducers: {
    searchProcess: (state, { payload }) => {
      state.search = payload;
    },
  },
  extraReducers: {
    [fetchProcesses.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchProcesses.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.entries = payload.data;
    },
    [fetchProcesses.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [archiveProcessAction.pending.type]: (state) => {
      state.loading = true;
    },
    [archiveProcessAction.fulfilled.type]: (state, { payload }) => {
      state.loading = false;

      const process = state.entries.find((entity) => entity.id === payload.id);
      if (process) {
        process.process_is_deleted = payload.process_is_deleted;
      }
    },
    [archiveProcessAction.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});

export const { searchProcess } = processesSlice.actions;
export const processesReducer = processesSlice.reducer;
