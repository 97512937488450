import { useTranslation } from "react-i18next";
import { AppInfoIcon } from "../../../../components/icons";

import s from "./join-modal.module.scss";

export const JoinInfo = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <div className={s.infoContainer}>
      <div className={s.infoContainerTitle}>
        <AppInfoIcon width="22" height="22" fill={"rgba(0, 87, 255, 1)"} />
        {t("page.process.form.step.lowcode.join.modal.information")}
      </div>
      <div className={s.infoContainerBody}>
        <div
          dangerouslySetInnerHTML={{
            __html: t(title),
          }}
        />
      </div>
    </div>
  );
};
