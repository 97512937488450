import { IAttributeRule } from "./attribute-rule.types";
import { IStorageConfigTypes } from "./connections.types";

export type EEntitySelectedStateType = "full" | "partial" | "empty";

export interface IEntriesAttributeTypes {
  attribute_is_nullable: boolean;
  attribute_is_selected: boolean;
  attribute_name: string;
  attribute_name_slug: string;
  attribute_type: string;
  id: number;
  attribute_rules: IEntriesAttributeRules[];
  is_type_supported: boolean;
}

export interface IEntriesAttributeRules {
  attribute_rule_rule_template: IAttributeRule;
}

export interface IStorageEntriesType {
  entity_selected_state?: EEntitySelectedStateType;
  entity_is_selected: boolean;
  entity_name: string;
  entity_name_slug: string;
  entity_schema: string;
  entity_schema_slug?: string;
  id: number;
  attributes: IEntriesAttributeTypes[];
}

export enum ESourceCode {
  NONE = "",
  ORACLE = "ds_oracle",
  MONGO_DB = "ds_mongo",
  GP = "ds_greenplum",
  PG = "ds_postgresql",
  MYSQL = "ds_mysql",
  XLSX = "ds_xlsx",
  MSSQL = "ds_mssqlserver",
}

export interface IStorageType {
  id: number;
  storage_datasource_code: ESourceCode;
  storage_name: string;
  storage_name_slug: string;
  storage_conn_config: IStorageConfigTypes;
  entities: IStorageEntriesType[];
  modelStatus?: string;
  gsDataStatus?: string;
  storage_crawled_at: string;
  storage_import_type?: string;
  storage_import_status?: string;
}

export interface IStorageInitialStateType {
  entries: IStorageType;
  loading: boolean;
  error: string;
}

export interface INodeTreeDataFieldsTypes {
  id: string;
  name: string;
  type: string;
  synchronization: boolean;
}

export interface INodeTreeDataTypes {
  id: string;
  title: string;
  schema: string;
  synchronization: boolean;
  fields: INodeTreeDataFieldsTypes[];
}

export interface INodeTreeTypes {
  id: string;
  type: string;
  position: { x: number; y: number };
  data: INodeTreeDataTypes;
}
