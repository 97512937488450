import { useEffect, useState, useContext } from "react";
import { Col, Row, Select, Drawer, Space } from "antd";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { format } from "sql-formatter";
import SyntaxHighlighter from "react-syntax-highlighter";
import { xcode as sqlStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { ProcessModeContext } from "../../../../providers/process-mode-provider/processMode.provider";
import { AppInput } from "../../../../components/app-input/app-input.component";
import {
  Join,
  TransformationIncomerType,
} from "../../../../store/types/low-code.types";
import { MergeFormSettings } from "./low-code-merge-form-settings.component";
import {
  AppArrowIcon,
  AppInfoIcon,
  AppMergeIcon,
} from "../../../../components/icons";
import { AppButton } from "../../../../components/app-button/app-button.component";
import { AppLogoLoader } from "../../../../components/ui/app-animated-logo/app-animated-logo.component";
import { useAppDispatch } from "../../../../store/hooks";
import { processGenerateSql } from "../../../../api/processes/processes-actions.api";
import { Transformation, Merge, Schema } from "../../schema";
import { nameValidation } from "../../../../helpers/low-code.helper";

import s from "./low-code-merge-modal.module.scss";
import { enrichRf } from "../../process.helper";

const { Option } = Select;

type PropsType = {
  source: TransformationIncomerType[];
  transformation: any;
  onSubmit: any;
  onCancel: any;
  transformNode: any;
  schema: Schema;
  rf: any;
};

export const MergeForm: React.FC<PropsType> = ({
  source: incomers,
  onSubmit,
  onCancel,
  transformNode: node,
  transformation,
  schema,
  rf,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [sql, setSql] = useState<string>("");
  const [loadingSql, setLoadingSql] = useState<boolean>(false);
  const [sqlError, setSqlError] = useState<string>("");
  const [isValidSettings, setIsValidSettings] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [count, setCount] = useState<number>(
    transformation?.settings?.length || 1
  );
  const [joins, setJoins] = useState<Join[]>(
    transformation?.settings?.length
      ? JSON.parse(JSON.stringify(transformation.settings))
      : [{ index: count, isValid: false }]
  );

  const mode = useContext(ProcessModeContext);

  useEffect(() => {
    const formIsValid = joins.every((join) => join.isValid);
    setIsValidSettings(formIsValid);
  }, [joins]);

  useEffect(() => {
    if (openDrawer) {
      let tree: Array<any> = new Merge({
        incomers,
        node,
        joins,
        name,
      }).toObject();

      const parentIncomers: Array<TransformationIncomerType> = incomers.filter(
        ({ type }) => type === "merge"
      );

      parentIncomers.forEach((parentIncomer) => {
        const buildTreePath = (item: Transformation | null | undefined) => {
          if (item?.data) {
            tree = tree.concat(new Merge(item.data).toObject());
          }
        };

        buildTreePath(schema.find(parentIncomer.node.id));
      });

      setLoadingSql(true);
      dispatch(processGenerateSql(enrichRf(tree, rf.toObject())))
        .unwrap()
        .then((res) => {
          setLoadingSql(false);
          setSql(res.data.sql);
        })
        .catch((error) => {
          setLoadingSql(false);
          setSqlError(error.data.data?.message || error.data.code);
          setSql("");
        });
    } else {
      setLoadingSql(false);
      setSqlError("");
      setSql("");
    }
  }, [openDrawer]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid: isValidForm },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name:
        transformation?.name ??
        `${t("page.process.form.step.lowcode.transformation.node.merge")} ${
          node.data.index
        }`,
      leftEntityId: joins[0].left ? String(joins[0].left?.entityId) : null,
    },
  });
  const leftEntityId = watch("leftEntityId");
  const name = watch("name");

  const submitForm = (data) => {
    onSubmit({ ...data, incomers });
    onCancel();
  };

  const drawerTitle = () => (
    <Space onClick={() => setOpenDrawer(false)}>
      <AppArrowIcon side={"left"} width={"16"} height={"16"} />
      <span>{t("page.process.form.step.lowcode.merge.view_sql")}</span>
    </Space>
  );

  const formatSQL = (sql: string) => {
    return format(sql, {
      language: "sql",
      tabWidth: 2,
      keywordCase: "upper",
      linesBetweenQueries: 2,
    });
  };

  const addJoin = () => {
    if (isValidSettings) {
      const maxId = count + 1;
      setCount(maxId);
      setJoins([...joins, { index: maxId, isValid: false }]);
    }
  };

  const removeJoin = (joinId: number) => {
    const newJoins = joins.filter(({ index }) => index !== joinId);
    setJoins(newJoins);
    setCount(newJoins[newJoins.length - 1].index);
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (isValidSettings) {
          submitForm({ ...data, joins, name });
        }
      })}
    >
      <div className={s.header}>
        <Row gutter={16}>
          <Col span={12}>
            <div className={s.textInput}>
              <AppMergeIcon />
              <Controller
                name="name"
                control={control}
                rules={nameValidation(t)}
                render={({ field }) => (
                  <input
                    type="text"
                    disabled={mode === "view"}
                    placeholder={t(
                      "page.process.form.step.lowcode.merge.name_placeholder"
                    )}
                    {...field}
                  />
                )}
              />
            </div>
            <ErrorMessage
              key={"name"}
              name={"name"}
              errors={errors}
              render={({ message }) => (
                <span className={s.inputError}>{message}</span>
              )}
            />
          </Col>
          <Col span={12}>
            <AppInput
              data-testid="select-left-entity"
              control={control}
              showArrow={true}
              showSearch={true}
              allowClear={true}
              disabled={mode === "view"}
              name={"leftEntityId"}
              onChange={(value) => {
                setCount(1);
                setJoins([{ index: 1, isValid: false }]);
                setValue("leftEntityId", value);
              }}
              errors={errors}
              placeholder={t(
                "page.process.form.step.lowcode.merge.select_left_entity_placeholder"
              )}
            >
              {incomers.map(({ storage, entity }) => (
                <Option value={String(entity.id)} key={entity.id}>
                  {entity.entity_name}{" "}
                  {storage.storage_name ? `(${storage.storage_name})` : ""}
                </Option>
              ))}
            </AppInput>
          </Col>
        </Row>
      </div>
      <div className={s.content}>
        {leftEntityId ? (
          <MergeFormSettings
            source={incomers}
            leftEntityId={leftEntityId}
            isValid={isValidSettings}
            joins={joins}
            addJoin={addJoin}
            removeJoin={removeJoin}
            onChange={(joins: Join[]) => {
              const formIsValid = joins.every((join) => join.isValid);
              setIsValidSettings(formIsValid);
              setJoins(joins);
            }}
          />
        ) : (
          <div className={s.noEntityText}>
            <p className={s.subheading}>
              {t("page.process.form.step.lowcode.merge.entity_selection")}
            </p>
            <p>
              {t("page.process.form.step.lowcode.merge.entity_selection.help")}
            </p>
          </div>
        )}
        <Drawer
          title={drawerTitle()}
          placement="right"
          closable={false}
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
          getContainer={false}
          className="merge-sql"
        >
          {loadingSql && (
            <div className={s.drawerAction}>
              <AppLogoLoader loading={true}></AppLogoLoader>
            </div>
          )}
          {sqlError && (
            <div className={s.drawerAction}>
              <div className={s.drawerActionError}>
                <AppInfoIcon sharedStyles={s.drawerActionErrorIcon} />
                <span>{sqlError}</span>
              </div>
            </div>
          )}
          <SyntaxHighlighter
            language="sql"
            style={sqlStyle}
            wrapLongLines={true}
            PreTag="div"
          >
            {formatSQL(sql)}
          </SyntaxHighlighter>
        </Drawer>
      </div>
      <div className={s.footer}>
        <AppButton
          disabled={!isValidSettings || !isValidForm}
          onClick={() => setOpenDrawer(!openDrawer)}
          sharedStyles={s.applyOperatorButton}
        >
          {openDrawer ? (
            <span>{t("page.process.form.step.lowcode.merge.hideSql")}</span>
          ) : (
            <span>{t("page.process.form.step.lowcode.merge.showSql")}</span>
          )}
        </AppButton>
        <AppButton
          disabled={!isValidSettings || !isValidForm || mode === "view"}
          htmlType="submit"
          sharedStyles={s.applyOperatorButton}
        >
          {t("page.process.form.step.lowcode.merge.submit")}
        </AppButton>
      </div>
    </form>
  );
};
