import { createContext } from "react";

export const TransformContext = createContext<{
  onChangeSettings: (nodeId: string) => void;
  onEmitDeleteNode: (nodeId: string) => void;
  onEmitEditNode: (nodeId: string | null) => void;
}>({
  onChangeSettings: () => null,
  onEmitDeleteNode: () => null,
  onEmitEditNode: () => null,
});
