import s from "./app-checkbox.module.scss";

import React, { ReactNode } from "react";
import cn from "classnames";
import { RegisterOptions, Controller, Control } from "react-hook-form";
import { Checkbox, CheckboxProps } from "antd";

interface IInputPropTypes {
  sharedStyles?: string;
  checked?: boolean;
  name: string;
  id?: string;
  rules?: RegisterOptions;
  children: ReactNode[];
  control?: Control<any>;
  direction?: "horizontal" | "vertical";
  defaultValue?: any;
}

export const AppCheckbox: React.FC<IInputPropTypes & CheckboxProps> = ({
  sharedStyles,
  name,
  children,
  checked = undefined,
  control,
  rules,
  id,
  direction,
  defaultValue,
  ...props
}) => {
  return (
    <div className={cn(sharedStyles)}>
      <Controller
        control={control}
        rules={rules}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <Checkbox.Group
            className={cn(s.checkBoxGroup, {
              [s._horizontal]: direction === "horizontal",
              [s._vertical]: direction === "vertical",
            })}
            {...props}
            onChange={onChange}
            value={value}
          >
            {children.map((checkBox, i) => {
              return (
                <span key={i} className={s.checkBoxWrapper}>
                  {checkBox}
                </span>
              );
            })}
          </Checkbox.Group>
        )}
      />
    </div>
  );
};
