import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  EInterval,
  intervalMapper,
} from "../../pages/create-process/process.helper";
import { ScheduleType } from "../../store/types/low-code.types";

type PropTypes = {
  schedule: ScheduleType;
};

export const ScheduleString: FC<PropTypes> = ({ schedule }) => {
  const { t } = useTranslation();

  const scheduleOptions = [
    {
      key: intervalMapper(EInterval.PER_HOUR),
      value: "page.process.form.step.one.schedule.option.hour",
      engKey: "perhour",
    },
    {
      key: intervalMapper(EInterval.PER_DAY),
      value: "page.process.form.step.one.schedule.option.day",
      engKey: "perday",
    },
    {
      key: intervalMapper(EInterval.PER_WEEK),
      value: "page.process.form.step.one.schedule.option.week",
      engKey: "perweek",
    },
    {
      key: intervalMapper(EInterval.PER_MONTH),
      value: "page.process.form.step.one.schedule.option.month",
      engKey: "permonth",
    },
  ];

  const timeType = useMemo(
    () =>
      scheduleOptions.find((item) => item.key === schedule?.interval)?.engKey,
    [schedule]
  );

  const itemsDays = [
    {
      key: "1",
      value: t("form.schedule.days.pn"),
      full: t("form.schedule.days.pn.full"),
    },
    {
      key: "2",
      value: t("form.schedule.days.vt"),
      full: t("form.schedule.days.vt.full"),
    },
    {
      key: "3",
      value: t("form.schedule.days.sr"),
      full: t("form.schedule.days.sr.full"),
    },
    {
      key: "4",
      value: t("form.schedule.days.cht"),
      full: t("form.schedule.days.cht.full"),
    },
    {
      key: "5",
      value: t("form.schedule.days.pt"),
      full: t("form.schedule.days.pt.full"),
    },
    {
      key: "6",
      value: t("form.schedule.days.sb"),
      full: t("form.schedule.days.sb.full"),
    },
    {
      key: "0",
      value: t("form.schedule.days.vs"),
      full: t("form.schedule.days.vs.full"),
    },
  ];

  const { minute, hour, week, month } = schedule;

  const pluralWeek = (key: string) => {
    if ([1, 2, 4].includes(Number(key))) {
      return "form.schedule.each.two";
    }
    if ([3, 5, 6].includes(Number(key))) {
      return "form.schedule.each.one";
    }
    return "form.schedule.each.five";
  };

  const checkNumber = (value: string) => {
    return value.toString().length >= 2 ? value : `0${value}`;
  };

  const findWeek =
    itemsDays.find((day) => day.key === String(week))?.full || "";

  const renderPrompt = useCallback(() => {
    switch (timeType as EInterval) {
      case EInterval.PER_HOUR:
        if (minute) {
          return t("form.schedule.option.result.minutes", {
            0: minute,
            1: t("form.schedule.minute.one"),
          });
        }
        break;
      case EInterval.PER_DAY:
        if (hour && minute) {
          return t("form.schedule.option.result.hours", {
            0: checkNumber(hour),
            1: checkNumber(minute),
          });
        }
        break;
      case EInterval.PER_WEEK:
        if (hour && minute && week) {
          return t("form.schedule.option.result.weeks", {
            0: t(pluralWeek(week)),
            1: findWeek,
            2: checkNumber(hour),
            3: checkNumber(minute),
          });
        }
        break;
      case EInterval.PER_MONTH:
        if (hour && minute && month) {
          return t("form.schedule.option.result.months", {
            0: month,
            1: checkNumber(hour),
            2: checkNumber(minute),
          });
        }
        break;
    }
  }, [timeType, hour, week, month]);

  return <>{renderPrompt()}</>;
};
