import { IAttributeRule } from "../types/attribute-rule.types";
import { RootState } from "../index";

export const selectAttributeRules = (state: RootState): IAttributeRule[] =>
  state.attributeRules.rules;

export const selectAllAttributeRules = (state: RootState) =>
  state.attributeRules;

export const selectSearchRules = (state: RootState): IAttributeRule[] => {
  return state.attributeRules.rules.filter((item) => {
    return item.rule_template_name
      .toLowerCase()
      .includes(state.attributeRules.search.toLowerCase());
  });
};
