import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import "./index.scss";
import App from "./App";

import { Provider } from "react-redux";

import { setupStore } from "./store";
import "intro.js/introjs.css";
import axiosInstance from "./store/axios-instanse";
import "reactflow/dist/style.css";

import { SnackbarProvider } from "notistack";

import "./i18n";
import { AppLogoLoader } from "./components/ui/app-animated-logo/app-animated-logo.component";

fetch("/config.json")
  .then((e) => e.json())
  .then((config) => {
    (window as any).config = config;
    axiosInstance.defaults.baseURL = config.BASE_URL;

    console.log("version:", config.VERSION);

    root.render(
      <Suspense fallback={<AppLogoLoader loading={true} />}>
        <Provider store={setupStore()}>
          <SnackbarProvider
            autoHideDuration={5000}
            hideIconVariant={true}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <App />
          </SnackbarProvider>
        </Provider>
      </Suspense>
    );
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
