import React, { useState, useEffect, memo } from "react";
import { ColumnsType } from "antd/lib/table";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Checkbox, Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import { AttachRuleToAttributeModal } from "./attach-rule-to-attribute-modal/attach-rule-to-attribute-modal.component";
import { selectAttributeRules } from "../../../store/storages/attribute-rules.selector";
import { OriginTableSearch } from "./origin-table-search/origin-table-search.component";
import { UploadFileModal } from "./upload-csv-file-modal/upload-file-modal.component";
import { AppEditAttributeRule } from "./app-edit-attribute-rule/app-edit-attribute-rule.component";
import { IEntriesAttributeTypes } from "../../../store/types/storages.types";
import {
  fetchStorageAttributes,
  fetchStorageAttributesSearch,
} from "../../../api/storages/storages-acitons.api";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { getAttributesCount } from "../../../store/storages/storage-entity-attributes.selector";
import { storageEntityAttributesSelectors } from "../../../store/storages/storage-entity-attributes.selector";
import { EEntitySelectedStateType } from "../../../store/types/storages.types";

import s from "./app-origin-table.module.scss";

const FIRST_PAGE = 1;
interface IAppOriginTableProps {
  entity: any;
  entityType: string;
  storageId: number;
  modifiedAttributes: IEntriesAttributeTypes[];
  disableStorageActions: boolean;
  isWholeEntitySelected: EEntitySelectedStateType;
  onEntitySelected: (
    entityIsSelected: boolean,
    attributes: IEntriesAttributeTypes[]
  ) => void;
  onAttributeSelected: (
    id: number,
    changes: Partial<IEntriesAttributeTypes>
  ) => void;
  setIsWholeEntitySelected: (EEntitySelectedStateType) => void;
  isLoader: boolean;
  activateLoader: (value: boolean) => void;
}
export const AppOriginTable: React.FC<IAppOriginTableProps> = memo(
  ({
    entity,
    entityType,
    storageId,
    modifiedAttributes,
    disableStorageActions,
    isWholeEntitySelected,
    onEntitySelected = () => null,
    onAttributeSelected = () => null,
    setIsWholeEntitySelected,
    isLoader,
    activateLoader,
  }) => {
    const { reset } = useForm({
      mode: "onChange",
      defaultValues: {
        search: "",
      },
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const [query] = useSearchParams();
    const entityId = Number(query.get("tab"));

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const dataRules = useAppSelector(selectAttributeRules);
    const attributesCount = useAppSelector((state) =>
      getAttributesCount(state)
    );
    const storageEntityAttributes = useAppSelector((state) =>
      storageEntityAttributesSelectors.selectAll(state)
    );

    const [search, setSearch] = useState<string>("");
    const [showUploadCsvModal, setShowUploadCsvModal] = useState(false);
    const [showAttributeRuleModal, setShowAttributeRuleModal] = useState(false);
    const [selectedAttribute, setSelectedAttribute] =
      useState<IEntriesAttributeTypes | null>(null);

    useEffect(() => {
      dispatch(
        fetchStorageAttributes({
          storageId,
          entityId,
          offset: Number(query.get("page")) - 1,
        })
      )
        .unwrap()
        .then((data) => {
          setIsWholeEntitySelected(data.results.entity_selected_state);
        });
    }, []);

    const attributes = storageEntityAttributes.map(
      (attr) => modifiedAttributes.find((item) => item?.id == attr.id) || attr
    );

    function handleShowUploadCsvModal(): void {
      setShowUploadCsvModal(true);
    }

    const handleCancelUploadCsvModal = () => {
      setSelectedAttribute(null);
      setShowUploadCsvModal(false);
    };

    const handleEntitySelectedChange = (isChecked, attributes) => {
      const filteredAttributeList = attributes.filter(
        (item) => item.is_type_supported
      );
      reset();
      onEntitySelected(isChecked, filteredAttributeList);
    };

    const selectAllAttributes = (event: CheckboxChangeEvent) => {
      handleEntitySelectedChange(event.target.checked, storageEntityAttributes);
    };

    const getAttributeRuleNames = (ruleId: string) => {
      const findedRule = dataRules.find(
        (rule) => String(rule.id) === String(ruleId)
      );
      return findedRule ? findedRule.rule_template_name : "";
    };
    const setAttributeRule = (attr: IEntriesAttributeTypes) => {
      setSelectedAttribute(attr);
      setShowAttributeRuleModal(true);
    };
    const handleCancelAttributeRule = () => {
      setSelectedAttribute(null);
      setShowAttributeRuleModal(false);
    };

    const columns: ColumnsType<any> = [
      {
        width: "30%",
        title: (
          <div className={s.rowWrapper}>
            <Checkbox
              disabled={disableStorageActions}
              checked={isWholeEntitySelected === "full"}
              onChange={selectAllAttributes}
            >
              <p className={s.tableTitle}>
                {t("page.origin.table.choose.all.attributes")}
              </p>
            </Checkbox>
          </div>
        ),
        key: "action",
        render: (_, record) => {
          const handleChange = (event: CheckboxChangeEvent) => {
            onAttributeSelected(record.id, {
              attribute_is_selected: event.target.checked,
            });
          };

          return (
            <div className={s.rowWrapper}>
              <div className={s.originSync}>
                <Checkbox
                  disabled={disableStorageActions || !record.is_type_supported}
                  checked={record.attribute_is_selected}
                  onChange={handleChange}
                />
                <div className={s.originAttr}>
                  <span>{t("source.detail.card.table.head.attribute")}:</span>
                  <p>{record.attribute_name}</p>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div className={s.rowWrapper}>
            <p className={s.tableTitle}>
              {t("source.detail.card.table.head.type")}
            </p>
          </div>
        ),
        dataIndex: "type",
        key: "type",
        render: (_, { attribute_type }) => (
          <div className={s.rowWrapper}>
            <p className={s.tableContent}>{attribute_type}</p>
          </div>
        ),
      },
      {
        title: (
          <div className={s.rowWrapper}>
            <Tooltip title="null/not null" color={"var(--white)"}>
              <p className={s.tableTitle}>
                {t("source.detail.card.table.head.n")}
              </p>
            </Tooltip>
          </div>
        ),
        dataIndex: "type",
        key: "type",
        render: (_, { attribute_is_nullable }) => (
          <div className={s.rowWrapper}>
            <p className={s.tableContent}>
              {t(
                attribute_is_nullable
                  ? "source.detail.card.table.head.n.yes"
                  : "source.detail.card.table.head.n.no"
              )}
            </p>
          </div>
        ),
      },
      {
        /* comment in DLH-2816 */
      },
      // {
      //   title: (
      //     <div className={s.rowWrapper}>
      //       <p className={s.tableTitle}>
      //         {t("source.detail.card.table.head.rules")}
      //       </p>
      //     </div>
      //   ),
      //   dataIndex: "type",
      //   key: "type",
      //   width: "30%",
      //   render: (_, record) => {
      //     return (
      //       <>
      //         <AppEditAttributeRule
      //           record={record}
      //           onSetAttributeRule={setAttributeRule}
      //           onGetAttrNames={getAttributeRuleNames}
      //         />
      //       </>
      //     );
      //   },
      // },
    ];

    useEffect(() => {
      if (!searchParams.get("page")) {
        searchParams.set("page", String(FIRST_PAGE));
        setSearchParams(searchParams);
      }
    }, []);

    let fileFormat;
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (entityType) {
      case "ds_xlsx":
        fileFormat = "xlsx";
        break;
      default:
        fileFormat = "csv";
    }

    const fetchAttributesData = (page) => {
      if (search.length > 0) {
        dispatch(
          fetchStorageAttributesSearch({
            storageId,
            entityId,
            search,
            offset: page - 1,
          })
        )
          .unwrap()
          .then(() => {
            activateLoader(false);
          });
        return;
      }
      dispatch(
        fetchStorageAttributes({
          storageId,
          entityId: entity.id,
          offset: page - 1,
        })
      )
        .unwrap()
        .then(({ results }) => {
          if (isWholeEntitySelected === "partial") return;
          // при переключении пагинации чтоб отображались еще несохраненные состояния чекбоксов:"выбраться все" и атрибутов
          const isChecked = isWholeEntitySelected === "full";
          handleEntitySelectedChange(isChecked, results.attributes);
        });
    };

    return (
      <div className={s.originTable}>
        <OriginTableSearch
          storageId={storageId}
          entityId={entity?.id}
          onShowUploadCsvModal={handleShowUploadCsvModal}
          fileFormat={fileFormat}
          activateLoader={activateLoader}
          isLoader={isLoader}
          changeSearch={(value: string) => {
            setSearch(value);
          }}
          search={search}
        />

        {attributes.length ? (
          <div className={s.originListWrapper}>
            <div className={s.originList} id={"table-settings"}>
              <Table
                bordered
                size="small"
                rowKey={(fieldkey) => fieldkey.id}
                columns={columns}
                dataSource={attributes}
                loading={isLoader}
                pagination={{
                  total: attributesCount,
                  onChange: (page) => {
                    searchParams.set("page", String(page));
                    setSearchParams(searchParams);
                    fetchAttributesData(page);
                  },
                  showSizeChanger: false,
                  pageSize: 50,
                  defaultCurrent: FIRST_PAGE,
                  current: Number(searchParams.get("page")),
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <UploadFileModal
          entity={entity}
          storageId={Number(storageId)}
          open={showUploadCsvModal}
          onCancel={handleCancelUploadCsvModal}
          fileFormat={fileFormat}
        />

        <AttachRuleToAttributeModal
          attribute={selectedAttribute}
          open={showAttributeRuleModal}
          onCancel={handleCancelAttributeRule}
        />
      </div>
    );
  }
);
