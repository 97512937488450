import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu } from "antd";
import cn from "classnames";

import { AppDotMenuIcon } from "../../icons/app-dot-menu.icon";

import s from "./app-card.module.scss";

type PropTypes = {
  id?: string;
  actions?: React.ReactNode[];
  children?: React.ReactNode;
  isError?: boolean;
  onClick?: () => void;
};

export const CardTitle: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={cn(s.row, s.rowContainer, s.header, className)}>
      {children}
    </div>
  );
};

export const CardBody: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={cn(s.rowContainer, s.cardBody, className)}>{children}</div>
  );
};

export const CardLabelBox: React.FC<{
  label: string | ReactNode;
  className?: string;
  children?: React.ReactNode;
}> = ({ label, className, children }) => {
  return (
    <div className={cn(className)}>
      <div className={s.boxLabel}>{label}</div>
      <div className={s.boxContent}>{children}</div>
    </div>
  );
};

export const CardMenuItem: React.FC<{
  icon: React.ReactNode;
  text: string | React.ReactNode;
  id?: string;
  className?: string;
  isDanger?: boolean;
  isPrimary?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}> = ({
  icon,
  text,
  id,
  className,
  isDanger = false,
  isPrimary = false,
  isDisabled = false,
  onClick = () => null,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    return isDisabled ? e.stopPropagation() : onClick();
  };
  return (
    <div
      id={id}
      className={cn(
        s.actionItem,
        { [s._danger]: isDanger },
        { [s._disabled]: isDisabled },
        { [s._primary]: isPrimary },
        className
      )}
      onClick={handleClick}
    >
      {icon}
      {text}
    </div>
  );
};

export const Card: React.FC<PropTypes> = ({
  id,
  actions,
  children,
  isError = false,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <div id={id} className={cn(s.card, { [s._danger]: isError })}>
      <div className={s.cardContentWithTitle} onClick={onClick}>
        {children}
      </div>
      {Boolean(actions?.length) && (
        <div className={cn(s.row, s.rowContainer, s.actions)}>
          <div className="">{t("page.process.card.actions")}</div>

          <Dropdown
            overlay={
              <Menu
                items={actions?.map((component, index) => ({
                  key: index,
                  label: component,
                }))}
              />
            }
            placement="bottomRight"
          >
            <button className={s.actionBtn}>
              <AppDotMenuIcon />
            </button>
          </Dropdown>
        </div>
      )}
    </div>
  );
};
