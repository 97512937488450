import { Modal, Tag } from "antd";
import { getConnectedEdges } from "reactflow";
import { useTranslation } from "react-i18next";
import type { Node } from "reactflow";

import { useAppSelector } from "../../../../store/hooks";
import { TransformationIncomerType } from "../../../../store/types/low-code.types";
import { GroupForm } from "./low-code-group-form.component";

const storageSourceAdapter = (
  node,
  entityId,
  data
): TransformationIncomerType => {
  const storage = data[node.id];

  const entity = storage.entities.find(
    (entity) => Number(entity.id) === Number(entityId)
  );

  return { storage, entity, node, type: "storage" };
};

const processSourceAdapter = (node: Node): TransformationIncomerType => {
  return {
    node,
    entity: node.data.entity,
    storage: node.data.entity.entity_storage,
    type: "process",
  };
};

export const LowCodeGroupModal = ({
  rf,
  schema,
  transformNode: node,
  transformation,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const lowCodeState = useAppSelector((state) => state.lowCode);

  if (!node) return null;

  const edges = getConnectedEdges([node], rf.getEdges());

  const incomers: TransformationIncomerType[] = edges
    .filter(({ target }) => target === node.id)
    .map((edge) => {
      const node = rf.getNode(edge.source);

      if (node.type === "storageNode") {
        return storageSourceAdapter(
          node,
          edge.sourceHandle,
          lowCodeState.storages.entities
        );
      } else if (node.type === "processNode") {
        return processSourceAdapter(node);
      } else if (node.type === "mergeTransformNode") {
        return schema.getResult(node.id);
      }
    });

  if (
    edges.filter(({ target }) => target === node.id).length !==
    incomers.filter((item) => item).length
  ) {
    return null;
  }

  return (
    <Modal
      destroyOnClose={true}
      open={Boolean(node)}
      footer={false}
      width={1692}
      onCancel={onCancel}
      wrapClassName="group-modal"
      title={
        <div>
          {t("page.process.form.step.lowcode.transformation.node.group")}
          <Tag>{node?.data.index}</Tag>
        </div>
      }
    >
      <GroupForm
        incomers={incomers}
        transformation={transformation}
        node={node}
        onSubmit={(data) => {
          onSubmit({ ...data, node: node, incomers });
        }}
        onCancel={onCancel}
      />
    </Modal>
  );
};
