import React from "react";

type AppPlayIconProps = {
  width?: string;
  height?: string;
  fill?: string;
};

export const AppPlayIcon: React.FC<AppPlayIconProps> = ({
  width = "24",
  height = "24",
  fill = "none",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0415 12.8059L18.0415 12.8059L18.0385 12.8077L6.90396 19.3702C6.28865 19.7325 5.5 19.2927 5.5 18.5621V5.43686C5.5 4.7075 6.28725 4.26697 6.90371 4.63042L6.90377 4.63045L18.0385 11.1931L18.0385 11.1931L18.0415 11.1949C18.1796 11.2749 18.2953 11.3912 18.3762 11.5327L18.8103 11.2846L18.3762 11.5327C18.4571 11.6743 18.5 11.8356 18.5 12.0004C18.5 12.1651 18.4571 12.3265 18.3762 12.4681C18.2953 12.6096 18.1796 12.7258 18.0415 12.8059Z"
        stroke="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
