import React, { SVGAttributes } from "react";

export const AppDLHLogoText: React.FC<SVGAttributes<any>> = () => {
  return (
    <svg
      width="54"
      height="22"
      viewBox="0 0 54 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.856079 21.0254V0.915039H7.54063C9.6744 0.915039 11.44 1.36823 12.8373 2.27461C14.2535 3.16211 15.311 4.37061 16.0096 5.90013C16.7272 7.41076 17.086 9.09134 17.086 10.9419C17.086 12.9812 16.6988 14.7562 15.9246 16.2669C15.1505 17.7775 14.0458 18.9482 12.6107 19.7791C11.1756 20.6099 9.48557 21.0254 7.54063 21.0254H0.856079ZM15.6414 10.9419C15.6414 9.26129 15.3204 7.7601 14.6784 6.43829C14.0552 5.11649 13.1394 4.08737 11.9309 3.35094C10.7413 2.59562 9.27786 2.21796 7.54063 2.21796H2.30062V19.7224H7.54063C9.29675 19.7224 10.7791 19.3353 11.9876 18.5611C13.1961 17.7869 14.1024 16.7389 14.7067 15.4171C15.3298 14.0764 15.6414 12.5847 15.6414 10.9419Z"
        fill="#2E3192"
      />
      <path
        d="M21.0483 21.0254V0.915039H22.4928V19.7224H34.5023V21.0254H21.0483Z"
        fill="#2E3192"
      />
      <path
        d="M53.0847 0.915039V21.0254H51.6401V11.3951H38.9508V21.0254H37.5063V0.915039H38.9508V10.0921H51.6401V0.915039H53.0847Z"
        fill="#2E3192"
      />
    </svg>
  );
};
