import React from "react";

export const AppSyncIcon: React.FC<{
  width?: string;
  height?: string;
  sharedStyles?: string;
}> = ({ width = "24", height = "24", sharedStyles }) => {
  return (
    <svg
      className={sharedStyles}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.77376 7.5H10.2V9.3H3.9V3H5.7V5.94331C7.2526 4.06426 9.47619 3 12 3C16.9706 3 21 7.02944 21 12C19.2 12 21 12 19.2 12C19.2 8.02355 15.9764 4.8 12 4.8C9.84574 4.8 7.99819 5.77083 6.77376 7.5ZM17.2262 16.5H13.8V14.7H20.1V21H18.3V18.0567C16.7474 19.9357 14.5238 21 12 21C7.02944 21 3 16.9706 3 12H4.8C4.8 15.9764 8.02355 19.2 12 19.2C14.1543 19.2 16.0018 18.2292 17.2262 16.5Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
