import React from "react";

type AppImportIconProps = {
  width?: string;
  height?: string;
};

export const AppUnarchiveIcon: React.FC<AppImportIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.02794 7.34078L11.2626 5.13408V12.0614C11.2626 12.2096 11.3214 12.3517 11.4262 12.4565C11.531 12.5612 11.6731 12.6201 11.8212 12.6201C11.9694 12.6201 12.1115 12.5612 12.2163 12.4565C12.321 12.3517 12.3799 12.2096 12.3799 12.0614V5.13408L14.6145 7.34078C14.6666 7.3925 14.7284 7.43346 14.7963 7.46131C14.8642 7.48916 14.937 7.50336 15.0104 7.5031C15.0838 7.50284 15.1564 7.48813 15.2241 7.4598C15.2918 7.43147 15.3533 7.39008 15.405 7.33799C15.4568 7.2859 15.4977 7.22413 15.5256 7.15622C15.5534 7.0883 15.5676 7.01556 15.5674 6.94216C15.5671 6.86875 15.5524 6.79612 15.5241 6.7284C15.4957 6.66068 15.4543 6.59921 15.4022 6.54748L11.8212 3L8.25141 6.54748C8.16489 6.65396 8.12058 6.78854 8.12691 6.92558C8.13324 7.06262 8.18978 7.19254 8.28575 7.29058C8.38172 7.38862 8.5104 7.44792 8.64728 7.45718C8.78415 7.46643 8.91965 7.425 9.02794 7.34078ZM5.11732 12.0615H10.1452C10.1477 12.4749 10.3029 12.8728 10.581 13.1788H4V9.54749C4 9.32524 4.08829 9.11209 4.24544 8.95494C4.40259 8.79779 4.61574 8.7095 4.83799 8.7095H10.1452V9.82682H5.11732V12.0615ZM13.4972 8.7095H18.8045C19.0267 8.7095 19.2399 8.79779 19.397 8.95494C19.5542 9.11209 19.6425 9.32524 19.6425 9.54749V13.1788H13.0615C13.3396 12.8728 13.4948 12.4749 13.4972 12.0615H18.5252V9.82682H13.4972V8.7095ZM17.9665 19.8827H5.67605V14.2961H4.55873V19.8827C4.55873 20.179 4.67644 20.4632 4.88598 20.6727C5.09552 20.8823 5.37971 21 5.67605 21H17.9665C18.2629 21 18.5471 20.8823 18.7566 20.6727C18.9661 20.4632 19.0839 20.179 19.0839 19.8827V14.2961H17.9665V19.8827ZM9.58667 15.4134C9.58667 15.5616 9.64553 15.7037 9.7503 15.8084C9.85507 15.9132 9.99716 15.9721 10.1453 15.9721H13.4973C13.6454 15.9721 13.7875 15.9132 13.8923 15.8084C13.9971 15.7037 14.0559 15.5616 14.0559 15.4134C14.0559 15.2652 13.9971 15.1231 13.8923 15.0184C13.7875 14.9136 13.6454 14.8547 13.4973 14.8547H10.1453C9.99716 14.8547 9.85507 14.9136 9.7503 15.0184C9.64553 15.1231 9.58667 15.2652 9.58667 15.4134Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
