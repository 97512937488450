import React from "react";

type AppCopyIconProps = {
  width?: string;
  height?: string;
};

export const AppCopyIcon: React.FC<AppCopyIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.88235 21C5.36471 21 4.92141 20.8236 4.55247 20.4708C4.18353 20.118 3.99937 19.6944 4 19.2V6.6H5.88235V19.2H16.2353V21H5.88235ZM9.64706 17.4C9.12941 17.4 8.68612 17.2236 8.31718 16.8708C7.94824 16.518 7.76408 16.0944 7.76471 15.6V4.8C7.76471 4.305 7.94918 3.8811 8.31812 3.5283C8.68706 3.1755 9.13004 2.9994 9.64706 3H18.1176C18.6353 3 19.0786 3.1764 19.4475 3.5292C19.8165 3.882 20.0006 4.3056 20 4.8V15.6C20 16.095 19.8155 16.5189 19.4466 16.8717C19.0776 17.2245 18.6347 17.4006 18.1176 17.4H9.64706ZM9.64706 15.6H18.1176V4.8H9.64706V15.6Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
