import { Control, Controller, RegisterOptions } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import React, { FC } from "react";
import s from "./app-tab-calendar.module.scss";
import { Radio } from "antd";
import cn from "classnames";

interface IAppTabCalendarProps {
  items: { key: string; value: string; full?: string }[];
  control: Control<any>;
  errors?: FieldErrors;
  rules?: RegisterOptions;
  name: string;
  sharedStyles?: string;
  label?: string;
  selectedItem?: string | null;
  disabled?: boolean;
}

export const AppTabCalendar: FC<IAppTabCalendarProps> = ({
  control,
  items,
  errors,
  rules,
  name,
  sharedStyles,
  label,
  selectedItem,
  disabled = false,
}) => {
  return (
    <div className={cn(s.radioCalendar, sharedStyles)}>
      {label && <span className={s.radioCalendarLabel}>{label}</span>}
      <Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Radio.Group
            className={s.radioGroup}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            defaultValue={String(selectedItem)}
          >
            <span className={s.radioWrapper}>
              {items?.map((element, i) => {
                const { key, value } = element;
                return (
                  <Radio.Button
                    key={key}
                    className={s.radioButton}
                    value={key}
                    disabled={disabled}
                  >
                    {value}
                  </Radio.Button>
                );
              })}
            </span>
          </Radio.Group>
        )}
        name={name}
      />
    </div>
  );
};
