import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";

import { storageEntitiesSelectors } from "../../../../store/storages/storage-entities.selector";
import { useAppSelector } from "../../../../store/hooks";
import { AppArrowIcon } from "../../../icons/app-arrow.icon";
import { AppConnectionIcon } from "../../../icons/app-connection.icon";

import s from "./app-origin-tab.module.scss";

interface AppOriginTabProps {
  onSetActive: (id: number) => void;
  tabId: number;
  selectedId: number;
  name: string;
  schemaName: string;
  isSync: boolean;
  isUnsaved: boolean;
  toScroll: (offsetY?: number) => void;
}

export const AppOriginTab: React.FC<AppOriginTabProps> = ({
  tabId,
  onSetActive,
  name,
  schemaName,
  selectedId,
  isSync,
  toScroll,
}) => {
  const tabRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [query] = useSearchParams();

  const entity = useAppSelector((state) =>
    storageEntitiesSelectors.selectById(state, tabId)
  );

  useEffect(() => {
    if (selectedId === tabId) {
      const offsetY = tabRef.current?.getBoundingClientRect().y;
      toScroll(offsetY);
    }
  }, [tabRef]);
  return (
    <div
      ref={tabRef}
      className={cn(s.originTab, {
        [s._active]: selectedId === tabId,
        [s._unsaved]: entity?.entity_is_selected,
      })}
      onClick={() => onSetActive(+tabId)}
    >
      <div className={s.tabTitles}>
        <h3>{name}</h3>
        <div className={s.tabSubtitle}>
          {t("page.process.form.step.three.tree.theme")}:&nbsp;
          <span>{schemaName}</span>
        </div>
      </div>

      <div className={s.tabIcons}>
        {isSync ? <AppConnectionIcon className={s.iconSync} /> : null}
        <AppArrowIcon className={s.icon} side={"right"} />
      </div>
    </div>
  );
};
