export const truncateText = (
  str: string,
  num: number
): { text: string; isTruncated: boolean } => {
  const result = {
    text: "",
    isTruncated: false,
  };

  if (str.length > num) {
    result.text = str.slice(0, num) + "...";
    result.isTruncated = true;
  } else {
    result.text = str;
    result.isTruncated = false;
  }
  return result;
};
