import { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

export const AppStartIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.59997 14.52L3.71997 13.8C3.71997 13.8 3.71997 11.64 4.43997 9.48C5.15997 7.32 10.2 7.32 10.2 7.32M6.59997 14.52L9.47997 17.4M6.59997 14.52C6.59997 14.52 9.47997 7.32 13.08 5.16C16.68 3 21 3 21 3C21 3 21 7.32 18.84 10.92C16.68 14.52 9.47997 17.4 9.47997 17.4M9.47997 17.4L10.2 20.28C10.2 20.28 12.36 20.28 14.52 19.56C16.68 18.84 16.68 13.8 16.68 13.8"
        stroke="var(--app-icon-color, #737373)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 21L5.88 19.56L4.44 18.12L3 21Z"
        fill="black"
        stroke="var(--app-icon-color, #737373)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.24 9.47999C15.6377 9.47999 15.96 9.15764 15.96 8.75999C15.96 8.36235 15.6377 8.03999 15.24 8.03999C14.8424 8.03999 14.52 8.36235 14.52 8.75999C14.52 9.15764 14.8424 9.47999 15.24 9.47999Z"
        fill="black"
        stroke="var(--app-icon-color, #737373)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
