import { Table, Tabs, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "./low-code-entity-table.module.scss";
import {
  IStorageEntriesType,
  IStorageType,
} from "../../../../store/types/storages.types";
import { AppSmallCrossIcon } from "../../../../components/icons/app-small-cross.icon";
import { DragSizing } from "../../../../components/app-drag-element/app-drag-element.component";

type PropTypes = {
  node: IStorageType;
  containerHeight?: number;
  onClose?: () => void;
  initialHeight?: number | undefined;
  handleDragHeight?: (tableHeight: number | undefined) => void;
};

export const LowCodeEntityTable: React.FC<PropTypes> = ({
  node,
  containerHeight,
  onClose,
  initialHeight,
  handleDragHeight,
}) => {
  const { t } = useTranslation();
  const [activeEntity, setActiveEntity] = useState<IStorageEntriesType | null>(
    null
  );
  const tableRef = useRef<HTMLDivElement>(null);
  const [dragHeight, setDragHeight] = useState<number | undefined>();
  useEffect(() => {
    return () => {
      if (dragHeight) {
        handleDragHeight && handleDragHeight(dragHeight);
      }
    };
  }, [dragHeight]);

  const handleTabChange = (activeKey: string) => {
    const fineded = node.entities.find(
      (entity) => Number(entity.id) === Number(activeKey)
    );

    setActiveEntity(fineded || null);
  };

  useEffect(() => {
    setActiveEntity(node.entities[0]);
  }, [node]);

  const tabsItems = node.entities.map((entity) => ({
    label: <span className={s.tab}>{entity.entity_name}</span>,
    key: `${entity.id}`,
  }));

  const columns: ColumnsType<any> = [
    {
      width: "30%",
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>
            {t("source.detail.card.table.head.attribute")}
          </p>
        </div>
      ),
      key: "action",
      render: (_, record) => {
        return (
          <div className={s.rowWrapper}>
            <div className={s.tableTitle}>{record.attribute_name}</div>
          </div>
        );
      },
    },
    {
      title: (
        <div className={s.rowWrapper}>
          <p className={s.tableTitle}>
            {t("source.detail.card.table.head.type")}
          </p>
        </div>
      ),
      dataIndex: "type",
      key: "type",
      width: "15%",
      render: (_, { attribute_type }) => (
        <div className={s.rowWrapper}>
          <p className={s.tableContent}>{attribute_type}</p>
        </div>
      ),
    },
    {
      title: (
        <div className={s.rowWrapper}>
          <Tooltip title="null/not null" color={"var(--white)"}>
            <p className={s.tableTitle}>
              {t("source.detail.card.table.head.n")}
            </p>
          </Tooltip>
        </div>
      ),
      dataIndex: "type",
      key: "type",
      width: "15%",
      render: (_, { attribute_is_nullable }) => (
        <div className={s.rowWrapper}>
          <p className={s.tableContent}>
            {t(
              attribute_is_nullable
                ? "source.detail.card.table.head.n.yes"
                : "source.detail.card.table.head.n.no"
            )}
          </p>
        </div>
      ),
    },
    // {
    //   title: (
    //     <div className={s.rowWrapper}>
    //       <p className={s.tableTitle}>
    //         {t("source.detail.card.table.head.rules")}
    //       </p>
    //     </div>
    //   ),
    //   dataIndex: "type",
    //   key: "type",
    //   render: (_, record) => {
    //     return <>-</>;
    //   },
    // },
  ];

  const addStyle = initialHeight ? { height: initialHeight } : {};

  if (!activeEntity) return null;

  return (
    <DragSizing
      border="top"
      handlerOffset={-25}
      showCurrentHeight={setDragHeight}
      style={{
        minHeight: (containerHeight && containerHeight - 450) || 150,
        maxHeight: (containerHeight && containerHeight - 100) || 500,
        ...addStyle,
      }}
      handlerClassName={s.dragZone}
    >
      <div className={s.footerTable} ref={tableRef} {...addStyle}>
        <AppSmallCrossIcon
          sharedStyles={s.footerTableClose}
          width={"16"}
          height={"16"}
          onClick={onClose}
        />
        <Tabs
          size="small"
          tabBarGutter={16}
          activeKey={`${activeEntity.id}`}
          items={tabsItems}
          onChange={handleTabChange}
        />

        {activeEntity.attributes.length ? (
          <div className={s.table}>
            <div>
              <Table
                bordered
                size="small"
                rowKey={(fieldkey) => fieldkey.id}
                columns={columns}
                pagination={false}
                dataSource={activeEntity.attributes}
              />
            </div>
          </div>
        ) : (
          <div>{t("page.process.form.step.lowcode.attributes.not.found")}</div>
        )}
      </div>
    </DragSizing>
  );
};
