import axios from "../../store/axios-instanse";
import { showMessage } from "../../store/snackbar/snackbar.slice";
import { ESnackbarState } from "../../store/types/snackbar.types";
import { normalize, schema, denormalize } from "normalizr";

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IEntriesAttributeTypes,
  IStorageEntriesType,
  IStorageType,
} from "../../store/types/storages.types";
import { RootState } from "../../store";
import { getAttributeStatus } from "../notifications/notifications-actions.api";
import { ICreateRule } from "../../store/types/attribute-rule.types";

export const attributeEntity = new schema.Entity("attributes");
export const entitySchema = new schema.Entity("entities", {
  attributes: [attributeEntity],
});
export const storageSchema = new schema.Entity("storage", {
  entities: [entitySchema],
});

/**
 * @description Нормализация данных, для явного разделения по слоям и связи по ссылкам
 */
export const normalizedStorage = (rawStorage: any) => {
  const normalized = normalize<
    any,
    {
      storage: { [key: string]: IStorageType };
      entities: { [key: string]: IStorageEntriesType };
      attributes: { [key: string]: IEntriesAttributeTypes };
    }
  >(rawStorage, storageSchema);

  return normalized.entities;
};

export const fetchStorageById = createAsyncThunk(
  "fetchStorageById",
  async (id: any) => {
    const response = await axios.get(`/dlh-backend/storages/${id}/`);
    return normalizedStorage(response.data.data);
  }
);

export const fetchStorageEntities = createAsyncThunk(
  "fetchStorageEntities",
  async ({ storageId, offset = 0 }: any) => {
    const limit = 50;
    const response = await axios.get(
      `/dlh-backend/storages/${storageId}/entities/?&limit=${limit}&offset=${
        limit * offset
      }`
    );

    return {
      entities: response.data.results.entities,
      count: response.data.count,
      storageSelected: response.data.results.storage_selected,
    };
  }
);

export const fetchStorageEntitiesSearch = createAsyncThunk(
  "fetchStorageEntities",
  async ({ storageId, search, offset }: any) => {
    const limit = 50;
    const response = await axios.get(
      `/dlh-backend/storages/${storageId}/entities/?&limit=${limit}&offset=${
        limit * offset
      }&search=${search}`
    );

    return {
      entities: response.data.results.entities,
      count: response.data.count,
    };
  }
);

export const fetchStorageAttributes = createAsyncThunk(
  "fetchStorageAttributes",
  async ({ storageId, entityId, offset = 0 }: any) => {
    const limit = 50;
    const response = await axios.get(
      `/dlh-backend/storages/${storageId}/entities_attributes/?entity_id=${entityId}&limit=${limit}&offset=${
        limit * offset
      }`
    );

    return response.data;
  }
);
export const fetchStorageAttributesSearch = createAsyncThunk(
  "fetchStorageAttributes",
  async ({ storageId, entityId, search, offset }: any) => {
    const limit = 50;
    const response = await axios.get(
      `/dlh-backend/storages/${storageId}/entities_attributes/?entity_id=${entityId}&limit=${limit}&offset=${
        limit * offset
      }&search=${search}`
    );

    return response.data;
  }
);

export const fetchStorageForProcess = createAsyncThunk(
  "fetchStorageForProcess",
  async () => {
    const response = await axios.get(
      "/dlh-backend/storages/get_list_for_process/"
    );
    return response.data;
  }
);

export const saveStoragesSelect = createAsyncThunk(
  "saveStoragesSelect",
  async (
    {
      storageId,
      entity,
    }: {
      storageId: number;
      entity: IStorageEntriesType;
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    const data = {
      storage_id: storageId,
      entities: [entity],
    };

    try {
      await axios({
        method: "POST",
        url: "/dlh-backend/raw/select/",
        data,
      });
      dispatch(getAttributeStatus(true));
      dispatch(
        showMessage({
          message: "source.detail.notification.success.update",
          type: ESnackbarState.SUCCESS,
        })
      );
      return data;
    } catch (error) {
      dispatch(
        showMessage({
          message: "source.detail.notification.error.update",
          type: ESnackbarState.ERROR,
        })
      );
      return rejectWithValue(error.response.status);
    }
  }
);

export const uploadEntityFile = createAsyncThunk(
  "uploadEntityFile",
  async (
    data: {
      storage_id: number;
      entity_id: number;
      truncate: boolean;
      file: FormDataEntryValue | null;
      fileFormat;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "/dlh-backend/raw/file/",
        data,
      });
      let message;
      if (data.fileFormat === "xlsx") {
        message = "notification.file.xlsx.is.being.proccessing";
      } else {
        message =
          "source.detail.card.actions.actions_button.upload_csv.send.success";
      }
      dispatch(
        showMessage({
          message,
          type: ESnackbarState.SUCCESS,
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        showMessage({
          message:
            "source.detail.card.actions.actions_button.upload_csv.send.error",
          type: ESnackbarState.ERROR,
        })
      );
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateModelAction = createAsyncThunk(
  "updateModelAction",
  async (storageId: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `/dlh-backend/storage/${storageId}/update_model/`
      );
      dispatch(
        showMessage({
          message: "page.source.notification.update_model",
          type: ESnackbarState.SUCCESS,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(
        showMessage({
          message: "page.source.notification.update_model.fail",
          type: ESnackbarState.ERROR,
        })
      );
      return rejectWithValue(error.response.status);
    }
  }
);

export const fetchGSData = createAsyncThunk(
  "fetchGSData",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `/dlh-backend/storage/${id}/onetime_start/`,
        {
          storage_id: id,
        }
      );
      dispatch(fetchStorageById(id));
      dispatch(
        showMessage({
          message: "source.detail.notification.success.update",
          type: ESnackbarState.SUCCESS,
        })
      );

      return response.data;
    } catch (error) {
      dispatch(
        showMessage({
          message: "source.detail.notification.error.update",
          type: ESnackbarState.ERROR,
        })
      );
      return rejectWithValue(error.response.status);
    }
  }
);

export const startCdcImport = createAsyncThunk(
  "startCdcImport",
  async (storageId: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/dlh-backend/storage/${storageId}/cdc_start/`,
      });
      dispatch(
        showMessage({
          message: "source.detail.card.head.one_time_data_import.send.success",
          type: ESnackbarState.SUCCESS,
        })
      );
      dispatch(getAttributeStatus(true));
      return response.data;
    } catch (error) {
      dispatch(
        showMessage({
          message: "source.detail.card.head.one_time_data_import.send.error",
          type: ESnackbarState.ERROR,
        })
      );
      return rejectWithValue(error.response.status);
    }
  }
);

export const stopCdcImport = createAsyncThunk(
  "stopCdcImport",
  async (storageId: number) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/dlh-backend/storage/${storageId}/cdc_stop/`,
      });
      return response.data;
    } catch (error) {
      console.error("cdc_stop error:", error);
    }
  }
);

export const fetchRules = createAsyncThunk("fetchRules", async () => {
  const response = await axios.get("/dlh-backend/rules/get/");
  return response.data;
});

export const addRule = createAsyncThunk(
  "addRule",
  async (data: ICreateRule, { dispatch }) => {
    try {
      const response = await axios.post("/dlh-backend/rules/add/", data);

      dispatch(
        showMessage({
          message: "page.rule.action.add.success",
          type: ESnackbarState.SUCCESS,
        })
      );
      dispatch(fetchRules());

      return response.data;
    } catch (e) {
      dispatch(
        showMessage({
          message: "page.rule.action.add.error",
          type: ESnackbarState.ERROR,
        })
      );
    }
  }
);

export const addRuleToAttribute = createAsyncThunk(
  "attributeRuleAdd",
  async (data: any) => {
    const response = await axios({
      method: "PUT",
      url: "dlh-backend/attribute/rule/edit/",
      data,
    });

    return response.data;
  }
);
