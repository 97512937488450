import React, { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { useSnackbar } from "notistack";

import { selectSnackbar } from "../../store/snackbar/snackbar.selector";
import { clearMessage } from "../../store/snackbar/snackbar.slice";
import { onOpen } from "../../store/notification/notification.slice";
import { ESnackbarState } from "../../store/types/snackbar.types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { AppButton } from "../../components/app-button/app-button.component";

import s from "./snackbar.provider.module.scss";

type SnackbarProviderProps = {
  children: ReactNode;
};

export const AppSnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children,
}) => {
  const { type, message, action, transOptions } =
    useAppSelector(selectSnackbar);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const notificationData = () => {
    const setType = type === ESnackbarState.ERROR ? { isDanger: true } : {};
    if (action?.actionText) {
      return (
        <div className={s.notificationContainer}>
          <div onClick={() => closeSnackbar()}>
            <CloseOutlined style={{ color: "#fff", cursor: "pointer" }} />
          </div>
          <div className={s.rightBlock}>
            <span>{t(`${message}`, { ...transOptions })}</span>
            <AppButton
              isSmall
              {...setType}
              onClick={() => {
                switch (action?.actionCb) {
                  case "notification":
                    dispatch(onOpen(true));
                    break;
                  case "process_created":
                    navigate(
                      `/processes/${action.actionData.process_id}/result`
                    );
                    break;
                  case "xlsx_import_error_invalid_xlsx_file":
                    dispatch(onOpen(true));
                    navigate(`/origin/${action.actionData.storage}`);
                    break;
                  case "xlsx_import_correct_xlsx_file":
                    navigate(`/origin/${action.actionData.storage}`);
                    break;
                  case "storage_import_status":
                    dispatch(onOpen(true));
                    navigate(`/origin/${action.actionData.storage_id}`);
                    break;
                  // dispatch(onOpen(true));
                }
                closeSnackbar();
              }}
            >
              {t(`${action?.actionText}`)}
            </AppButton>
          </div>
        </div>
      );
    } else {
      return <span>{t(`${message}`, { ...transOptions })}</span>;
    }
  };

  useEffect(() => {
    if (type && message) {
      enqueueSnackbar(notificationData(), {
        variant: type,
      });
      dispatch(clearMessage());
    }
  }, [type, message, action]);

  return <>{children}</>;
};
