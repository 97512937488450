import { ESourceCode } from "./storages.types";

//Responses

export interface IStorageConfigTypes {
  db?: string;
  host?: string;
  password?: string;
  port?: string;
  user?: string;
  role?: string;
  link?: string;
  source_type?: ESourceCode;
  name?: string;
  service_name?: string;
  headers?: boolean;
  rows?: number[];
}

export enum EAppDbIcon {
  POSTGRE = "ds_postgresql",
  ORACLE = "ds_oracle",
  GOOGLE_SHEETS = "ds_googlesheets",
  GREENPLUM = "ds_greenplum",
  MY_SQL = "ds_mysql",
  MONGO_DB = "ds_mongodb",
  XLSX = "ds_xlsx",
  MS = "ds_mssqlserver",
}

export interface IConnectionsTypes {
  id: number;
  storage_name: string;
  storage_datasource_code: EAppDbIcon;
  color?: string;
}

export interface IConnectionsInitialStateTypes {
  connections: IConnectionsTypes[];
  search: string;
  connectionTypes: Array<string>;
  loading: boolean;
  error: string;
}

//Requests

export interface ICreateConnectionTypes {
  storage_conn_config: IStorageConfigTypes;
  storage_datalayer_code: string;
  storage_datasource_code: ESourceCode;
  storage_is_master?: boolean;
  storage_name: string;
  file?: FormDataEntryValue | null;
}

export interface IEditConnectionTypes {
  id: number;
  connection: ICreateConnectionTypes;
}
