import { RootState } from "../index";
import { IShortProcess } from "../types/processes.types";
import { createEntityAdapter } from "@reduxjs/toolkit";

export const processesAdapter = createEntityAdapter<any>({
  selectId: (process) => process.id,
});

export const processesSelectors = processesAdapter.getSelectors<RootState>(
  (state) => state.lowCode.processes
);

export const storagesAdapter = createEntityAdapter<any>({
  selectId: (process) => process.id,
});

export const shortProcessAdapter = createEntityAdapter<IShortProcess>({
  selectId: (process) => process.id,
});

export const shortProcessSelectors =
  shortProcessAdapter.getSelectors<RootState>(
    (state) => state.lowCode.processes
  );

export const selectAllProcesses = (state: RootState) =>
  shortProcessSelectors.selectAll(state);

export const selectProcessById = (state: RootState, id: number | string) =>
  shortProcessSelectors.selectById(state, id);

export const selectTotal = (state) =>
  shortProcessAdapter.getSelectors().selectTotal(state);

export const {
  selectById: selectStorageById,
  selectIds: selectStorageIds,
  selectEntities: selectStorageEntities,
  selectAll: selectAllStorages,
  selectTotal: selectTotalStorages,
} = storagesAdapter.getSelectors<RootState>((state) => state.lowCode.storages);
