import { Middleware } from "redux";
import { checkIsOnline } from "../network/network.slice";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../index";

const networkMiddleware: Middleware = ({ dispatch }) => {
  return (next) => {
    return (action) => {
      const thunkDispatch = dispatch as ThunkDispatch<
        RootState,
        { isOnline: boolean },
        any
      >;
      if (
        action.type !== checkIsOnline.pending.type &&
        action.type !== checkIsOnline.fulfilled.type &&
        action.type !== checkIsOnline.rejected.type
      ) {
        thunkDispatch(checkIsOnline());
      }
      return next(action);
    };
  };
};

export default networkMiddleware;
