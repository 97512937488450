import { RootState } from "../index";
import {
  IConnectionsInitialStateTypes,
  IConnectionsTypes,
} from "../types/connections.types";

export const selectAllConnections = (
  state: RootState
): IConnectionsInitialStateTypes => state.connections;

export const selectSearchConnections = (
  state: RootState
): IConnectionsTypes[] => {
  return state.connections.connections.filter((item) => {
    return item.storage_name
      .toLowerCase()
      .includes(state.connections.search.toLowerCase());
  });
};
