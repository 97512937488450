import React, { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

const APP_ICON_COLOR = "var(--app-icon-color, #8A8A8A)";

export const AppFullJoinIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50004 12C7.50031 10.541 7.92619 9.11383 8.72549 7.8933C9.52479 6.67276 10.6628 5.71191 12 5.1285C10.8586 4.62927 9.61081 4.42224 8.36932 4.52611C7.12784 4.62998 5.93178 5.04148 4.88918 5.72344C3.84658 6.40539 2.99028 7.33632 2.39763 8.43215C1.80497 9.52798 1.49463 10.7542 1.49463 12C1.49463 13.2458 1.80497 14.472 2.39763 15.5679C2.99028 16.6637 3.84658 17.5946 4.88918 18.2766C5.93178 18.9585 7.12784 19.37 8.36932 19.4739C9.61081 19.5778 10.8586 19.3707 12 18.8715C10.6628 18.2881 9.52479 17.3272 8.72549 16.1067C7.92619 14.8862 7.50031 13.459 7.50004 12Z"
      fill={APP_ICON_COLOR}
    />
    <path
      d="M12 6.80624C11.0878 7.33226 10.3303 8.08921 9.80363 9.00095C9.27693 9.91269 8.99963 10.9471 8.99963 12C8.99963 13.0529 9.27693 14.0873 9.80363 14.999C10.3303 15.9108 11.0878 16.6677 12 17.1937C12.9121 16.6677 13.6696 15.9108 14.1963 14.999C14.723 14.0873 15.0003 13.0529 15.0003 12C15.0003 10.9471 14.723 9.91269 14.1963 9.00095C13.6696 8.08921 12.9121 7.33226 12 6.80624Z"
      fill={APP_ICON_COLOR}
    />
    <path
      d="M15 4.5C13.9673 4.49975 12.9458 4.71376 12 5.1285C13.3375 5.71164 14.4757 6.67242 15.275 7.89301C16.0744 9.1136 16.5002 10.5409 16.5002 12C16.5002 13.4591 16.0744 14.8864 15.275 16.107C14.4757 17.3276 13.3375 18.2884 12 18.8715C13.0218 19.3178 14.1302 19.5307 15.2446 19.4946C16.359 19.4585 17.4514 19.1743 18.4421 18.6628C19.4329 18.1513 20.2971 17.4253 20.9719 16.5377C21.6466 15.6501 22.115 14.6231 22.3428 13.5317C22.5707 12.4402 22.5523 11.3117 22.2889 10.2282C22.0256 9.14474 21.524 8.13364 20.8206 7.26849C20.1173 6.40334 19.2298 5.70593 18.2229 5.22701C17.216 4.74809 16.115 4.49973 15 4.5Z"
      fill={APP_ICON_COLOR}
    />
  </svg>
);
