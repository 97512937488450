import { RootState } from "../../index";
import {
  IDomainProcess,
  IDomainProcessState,
} from "../../types/processes.types";

export const selectAllProcesses = (state: RootState): IDomainProcessState =>
  state.processes;

export const selectSearchProcesses = (state: RootState): IDomainProcess[] => {
  return state.processes.entries.filter((item) => {
    return item.process_name
      .toLowerCase()
      .includes(state.processes.search.toLowerCase());
  });
};
