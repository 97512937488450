import React from "react";

type AppImportIconProps = {
  width?: string;
  height?: string;
};

export const AppScheduleIcon: React.FC<AppImportIconProps> = ({
  width = "24",
  height = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5607 3.43934C20.842 3.72064 21 4.10218 21 4.5V9H19.5V4.5H16.5V6H15V4.5H9V6H7.5V4.5H4.5V19.5H7.5V21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H7.5V1.5H9V3H15V1.5H16.5V3H19.5C19.8978 3 20.2794 3.15804 20.5607 3.43934ZM15.75 22.5C14.5633 22.5 13.4033 22.1481 12.4166 21.4888C11.4299 20.8295 10.6609 19.8925 10.2067 18.7961C9.7526 17.6997 9.63378 16.4933 9.86529 15.3295C10.0968 14.1656 10.6682 13.0965 11.5074 12.2574C12.3465 11.4182 13.4156 10.8468 14.5795 10.6153C15.7433 10.3838 16.9497 10.5026 18.0461 10.9567C19.1425 11.4109 20.0795 12.1799 20.7388 13.1666C21.3981 14.1533 21.75 15.3133 21.75 16.5C21.75 18.0913 21.1179 19.6174 19.9926 20.7426C18.8674 21.8679 17.3413 22.5 15.75 22.5ZM15.75 12C14.86 12 13.99 12.2639 13.2499 12.7584C12.5099 13.2529 11.9331 13.9557 11.5925 14.7779C11.252 15.6002 11.1628 16.505 11.3365 17.3779C11.5101 18.2508 11.9387 19.0526 12.568 19.682C13.1974 20.3113 13.9992 20.7399 14.8721 20.9135C15.745 21.0872 16.6498 20.9981 17.4721 20.6575C18.2943 20.3169 18.9971 19.7401 19.4916 19.0001C19.9861 18.26 20.25 17.39 20.25 16.5C20.25 15.3065 19.7759 14.1619 18.932 13.318C18.0881 12.4741 16.9435 12 15.75 12ZM16.9425 18.75L15 16.8075V13.5H16.5V16.1925L18 17.6925L16.9425 18.75Z"
        fill="var(--app-icon-color, #737373)"
      />
    </svg>
  );
};
