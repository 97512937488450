import s from "./app-radio.module.scss";

import React, { ReactNode } from "react";
import cn from "classnames";
import { RegisterOptions, Controller, Control } from "react-hook-form";
import { CheckboxProps, Radio } from "antd";

interface IInputPropTypes {
  sharedStyles?: string;
  checked?: boolean;
  name: string;
  id?: string;
  rules?: RegisterOptions;
  children?: ReactNode[];
  control?: Control<any>;
  direction?: "horizontal" | "vertical";
  handleChange?: () => void;
}

export const AppRadioGroup: React.FC<IInputPropTypes & CheckboxProps> = ({
  sharedStyles,
  name,
  children,
  checked = undefined,
  control,
  rules,
  id,
  direction,
  handleChange = () => {},
  ...props
}) => {
  return (
    <div className={cn(sharedStyles)}>
      <div className={cn(s.checkboxWrapper)}>
        <Controller
          control={control}
          rules={rules}
          render={({ field: { onChange, value } }) => (
            <Radio.Group
              value={value}
              className={cn(s.radioGroup, {
                [s._horizontal]: direction === "horizontal",
                [s._vertical]: direction === "vertical",
              })}
              onChange={(e) => {
                onChange(e.target.value);
                handleChange();
              }}
            >
              {children?.map((radio, i) => {
                return (
                  <span key={i} className={s.radioWrapper}>
                    {radio}
                  </span>
                );
              })}
            </Radio.Group>
          )}
          name={name}
        />
      </div>
    </div>
  );
};
