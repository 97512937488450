import React, { useState, useEffect } from "react";
import { Collapse, Tooltip, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { AppBoxIcon } from "../../../../components/icons";
import { truncateText } from "../../../../helpers/truncate-text.helper";
import { useAppSelector } from "../../../../store/hooks";
import { selectCreatedProcess } from "../../../../store/processes/process-create/process-create.selector";

import st from "../../../../components/low-code/aside/aside.module.scss";

const { Panel } = Collapse;

type AttrItemProps = {
  isSelectedAll: boolean;
  attribute: any;
  joinsData: any;
  addAttribute: (string) => void;
  entityName: string;
  removeAttribute: (string) => void;
};

const AttrItem: React.FC<AttrItemProps> = React.memo(
  ({
    attribute: { attribute_name, id, attribute_type, headText },
    joinsData,
    addAttribute,
    entityName,
    removeAttribute,
    isSelectedAll,
  }) => {
    const [checked, setChecked] = useState(null);

    useEffect(() => {
      setChecked(null);
    }, [isSelectedAll]);

    const renderTitle = (title: string) => {
      const { text, isTruncated } = truncateText(title, 24);
      if (isTruncated) {
        return (
          <Tooltip title={title} color={"var(--white)"}>
            <span>{text}</span>
          </Tooltip>
        );
      } else {
        return <span>{text}</span>;
      }
    };

    const isUsed = joinsData.some((join) =>
      join.attributes.some((attribute) => id === attribute.id)
    );

    const onChange = (e) => {
      setChecked(e.currentTarget.checked);
      if (e.currentTarget.checked) {
        addAttribute({ attribute_name, entity_name: entityName });
      } else {
        removeAttribute(attribute_name);
      }
    };

    return (
      <div
        className={cn(st.asideItem, {
          [st.asideItemisUsed2]: isUsed,
        })}
        key={id}
        draggable
        role="option"
      >
        <div className={st.asideItemCol}>
          <AppBoxIcon width="20" height="20" />
          <div>
            <div className={st.asideItemTitle}>
              {renderTitle(attribute_name)}
            </div>
            <div className={st.asideItemType}>
              {headText}
              <span>{attribute_type}</span>
            </div>
          </div>
        </div>
        <Checkbox
          checked={checked !== null ? checked : isSelectedAll}
          onClick={(e) => onChange(e)}
          disabled={isUsed}
        />
      </div>
    );
  }
);

export const JoinCollapseStep2 = ({
  attributes,
  id,
  entityName,
  addAttribute,
  removeAttribute,
  addAttributes,
  removeAllAttributes,
}) => {
  const { t } = useTranslation();
  const { joinsData } = useAppSelector(selectCreatedProcess);
  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const addAll = (e) => {
    e.stopPropagation();
    const allAttributes = attributes.map(({ attribute_name }) => {
      return {
        attribute_name,
        entity_name: entityName,
      };
    });
    setIsSelectedAll(e.currentTarget.checked);
    if (e.currentTarget.checked) {
      addAttributes(allAttributes);
    } else {
      removeAllAttributes(allAttributes);
    }
  };

  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "8px",
      }}
    >
      <div>{entityName}</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ color: "#9B9B9B", marginRight: "8px" }}>
          {t("page.process.form.step.lowcode.join.result.add.all")}
        </div>
        <Checkbox checked={isSelectedAll} onClick={(e) => addAll(e)} />
        &nbsp;
      </div>
    </div>
  );

  return (
    <Collapse bordered={false} defaultActiveKey={["1"]}>
      <Panel header={header} key={id}>
        <div className={st.asideList}>
          {attributes.map((attribute) => {
            return (
              <AttrItem
                key={attribute.id}
                attribute={attribute}
                joinsData={joinsData}
                addAttribute={addAttribute}
                entityName={entityName}
                removeAttribute={removeAttribute}
                isSelectedAll={isSelectedAll}
              />
            );
          })}
        </div>
      </Panel>
    </Collapse>
  );
};
