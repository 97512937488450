import React from "react";
import {
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";

import { AppMainLayout } from "./components/app-main.layout";
import { Auth } from "./pages/auth/auth.page";
import { AuthProvider } from "./providers/auth-provider/auth.provider";
import { AppIntroProvider } from "./providers/intro-provider/intro.provider";
import routes from "./routes";

function App() {
  const router = createHashRouter(
    createRoutesFromElements(
      <>
        <Route
          path={"/"}
          element={
            <AuthProvider>
              <AppIntroProvider>
                <AppMainLayout />
              </AppIntroProvider>
            </AuthProvider>
          }
        >
          <Route index element={<Navigate to="/home" replace />}></Route>
          {routes.map(({ path, element }) => {
            return <Route key={path} path={path} element={element}></Route>;
          })}
        </Route>

        <Route path={"/auth"} element={<Auth />}></Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
