export const AppCsvIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.70572 18.456H14.9563L16.7143 18.4286V20.1429H4.70572C4.25234 20.1413 3.81811 19.9599 3.49832 19.6385C3.17854 19.3171 2.99932 18.882 3 18.4286L3.00858 4.71429C3.00789 4.2609 3.18711 3.82577 3.50689 3.50438C3.82668 3.18298 4.26091 3.00159 4.71429 3H11.5714L13.2771 4.70571L14.9571 6.384L16.7143 8.14286V9.84514H14.9563V8.97429H10.6877V4.70571H4.70572V18.456ZM21 11.5714L19.5 16.7143H18.2143L16.7143 11.5714H18L18.8571 14.5114L19.7143 11.5714H21ZM7.28572 11.5714H9.85714C10.0843 11.5721 10.3019 11.6626 10.4625 11.8232C10.6231 11.9838 10.7136 12.2015 10.7143 12.4286V13.2857H9.42857V12.8571H7.71429V15.4286H9.42857V15H10.7143V15.8571C10.7136 16.0843 10.6231 16.3019 10.4625 16.4625C10.3019 16.6231 10.0843 16.7136 9.85714 16.7143H7.28572C7.0586 16.7136 6.84097 16.6231 6.68037 16.4625C6.51977 16.3019 6.42925 16.0843 6.42857 15.8571V12.4286C6.42925 12.2015 6.51977 11.9838 6.68037 11.8232C6.84097 11.6626 7.0586 11.5721 7.28572 11.5714ZM15.8571 11.5714V12.8623L12.8486 12.8631V13.5H15C15.2273 13.5 15.4453 13.5903 15.6061 13.751C15.7668 13.9118 15.8571 14.1298 15.8571 14.3571V15.8631C15.8571 16.0905 15.7668 16.3085 15.6061 16.4692C15.4453 16.63 15.2273 16.7203 15 16.7203H11.5714V15.4286H14.58V14.7917H12.4286C12.2012 14.7917 11.9832 14.7014 11.8225 14.5407C11.6617 14.3799 11.5714 14.1619 11.5714 13.9346V12.4286C11.5714 12.2012 11.6617 11.9832 11.8225 11.8225C11.9832 11.6617 12.2012 11.5714 12.4286 11.5714H15.8571Z"
          fill={"var(--app-icon-color)" || "#737373"}
        />
      </svg>
    </>
  );
};
