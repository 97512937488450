import React from "react";

export const ArrowDownIcon: React.FC<{
  width?: string;
  height?: string;
  fill?: string;
}> = ({ width = "12", height = "7", fill = "#737373" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none"
    >
      <path
        d="M2.12016 0.288115L6.00016 4.16811L9.88016 0.288115C9.97274 0.195533 10.0827 0.122093 10.2036 0.0719881C10.3246 0.0218831 10.4542 -0.00390625 10.5852 -0.00390625C10.7161 -0.00390625 10.8457 0.0218831 10.9667 0.0719881C11.0877 0.122093 11.1976 0.195533 11.2902 0.288115C11.3827 0.380696 11.4562 0.490607 11.5063 0.611571C11.5564 0.732535 11.5822 0.862184 11.5822 0.993114C11.5822 1.12405 11.5564 1.25369 11.5063 1.37466C11.4562 1.49562 11.3827 1.60553 11.2902 1.69811L6.70016 6.28811C6.60765 6.38082 6.49776 6.45437 6.37678 6.50455C6.25581 6.55473 6.12613 6.58056 5.99516 6.58056C5.86419 6.58056 5.73451 6.55473 5.61353 6.50455C5.49256 6.45437 5.38267 6.38082 5.29016 6.28811L0.700159 1.69811C0.607455 1.6056 0.533907 1.49571 0.483726 1.37474C0.433545 1.25377 0.407715 1.12408 0.407715 0.993114C0.407715 0.862146 0.433545 0.732464 0.483726 0.61149C0.533907 0.490517 0.607455 0.380628 0.700159 0.288115C1.09016 -0.0918854 1.73016 -0.101885 2.12016 0.288115Z"
        fill={fill}
      />
    </svg>
  );
};
