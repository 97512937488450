import { createSlice } from "@reduxjs/toolkit";
import {
  fetchShortProcesses,
  fetchFullProcessById,
} from "../../api/processes/processes-actions.api";
import { fetchStorageForProcess } from "../../api/storages/storages-acitons.api";
import { ProcessType } from "../types/low-code.types";
import {
  selectTotal,
  processesAdapter,
  storagesAdapter,
} from "./low-code.selector";

const initialProcess: ProcessType = {
  loading: false,
  error: "",
  date: "-",
  schedule: null,
  entity: null,
};

const lowCodeSlice = createSlice({
  name: "low-code",
  initialState: {
    storages: storagesAdapter.getInitialState({
      loading: false,
    }),
    processes: processesAdapter.getInitialState({
      search: "",
      error: "",
      loading: false,
      count: 0,
      hasMore: false,
    }),
    process: initialProcess,
  },
  reducers: {},
  extraReducers: {
    [fetchShortProcesses.pending.type]: (state) => {
      state.processes.loading = true;
    },
    [fetchShortProcesses.fulfilled.type]: (state, { payload, meta }) => {
      if (meta.arg.offset === 0) {
        processesAdapter.setAll(state.processes, payload.results);
      } else {
        processesAdapter.addMany(state.processes, payload.results);
      }
      state.processes.loading = false;
      state.processes.count = payload.count;
      state.processes.hasMore = selectTotal(state.processes) < payload.count;
    },
    [fetchShortProcesses.rejected.type]: (state, { payload }) => {
      state.processes.loading = false;
      state.processes.error = payload;
    },
    [fetchFullProcessById.pending.type]: (state) => {
      state.process.loading = true;
    },
    [fetchFullProcessById.fulfilled.type]: (state, { payload }) => {
      const { id, lineage_process, process_scheduler } = payload;
      const entity = lineage_process.length
        ? lineage_process[0].lineage_ent_in
        : null;

      state.process.loading = false;
      state.process.entity = entity;
      state.process.schedule = process_scheduler;
      processesAdapter.updateOne(state.processes, {
        id,
        changes: { entity, schedule: process_scheduler ?? null },
      });
    },
    [fetchFullProcessById.rejected.type]: (state, { payload }) => {
      state.process.loading = false;
      state.processes.error = payload;
    },
    [fetchStorageForProcess.pending.type]: (state) => {
      state.storages.loading = true;
    },
    [fetchStorageForProcess.fulfilled.type]: (state, { payload }) => {
      storagesAdapter.setAll(state.storages, payload.data);
      state.storages.loading = true;
    },
    [fetchStorageForProcess.rejected.type]: (state, { payload }) => {
      state.storages.loading = true;
      state.processes.error = payload;
    },
  },
});

export const lowCodeReducer = lowCodeSlice.reducer;
