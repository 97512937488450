import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  fetchStorageEntities,
  saveStoragesSelect,
} from "../../api/storages/storages-acitons.api";
import { IStorageEntriesType } from "../types/storages.types";

export const storageEntitiesAdapter = createEntityAdapter<IStorageEntriesType>({
  selectId: (entity) => entity.id,
});

const storageEntitiesSlice = createSlice({
  name: "storageEntities",
  initialState: {
    ...storageEntitiesAdapter.getInitialState({ search: "" }),
    count: 0,
    storageSelected: false,
    entitiesData: [], // duplication entities, need refactor;
  },
  reducers: {
    searchStorageEntity: (state, { payload }) => {
      state.search = payload;
    },
    updateEntity: storageEntitiesAdapter.updateOne,
    setEntities: storageEntitiesAdapter.setAll,
    clearEntities: (state) => {
      storageEntitiesAdapter.removeAll;
      state.entitiesData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStorageEntities.fulfilled, (state, action) => {
      state.count = action.payload.count;
      state.storageSelected = action.payload.storageSelected;
      if (action.payload.entities) {
        state.entitiesData = action.payload.entities;
        storageEntitiesAdapter.addMany(state, action.payload.entities);
      }
    });
    builder.addCase(saveStoragesSelect.fulfilled, (state, action) => {
      storageEntitiesAdapter.upsertMany(state, action.payload.entities as any);
    });
  },
});

export const { searchStorageEntity, updateEntity, setEntities, clearEntities } =
  storageEntitiesSlice.actions;

export const storageEntitiesReducer = storageEntitiesSlice.reducer;
