import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Row, Collapse, Tooltip } from "antd";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { useDrag } from "react-dnd";
import cn from "classnames";

import { TransformationIncomerType } from "../../../../store/types/low-code.types";
// import { MergeFormSettings } from "./low-code-merge-form-settings.component";
import {
  AppJoinIcon,
  AppModelIcon,
  AppBoxIcon,
  PlusIcon,
  AppSearchIcon,
} from "../../../../components/icons";
import { AppButton } from "../../../../components/app-button/app-button.component";
import { JoinInfo } from "./low-code-join-info";
import { truncateText } from "../../../../helpers/truncate-text.helper";
import { DropSection } from "./low-code-join-drop.component";
import { nameValidation } from "../../../../helpers/low-code.helper";
import { AppInput } from "../../../../components/app-input/app-input.component";
import { debounce } from "../../../../utils/utils";
import { filterEntitiesAndAttributes } from "../../../../helpers/search.helper";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectCreatedProcess } from "../../../../store/processes/process-create/process-create.selector";
import {
  removeJoinFromList,
  addJoin,
} from "../../../../store/processes/process-create/process-create.slice";

import s from "./join-modal.module.scss";
import st from "../../../../components/low-code/aside/aside.module.scss";

const { Panel } = Collapse;

type PropsType = {
  source: TransformationIncomerType[];
  onSubmit: any;
  onCancel: any;
  transformNode: any;
  changeStep: (number) => void;
};

const ItemTypes = {
  ATTRIBUTE: "attribute",
};

type AttrItemProps = {
  attribute: any;
};

const AttrItem: React.FC<AttrItemProps> = ({
  attribute: { attribute_name, id, attribute_type, drag, headText },
}) => {
  const { joinsData } = useAppSelector(selectCreatedProcess);
  const renderTitle = (title: string) => {
    const { text, isTruncated } = truncateText(title, 24);
    if (isTruncated) {
      return (
        <Tooltip title={title} color={"var(--white)"}>
          <span>{text}</span>
        </Tooltip>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  const isUsed = joinsData.some((join) =>
    join.attributes.some((attribute) => id === attribute.id)
  );

  return (
    <div
      className={cn(st.asideItem, {
        [st.asideItemisUsed]: isUsed,
      })}
      key={id}
      ref={drag}
      draggable
      role="option"
    >
      <div className={st.asideItemCol}>
        <AppBoxIcon width="20" height="20" />
        <div>
          <div className={st.asideItemTitle}>{renderTitle(attribute_name)}</div>
          <div className={st.asideItemType}>
            {headText}
            <span>{attribute_type}</span>
          </div>
        </div>
      </div>
      <AppModelIcon />
    </div>
  );
};

export const JoinForm: React.FC<PropsType> = ({
  source: incomers,
  onSubmit,
  onCancel,
  transformNode: node,
  changeStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { joinsData } = useAppSelector(selectCreatedProcess);

  const enrichEntities = (data) => {
    const entities = JSON.parse(JSON.stringify(data));
    return entities.map((entity) => {
      entity.attributes = entity.attributes.map((attribute) => {
        const [, drag] = useDrag(() => ({
          type: ItemTypes.ATTRIBUTE,
          item: {
            id: attribute.id,
            name: attribute.attribute_name,
            type: attribute.attribute_type,
            entity: entity.entity_name,
            entity_id: entity.id,
          },
          end: () => {},
          collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
          }),
        }));
        return Object.assign({}, attribute, { drag });
      });

      return entity;
    });
  };

  const originEntities = enrichEntities(incomers.map(({ entity }) => entity));

  const [entities, setEntities] = useState<any[]>(originEntities);
  const [activeKey, setActiveKey] = useState(["1"]);

  const onChangeSearch = (event: React.FormEvent<HTMLInputElement>) => {
    const searchValue = (event.target as HTMLInputElement).value;
    if (searchValue.length > 0) {
      setActiveKey(entities.map((item) => String(item.id)));
    } else {
      setActiveKey(["1"]);
    }
    setEntities(
      searchValue
        ? filterEntitiesAndAttributes(originEntities, searchValue)
        : originEntities
    );
  };
  const debounceSearch = debounce(onChangeSearch, 500);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: { name: "" },
  });

  const createJoin = () => {
    const newId = joinsData.at(-1).id + 1;
    dispatch(addJoin({ id: newId, attributes: [] }));
  };

  const removeJoin = (count) => {
    dispatch(removeJoinFromList(count));
  };

  const handleClickCollapse = (key) => {
    setActiveKey(key);
  };

  return (
    <form
      onSubmit={handleSubmit(() => {
        onCancel();
        // if (isValidSettings) {
        //   submitForm({ ...data, joins, name });
        // }
      })}
    >
      <Row gutter={0}>
        <Col span={18}>
          <div className={s.leftBlock}>
            <div className={s.textInput}>
              <AppJoinIcon />
              <Controller
                name="name"
                control={control}
                rules={nameValidation(t)}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder={t(
                      "page.process.form.step.lowcode.join.modal.title.table"
                    )}
                    {...field}
                  />
                )}
              />
            </div>
            <ErrorMessage
              key={"name"}
              name={"name"}
              errors={errors}
              render={({ message }) => (
                <span className={s.inputError}>{message}</span>
              )}
            />
            {joinsData?.map(({ id, attributes = [], title = "" }) => {
              return (
                <DropSection
                  key={id}
                  count={id}
                  title={title}
                  attributes={attributes}
                  removeJoin={() => removeJoin(id)}
                  itemType={ItemTypes.ATTRIBUTE}
                />
              );
            })}
            <button
              type="button"
              className={cn({
                [s.addButton]: true,
                // [s.addButtonDisabled]: !isValid,
              })}
              onClick={createJoin}
            >
              {t("page.process.form.step.lowcode.merge.add")} &nbsp;
              <PlusIcon width="14" height="14" />
            </button>
            <JoinInfo title="page.process.form.step.lowcode.join.modal.information.text" />
          </div>
        </Col>
        <Col span={6}>
          <div className={s.rightBlock}>
            <div className={s.rightBlockHeader}>
              {t("page.process.form.step.lowcode.join.modal.list.entities")}
            </div>
            <AppInput
              icon={<AppSearchIcon />}
              sharedStyles={s.searchInput}
              onChange={debounceSearch}
              name={"search"}
              control={control}
              placeholder={t(
                "page.process.form.step.lowcode.union.search.placeholder" as string
              )}
            />
            <Collapse
              bordered={false}
              collapsible={"header"}
              defaultActiveKey={["1"]}
              activeKey={activeKey}
              onChange={handleClickCollapse}
            >
              {entities.map(({ id, entity_name, attributes }) => {
                return (
                  <Panel header={entity_name} key={id}>
                    <div className={st.asideList}>
                      {attributes.map((attribute) => {
                        return (
                          <AttrItem key={attribute.id} attribute={attribute} />
                        );
                      })}
                    </div>
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        </Col>
      </Row>
      <div className={s.footer}>
        <div className={s.footerLeftBtns}>
          <AppButton disabled={true}>
            <span>
              {t("page.process.form.step.lowcode.join.modal.see.preview")}
            </span>
          </AppButton>
          <AppButton disabled={true}>
            <span>{t("page.process.form.step.lowcode.merge.view_sql")}</span>
          </AppButton>
        </div>
        <div className={s.footerTitle}>
          {t("page.process.form.step.lowcode.join.modal.title")}
        </div>
        <AppButton
          disabled={joinsData.every((item) => item.title == undefined)}
          htmlType="submit"
          onClick={() => changeStep(2)}
        >
          {t("steps.action.next")}
        </AppButton>
      </div>
    </form>
  );
};
