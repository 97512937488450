import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";

import { selectProcessDetail } from "../../../store/processes/process-detail/process-detail.selector";
import { useAppSelector } from "../../../store/hooks";
import { AppInput } from "../../../components/app-input/app-input.component";
import { AppButton } from "../../../components/app-button/app-button.component";

import s from "../../create-process/create-process.module.scss";

export const Step3 = ({ onBack }: any) => {
  const { t } = useTranslation();
  const { control } = useForm();
  const { currentProcess } = useAppSelector(selectProcessDetail);
  const { process_sql } = currentProcess;

  return (
    <form className={cn(s.formWrapper, s.formWrapperLast)}>
      <div className={s.formTitleContainer}>
        <h3 className={s.formTitle}>{t("page.process.form.step.title")}</h3>
      </div>
      <div className={s.stepTwoContainer}>
        <div className={s.stepTwoEntities}>
          <AppInput
            disabled
            sharedStyles={s.sqlTextArea}
            rules={{
              required: t(
                "page.process.form.step.validation.required"
              ) as string,
            }}
            control={control}
            name={"process_sql"}
            placeholder={process_sql}
            isTextArea={true}
          />
        </div>
      </div>
      <div className={cn(s.formFooterButtons)}>
        <AppButton isOutline onClick={onBack}>
          {t("page.process.form.step.action.back")}
        </AppButton>
        <NavLink to={"/processes"}>
          <AppButton disabled={false}>
            {t("page.process.prompt.exit.confirm")}
          </AppButton>
        </NavLink>
      </div>
    </form>
  );
};
