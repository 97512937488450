import type { Node } from "reactflow";
import type {
  AttributeType,
  MergeDataType,
  TransformationIncomerType,
} from "../../../store/types/low-code.types";
import { flatten, uniq } from "../../../utils/utils";
import { Transformation } from ".";

export class Merge extends Transformation {
  public data: MergeDataType | null;
  protected children = new Map<string, Transformation>();
  public parent!: Transformation | null;

  constructor(data?: any | undefined | null) {
    super();
    this.data = data ?? null;
  }

  public getEntities() {
    const entityIds = flatten(
      this.data?.joins.map(({ left, right }) => [
        Number(left?.entityId),
        Number(right?.entityId),
      ]) || []
    );

    const childEntityIds = Array.from(this.children.values()).map((component) =>
      component.getEntities()
    );

    return uniq(
      entityIds.concat(flatten(childEntityIds)).filter((id) => !isNaN(id))
    );
  }

  public getAttributes() {
    const entityIds = flatten(
      this.data?.joins.map(({ left, right }) => [
        left?.entityId,
        right?.entityId,
      ]) || []
    );

    const allAttributes = flatten(
      this.data?.incomers.map(({ entity }) =>
        entity.attributes.map((attr: AttributeType) => ({
          id: attr.id,
          entity_id: entity.id,
          attribute_schema: entity.entity_name,
          attribute_name: attr.attribute_name,
          attribute_name_slug: attr.attribute_name_slug,
          attribute_type: attr.attribute_type,
          attribute_internal_type: attr.attribute_internal_type,
          attribute_is_nullable: attr.attribute_is_nullable,
          attribute_is_selected: attr.attribute_is_selected,
        }))
      ) || []
    );

    return allAttributes.filter(({ entity_id }) =>
      entityIds.includes(String(entity_id))
    );
  }

  getEntitiyIds() {
    return flatten(
      this.data?.joins.map(({ left, right }) => [
        left?.entityId,
        right?.entityId,
      ]) || []
    );
  }

  public getResult(): TransformationIncomerType {
    const key = `merge_${this.data?.node?.data.index}`;

    return {
      type: "merge",
      node: this.data?.node as Node,
      entity: {
        id: key,
        entity_name: this.data?.name || key,
        entity_name_slug: key,
        entity_schema: key,
        entity_schema_slug: key,
        attributes: this.getAttributes(),
      },
      storage: {
        id: key,
        storage_name: "",
        storage_name_slug: "",
        entities: [],
      },
    };
  }

  public toObject() {
    const key = `${this.data?.node?.type}_${this.data?.node?.data.index}`;

    const leaf =
      this.data === null
        ? []
        : [
            {
              index: this.data?.node?.data.index,
              type: "merge",
              id: this.data?.node.id,
              name: this.data.name,
              parent:
                this.parent && this.parent.data
                  ? { index: this.parent.data?.node.data.index, type: "merge" }
                  : null,
              children: Array.from(this.children.values()).map((child) => ({
                index: child.data?.node.data.index,
                type: "merge",
              })),
              settings: this.data.joins,
              results: {
                type: "process",
                id: 0,
                entityId: 0,
                entityName: key,
                attributes: this.getAttributes().map((item) => ({
                  id: item.id,
                  entityId: item.entity_id,
                  attributeSchema: item.attribute_schema,
                  attributeName: item.attribute_name,
                  attributeNameSlug: item.attribute_name_slug,
                  attributeType: item.attribute_type,
                })),
              },
            },
          ];

    const childLeafs = Array.from(this.children.values()).map((component) =>
      component.toObject()
    );

    return leaf.concat(flatten(childLeafs));
  }
}
