import { createSlice } from "@reduxjs/toolkit";
import { INotificationInitialState } from "../types/notification.types";
import { getAttributeStatus } from "../../api/notifications/notifications-actions.api";

const initialState: INotificationInitialState = {
  error: "",
  loading: false,
  notificationList: [],
  open: false,
};
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearNotificationList: (state) => {
      state.notificationList = [];
    },
    onOpen: (state, action) => {
      state.open = action.payload;
    },
  },
  extraReducers: {
    [getAttributeStatus.pending.type]: (state) => {
      state.loading = true;
    },
    [getAttributeStatus.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.notificationList = payload;
    },
    [getAttributeStatus.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { clearNotificationList, onOpen } = notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
