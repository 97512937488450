import { ReactNode } from "react";

export enum ESnackbarState {
  ERROR = "error",
  SUCCESS = "success",
}

export interface ISnackBarActionType {
  actionText: string;
  actionCb:
    | "notification"
    | "process_created"
    | "xlsx_import_error_invalid_xlsx_file"
    | "storage_import_status"
    | "xlsx_import_correct_xlsx_file";
  actionData?: any;
}

export interface ISnackBarInitialStateType {
  message: string | ReactNode;
  type: ESnackbarState | null;
  action?: ISnackBarActionType;
  transOptions?: {
    [keys: number]: string;
  };
}
