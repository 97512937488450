import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import cn from "classnames";

import { AppTruncateText } from "../app-truncate-text/app-truncate.component";
import {
  Card,
  CardBody,
  CardLabelBox,
  CardMenuItem,
  CardTitle,
} from "../ui/app-card/app-card.component";
import s from "./process-card.module.scss";
import {
  EProcessStatus,
  IDomainProcess,
} from "../../store/types/processes.types";
import { AppPlayIcon } from "../icons/app-play.icon";
import { AppArchiveIcon } from "../icons/app-archive.icon";
import { AppUnarchiveIcon } from "../icons/app-unarchive.icon";
import { AppEditIcon } from "../icons/app-edit.icon";

type PropTypes = {
  id: string;
  data: IDomainProcess;
  onArchive: (process: IDomainProcess) => void;
  onRun: (id: number, process_name_slug: string) => void;
  onEdit: () => void;
};

export const ProcessCard: React.FC<PropTypes> = ({
  id,
  data,
  onArchive,
  onRun,
  onEdit,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isArchived = data.process_status_flow === EProcessStatus.ARCHIVED;

  const isProcessResultAvailable = [
    EProcessStatus.ARCHIVED,
    EProcessStatus.DRAFT,
  ].includes(data.process_status_flow as any);

  const createMarkup = useCallback(() => {
    return { __html: data.process_description };
  }, [data]);

  const handleArchive = () => {
    onArchive(data);
  };
  const handleRun = () => {
    onRun(data.id, data.process_name_slug);
  };
  const handleViewResult = () => {
    if (isProcessResultAvailable) return;

    navigate(`/processes/${data.id}/result`);
  };

  const editProcess = (): void => {
    navigate(`/processes/${data.id}/edit`);
  };

  const statusColor = () => {
    switch (data.process_status_flow) {
      case EProcessStatus.ACTIVE:
        return { [s._active]: true };
      case EProcessStatus.RUNNING:
        return { [s._created]: true };
      case EProcessStatus.DRAFT:
        return { [s._draft]: true };
      case EProcessStatus.ARCHIVED:
        return {};
      case EProcessStatus.ERROR:
        return { [s._error]: true };
      default:
        return {};
    }
  };

  const cardActions = [
    <CardMenuItem
      key="result"
      text={t("page.process.card.actions.settings")}
      icon={<EyeOutlined style={{ fontSize: "20px", width: "24px" }} />}
      onClick={onEdit}
    />,
    <CardMenuItem
      key="run"
      text={t("page.process.card.actions.start")}
      icon={<AppPlayIcon />}
      onClick={handleRun}
      isDisabled={data.process_status_flow !== EProcessStatus.ACTIVE}
    />,
    <CardMenuItem
      key="edit"
      text={t("page.process.card.actions.edit")}
      icon={<AppEditIcon />}
      isDisabled={data.process_status_flow !== EProcessStatus.DRAFT}
      onClick={editProcess}
    />,
    <CardMenuItem
      id={`${id}-archive-btn`}
      key="archive"
      text={t(
        isArchived
          ? "page.process.card.actions.unarchive"
          : "page.process.card.actions.archive"
      )}
      isPrimary={isArchived}
      isDanger={!isArchived}
      isDisabled={
        data.process_status_flow !== EProcessStatus.ACTIVE && !isArchived
      }
      icon={isArchived ? <AppUnarchiveIcon /> : <AppArchiveIcon />}
      onClick={handleArchive}
    />,
  ];

  const filteredLineageProcess = data.lineage_process
    .map((item) => item.storage_name)
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <Card id={id} actions={cardActions} onClick={handleViewResult}>
      <CardTitle>{data.process_name}</CardTitle>
      <CardBody>
        <CardLabelBox
          className={cn("mb-16", s.processStatusWrap)}
          label={t("page.process.card.status")}
        >
          <div className={cn(s.processStatusText, statusColor())}>
            {data.process_status_flow.toLowerCase()}
          </div>
        </CardLabelBox>
        {filteredLineageProcess.length > 0 ? (
          <CardLabelBox
            className="mb-16"
            label={t("page.process.card.process_source")}
          >
            {filteredLineageProcess.map((storage_name) => (
              <Tag className={s.storageTag} key={storage_name}>
                {storage_name}
              </Tag>
            ))}
          </CardLabelBox>
        ) : null}
        <CardLabelBox label={t("page.process.card.rule_description") + ":"}>
          <AppTruncateText
            height={26}
            content={<span dangerouslySetInnerHTML={createMarkup()} />}
          >
            {data.process_description}
          </AppTruncateText>
        </CardLabelBox>
      </CardBody>

      {isArchived ? (
        <div className={s.archiveContainer}>
          <AppArchiveIcon />
          <p className="mt-4">{t("page.process.card.archive.status")}</p>
        </div>
      ) : null}
    </Card>
  );
};
