export const MsSqlIcon = ({ iconColor = "#737373" }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.28905 14.9648C7.22128 15.0258 7.16029 15.0868 7.10608 15.1478C6.64526 15.6358 5.85915 16.6523 6.02179 17.4994C6.03535 17.5739 6.06245 17.6485 6.08956 17.723C6.88922 18.9496 8.46144 19.4443 9.87101 19.6679C9.92522 19.6747 9.97944 19.6815 10.0337 19.695C11.3212 19.8916 13.0697 20 13.0697 20C13.0697 20 13.3136 19.5121 13.6389 18.8073C13.6592 18.7599 13.6863 18.7056 13.7067 18.6514C14.4047 16.8827 14.7367 15.0665 14.7367 13.2571C14.7367 13.1622 14.7435 13.0673 14.7435 12.9725C14.7435 12.6269 14.7232 12.3219 14.6758 12.0983C12.9002 12.6336 10.9892 13.2842 10.1285 13.6637C8.92903 14.1923 7.32971 14.9445 7.28905 14.9648Z"
          fill={iconColor}
        />
        <path
          d="M12.5411 5.64676C12.2565 5.0233 11.8431 4 11.8431 4L8.54956 5.93816C8.54956 5.93816 11.0028 6.51419 13.212 6.96146C12.9409 6.43287 12.6902 5.97882 12.5411 5.64676Z"
          fill={iconColor}
        />
        <path
          d="M12.8596 10.011C11.965 11.2241 10.9079 12.3151 9.72192 13.2571C9.81002 13.2164 9.89812 13.1758 9.98622 13.1419C10.5013 12.9115 11.4568 12.5726 12.5004 12.2338C13.1578 11.7255 13.7744 11.1631 14.3437 10.5599C13.8693 10.3499 13.3746 10.1669 12.8596 10.011Z"
          fill={iconColor}
        />
        <path
          d="M13.6524 7.57815C13.5169 7.55104 13.3813 7.52393 13.2322 7.49683C13.0425 7.46294 12.8392 7.42228 12.6427 7.38162C10.7384 7.00212 8.63757 6.50742 8.63757 6.50742C8.63757 6.50742 9.13228 7.02923 9.58632 7.53749C9.61343 7.57137 9.64731 7.60526 9.67442 7.63914C9.93194 7.9441 10.1352 8.29649 10.2776 8.66921C10.2843 8.69632 10.2911 8.70987 10.3047 8.73698C10.3047 8.74376 10.3047 8.75053 10.3114 8.75731C10.3114 8.76409 10.3114 8.76409 10.3182 8.77086C11.0908 8.88607 11.8633 9.05549 12.6291 9.27235C12.995 8.73698 13.3407 8.16773 13.6524 7.57815ZM10.4538 8.45913C10.4605 8.48624 10.4741 8.51335 10.4809 8.54045C10.4809 8.54723 10.4876 8.55401 10.4876 8.56756C10.4741 8.5269 10.4673 8.49302 10.4538 8.45913Z"
          fill={iconColor}
        />
        <path
          d="M14.8045 10.0652C15.3128 9.4892 15.7736 8.87251 16.2005 8.22872C15.6855 8.03897 15.0688 7.87633 14.2963 7.71368C13.9777 8.3236 13.6253 8.91318 13.2458 9.47565C13.7744 9.64507 14.2963 9.8416 14.8045 10.0652Z"
          fill={iconColor}
        />
        <path
          d="M12.2158 9.82126C11.6397 9.66539 11.0569 9.53663 10.4741 9.44176C10.5622 10.0246 10.5148 10.6141 10.2844 11.1495C10.2573 11.2173 10.2234 11.285 10.1827 11.3528C10.1082 11.4883 10.0201 11.6239 9.91843 11.7594C9.91843 11.7594 9.91843 11.7594 9.91843 11.7662C9.6067 12.166 8.84092 12.9657 8.26489 13.5553C9.78289 12.5252 11.1179 11.2647 12.2158 9.82126Z"
          fill={iconColor}
        />
        <path
          d="M14.0522 11.7459C14.6283 11.5697 15.1772 11.4138 15.6516 11.2851C15.699 11.2715 15.7464 11.2579 15.8007 11.2444C15.5296 11.0953 15.2517 10.953 14.9671 10.8242C14.6689 11.1427 14.3708 11.4545 14.0522 11.7459Z"
          fill={iconColor}
        />
        <path
          d="M16.6749 11.014C17.949 10.6684 18.8841 10.4108 18.8841 10.4108C18.8841 10.4108 18.1387 9.374 17.278 8.77086C17.129 8.66243 16.9663 8.57433 16.7901 8.48624C16.37 9.13681 15.9024 9.7535 15.4009 10.3363C15.8346 10.5464 16.2615 10.77 16.6749 11.014Z"
          fill={iconColor}
        />
        <path
          d="M10.4539 8.45912C10.4674 8.49301 10.481 8.52689 10.4945 8.56755C10.4945 8.56077 10.4877 8.554 10.4877 8.54044C10.4742 8.50656 10.4606 8.48623 10.4539 8.45912Z"
          fill={iconColor}
        />
      </svg>
    </>
  );
};
