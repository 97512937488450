import React from "react";

type AppEqualsIconProps = {
  width?: string;
  height?: string;
  fill?: string;
};

export const AppEqualsIcon: React.FC<AppEqualsIconProps> = ({
  width = "24",
  height = "24",
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7.99609C5.44772 7.99609 5 8.44381 5 8.99609C5 9.54838 5.44772 9.99609 6 9.99609L18 9.99609C18.5523 9.99609 19 9.54838 19 8.99609C19 8.44381 18.5523 7.99609 18 7.99609L6 7.99609ZM6 13.9961C5.44772 13.9961 5 14.4438 5 14.9961C5 15.5484 5.44772 15.9961 6 15.9961L18 15.9961C18.5523 15.9961 19 15.5484 19 14.9961C19 14.4438 18.5523 13.9961 18 13.9961L6 13.9961Z"
        fill="#0057FF"
      />
    </svg>
  );
};
