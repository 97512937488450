import cn from "classnames";

import { AppExclamationIcon as Icon } from "../../icons/app-exclamation.icon";
import { AppButton } from "../../app-button/app-button.component";
import { useTranslation } from "react-i18next";
import s from "./app-confirm-alert.module.scss";

type PropTypes = {
  type?: "info";
  children?: React.ReactNode;
  className?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const AppConfirmAlert: React.FC<PropTypes> = ({
  children,
  className,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        data-show="true"
        className={cn(s.wrapper, className)}
        role="alertdialog"
      >
        <span
          role="img"
          aria-label="info-circle"
          className={cn(s.icon, className)}
        >
          <Icon color="#FF3200" />
        </span>
        <div className={cn(s.content, className)}>{children}</div>
        <div className={cn(s.action, className)}>
          <AppButton isSmall isDanger isOutline onClick={onConfirm}>
            {t("app.alert.confirm.yes")}
          </AppButton>
          <AppButton isOutline isSmall onClick={onCancel}>
            {t("app.alert.confirm.no")}
          </AppButton>
        </div>
      </div>
    </>
  );
};
