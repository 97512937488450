import React, { SVGAttributes } from "react";

type PropTypes = {
  width?: string;
  height?: string;
};

export const AppBoxIcon: React.FC<PropTypes & SVGAttributes<any>> = ({
  width = "24",
  height = "24",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    // viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M16.8004 13.6C16.8004 13.904 16.6324 14.168 16.3764 14.304L10.0564 17.856C9.92839 17.952 9.76839 18 9.60039 18C9.43239 18 9.27239 17.952 9.14439 17.856L2.82439 14.304C2.69614 14.2366 2.5888 14.1353 2.51405 14.0112C2.4393 13.8871 2.39999 13.7449 2.40039 13.6V6.4C2.40039 6.096 2.56839 5.832 2.82439 5.696L9.14439 2.144C9.27239 2.048 9.43239 2 9.60039 2C9.76839 2 9.92839 2.048 10.0564 2.144L16.3764 5.696C16.6324 5.832 16.8004 6.096 16.8004 6.4V13.6ZM9.60039 3.72L4.83239 6.4L9.60039 9.08L14.3684 6.4L9.60039 3.72ZM4.00039 13.128L8.80039 15.832V10.464L4.00039 7.768V13.128ZM15.2004 13.128V7.768L10.4004 10.464V15.832L15.2004 13.128Z"
      fill="#979797"
    />
  </svg>
);
