import React from "react";
import Lottie from "lottie-react";
import cn from "classnames";

import animationLogoJson from "./animation-logo-dark.json";
import s from "./app-animated-logo.module.scss";

export const AppAnimatedLogo: React.FC<{ className?: string }> = ({
  className,
}) => (
  <div className={cn(s.container, className)}>
    <Lottie animationData={animationLogoJson} loop={true} />
  </div>
);

export const AppLogoLoader: React.FC<{
  loading: boolean;
  className?: string;
}> = ({ loading, className }) => {
  if (!loading) return null;

  return (
    <div className={cn(s.loaderContainer, className)}>
      <div className={s.loader}>
        <Lottie
          data-testid="lottie-logo"
          animationData={animationLogoJson}
          loop={true}
        />
      </div>
    </div>
  );
};
