import { Home } from "./pages/home/home.page";
import { OriginPage } from "./pages/origin-page/origin-page";
import { ProcessPage } from "./pages/process/process.page";
import { DataQuality } from "./pages/data-quality/data-quality.page";
import { CreateProcessPage } from "./pages/create-process/create-process.page";
import { ProcessResultPage } from "./pages/process-result/process-result.page";
import { ProcessDetailPage } from "./pages/process-detail/process-detail.page";
import { HypothesisPage } from "./pages/hypothesis/hypothesis.page";
import { NotFound } from "./pages/not-found/not-found.page";
import { ProcessEditPage } from "./pages/process-edit/process-edit.page";

const routes = [
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/quality",
    element: <DataQuality />,
  },
  {
    path: "/processes",
    element: <ProcessPage />,
  },
  {
    path: "/hypothesis",
    element: <HypothesisPage />,
  },
  {
    path: "/processes/create",
    element: <CreateProcessPage />,
  },
  {
    path: "/processes/create-low-code",
    element: <CreateProcessPage isLowcode={true} />,
  },
  {
    path: "/processes/:id/result",
    element: <ProcessResultPage />,
  },
  {
    path: "/processes/:id",
    element: <ProcessDetailPage />,
  },
  {
    path: "/processes/:id/edit",
    element: <ProcessEditPage />,
  },
  {
    path: "/origin/:id",
    element: <OriginPage />,
  },
];

export default routes;
