import s from "./app-main.module.scss";

import { AppHeader } from "./app-header/app-header.component";
import React, { ReactNode } from "react";
import { AppSnackbarProvider } from "../providers/snackbar-provider/snackbar.provider";

import { Outlet } from "react-router";
import cn from "classnames";
import { MobileGuard } from "./app-mobile-guard/mobile-guard.component";
import { useGetMessageQuery } from "../store/notification/ws.query";

export const AppRightBar = ({ children }: any) => {
  return <div className={cn(s.rightBar)}>{children}</div>;
};

interface IAppContentType {
  children?: ReactNode;
  isFull?: boolean;
}

export const AppContent: React.FC<IAppContentType> = ({ children, isFull }) => {
  return (
    <div
      className={cn(s.content, {
        [s._full]: isFull,
      })}
    >
      {children}
    </div>
  );
};

export const AppMainLayout = () => {
  useGetMessageQuery("message");
  return (
    <>
      <MobileGuard />
      <div className={s.mainWrapper}>
        <div className={cn(s.container, s.header)}>
          <AppHeader />
        </div>
        <div className={cn(s.container)}>
          <AppSnackbarProvider>
            <Outlet />
          </AppSnackbarProvider>
        </div>
      </div>
    </>
  );
};
