import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContent } from "../../components/app-main.layout";
import { AppAnimatedLogo } from "../../components/ui/app-animated-logo/app-animated-logo.component";
import { AppDLHLogoText } from "../../components/icons/logo/app-dlh-logo-text.icon";
import { AppButton } from "../../components/app-button/app-button.component";

import s from "./not-found.module.scss";

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <AppContent>
      <div className={s.notFoundContainer}>
        <div className={s.notFoundLogo}>
          <AppAnimatedLogo className={s.logoAnimated} />
          <AppDLHLogoText />
        </div>
        <div className={s.notFoundMain}>
          <div className={s.titleNotFound}>
            {t("page.not_found.title.main")}
          </div>
          <Link to={"/"}>
            <AppButton isFilled>
              {t("page.not_found.title.button.main-page")}
            </AppButton>
          </Link>
        </div>
      </div>
    </AppContent>
  );
};
